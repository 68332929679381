import * as dayjs from 'dayjs';
import 'dayjs/locale/ru';

export const respondentDate = (date) => {
    if (!date) {
        return null;
    }

    return dayjs(date).locale('ru').format('DD MMMM YYYY, HH:mm');
};

export const getUserTimezone = () => {
    const tzOffset = new Date().getTimezoneOffset() / 60;

    if (tzOffset === 0) {
        return tzOffset;
    }

    return tzOffset * -1;
};

/**
 *
 * Возвращает начальную и конечную даты указанной недели в заданном году.
 *
 * @param {number} year - Год.
 * @param {number} weekNumber - Номер недели в году.
 *
 * @returns {{ start: string, end: string }} - Объект с начальной и конечной датами недели.
 *
 */
export function getWeekDates(year, weekNumber) {
    const januaryFirst = dayjs(`${year}-01-01`);
    const dayOfFirst = januaryFirst.day();
    const startDate = januaryFirst.add(((8 - dayOfFirst) % 7) + (weekNumber - 1) * 7, 'day');
    const endDate = startDate.add(6, 'day');
    const formattedStartDate = startDate.format('YYYY-MM-DD');
    const formattedEndDate = endDate.format('YYYY-MM-DD');

    return {
        start: formattedStartDate,
        end: formattedEndDate
    };
}
