import { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { classNames } from "helpers/app";

import { showAlert } from "redux/appReducer";
import { updateExamination } from "api/examinations";
import { ButtonLoader } from "components/ButtonLoader";
import { questionnaireForms, questionnaireMethodsList, questionnaireButtons } from "components/questionnaire/consts";
import styles from "./ExaminationChooseMethods.module.scss";

const formNutritionalRatingScaleRequired = questionnaireForms.formNutritionalRatingScale.required;

// TODO посмотреть как можно отрефакторить
export const ExaminationChooseMethods = memo(({ id, respondentId }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { mutateAsync: updateMutation } = useMutation({
        mutationFn: (methods) =>
            updateExamination(
                id,
                { methods },
                {
                    type: "set-methods",
                }
            ),
    });
    const [chosenMethods, setChosenMethods] = useState(questionnaireMethodsList);
    const [isLoading, setIsLoading] = useState(false);

    const handleChooseMethod = (key) => {
        let updatedChosenMethods;

        if (chosenMethods.includes(key)) {
            // if (questionnaireForms.formGHIS.required.includes(key)) {
            //     updatedChosenMethods = chosenMethods.filter((item) => item !== key && item !== "formGHIS");
            // }

            if (formNutritionalRatingScaleRequired.includes(key)) {
                updatedChosenMethods = chosenMethods.filter(
                    (item) => item !== key && item !== "formNutritionalRatingScale"
                );
            } else {
                updatedChosenMethods = chosenMethods.filter((item) => item !== key);
            }
        } else {
            updatedChosenMethods = [...chosenMethods, key];
        }

        setChosenMethods(updatedChosenMethods);
    };

    const handleConfirmMethods = () => {
        setIsLoading(true);

        updateMutation(chosenMethods)
            .then(async () => {
                await Promise.all([
                    queryClient.invalidateQueries(["examination", id]),
                    queryClient.refetchQueries(["respondent", respondentId]),
                ]);
                // setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                dispatch(showAlert({ type: "error", text: error.message }));
            });
    };

    return (
        <div className={styles.root}>
            <h3 className={styles.title}>Рекомендованные методики</h3>

            <div className={styles.filterBtns}>
                <button className="secondary-btn" onClick={() => setChosenMethods([])}>
                    Убрать всё
                </button>
                <button className="secondary-btn" onClick={() => setChosenMethods(questionnaireMethodsList)}>
                    Выбрать всё
                </button>
            </div>

            <div className={styles.list}>
                {questionnaireButtons.map(({ key, title, isHighlighted }) => {
                    let isDisabled = false;

                    // if (key === "formGHIS") {
                    //     isDisabled = !questionnaireForms.formGHIS.required.every((item) =>
                    //         chosenMethods.includes(item)
                    //     );
                    // } else

                    if (key === "formNutritionalRatingScale") {
                        isDisabled = !formNutritionalRatingScaleRequired.every((item) => chosenMethods.includes(item));
                    }

                    return (
                        <div
                            className={classNames("styled-checkbox styled-checkbox__wrapper", {
                                [styles.highlighted]: isHighlighted,
                            })}
                            key={key}
                        >
                            <input
                                type="checkbox"
                                id={key}
                                checked={chosenMethods.includes(key)}
                                onChange={() => handleChooseMethod(key)}
                                disabled={isDisabled}
                            />
                            <label htmlFor={key}>{title}</label>
                        </div>
                    );
                })}
            </div>

            <hr />

            <button onClick={handleConfirmMethods} className="main-btn" disabled={!chosenMethods.length || isLoading}>
                {isLoading ? <ButtonLoader /> : "Утвердить"}
            </button>
        </div>
    );
});
