import { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateExamination } from "api/examinations";
import { showAlert } from "redux/appReducer";
import { useSearchParamsDelete } from "hooks/useSearchParamsDelete";
import { classNames } from "helpers/app";
import { ButtonLoader } from "components/ButtonLoader";
import { questionnaireButtons, questionnaireForms, questionnaireMethodsList } from "components/questionnaire/consts";
import styles from "./ExaminationChangeMethods.module.scss";

const formNutritionalRatingScaleRequired = questionnaireForms.formNutritionalRatingScale.required;

export const ExaminationChangeMethods = memo(({ id, methods, filledForms }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [isLoading, setIsLoading] = useState(false);
    const [chosenMethods, setChosenMethods] = useState([]);
    const deleteSearchParam = useSearchParamsDelete();
    const { mutateAsync: updateMutation } = useMutation({
        mutationFn: (methods) =>
            updateExamination(
                id,
                { methods },
                {
                    type: "set-methods",
                }
            ),
    });

    useEffect(() => {
        setChosenMethods(methods);
    }, [methods, filledForms]);

    const handleChooseMethod = (key) => {
        let updatedChosenMethods;

        if (chosenMethods.includes(key)) {
            if (formNutritionalRatingScaleRequired.includes(key)) {
                updatedChosenMethods = chosenMethods.filter(
                    (item) => item !== key && item !== "formNutritionalRatingScale"
                );
            } else {
                updatedChosenMethods = chosenMethods.filter((item) => item !== key);
            }
        } else {
            updatedChosenMethods = [...chosenMethods, key];
        }

        setChosenMethods(updatedChosenMethods);
    };

    const handleConfirmChanges = () => {
        setIsLoading(true);
        updateMutation(chosenMethods)
            .then(async () => {
                await queryClient.invalidateQueries(["examination", id]);
                deleteSearchParam("step");
                // setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                dispatch(showAlert({ type: "error", text: error.message }));
            });
    };

    return (
        <div>
            <h3 className={styles.title}>Изменение методик осмотра</h3>

            <div className={styles.filterBtns}>
                <button className="secondary-btn" onClick={() => setChosenMethods(filledForms)}>
                    Убрать всё
                </button>
                <button className="secondary-btn" onClick={() => setChosenMethods(questionnaireMethodsList)}>
                    Выбрать всё
                </button>
            </div>

            <div className={styles.list}>
                {questionnaireButtons.map(({ key, title, isHighlighted }) => {
                    let isDisabled = false;
                    const isSuccess = filledForms.includes(key);

                    if (key === "formNutritionalRatingScale") {
                        isDisabled = !formNutritionalRatingScaleRequired.every((item) => chosenMethods.includes(item));
                    }

                    return (
                        <div
                            className={classNames("styled-checkbox styled-checkbox__wrapper", {
                                [styles.success]: isSuccess,
                                [styles.highlighted]: isHighlighted,
                            })}
                            key={key}
                        >
                            <input
                                type="checkbox"
                                id={key}
                                checked={chosenMethods.includes(key)}
                                onChange={() => {
                                    if (!isSuccess) {
                                        handleChooseMethod(key);
                                    }
                                }}
                                disabled={isDisabled}
                            />
                            <label htmlFor={key}>{title}</label>
                        </div>
                    );
                })}
            </div>

            <hr />

            <button onClick={handleConfirmChanges} className="main-btn" disabled={isLoading || !chosenMethods.length}>
                {isLoading ? <ButtonLoader /> : "Изменить"}
            </button>
        </div>
    );
});
