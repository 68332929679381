import {
    Questionnaire1,
    Questionnaire2,
    Questionnaire3,
    Questionnaire4,
    QuestionnaireMMSE,
    QuestionnaireDeliriumScale,
    QuestionnaireGeriatricDepressionScale,
    QuestionnaireNutritionalRatingScale,
    QuestionnaireClinicalDehydrationScale,
    QuestionnaireBarthelScale,
    QuestionnaireLawtonScale,
    QuestionnairePhysicalFunctioningTest,
    QuestionnairePainIntensityRatings,
    QuestionnaireSleepAssessment,
    QuestionnaireNortonScale,
    QuestionnaireMorseScale,
    QuestionnaireGHIS,
    QuestionnairePainAssessmentDementia,
    QuestionnaireAdditionalScales,
    QuestionnaireAgeNotBarrier,
    QuestionnaireGetUpAndGo,
    QuestionnaireCharlsonComorbidityIndex,
    QuestionnaireRASS,
    QuestionnaireDynamometry,
} from "components/questionnaire";

const questionnairePageStepsArr = [
    {
        key: "form1",
        component: Questionnaire1,
    },
    {
        key: "form2",
        component: Questionnaire2,
    },
    {
        key: "form3",
        component: Questionnaire3,
    },
    {
        key: "form4",
        component: Questionnaire4,
    },
    {
        key: "formMMSE",
        component: QuestionnaireMMSE,
    },
    {
        key: "formDeliriumScale",
        component: QuestionnaireDeliriumScale,
    },
    {
        key: "formAgeNotBarrier",
        component: QuestionnaireAgeNotBarrier,
    },
    {
        key: "formNutritionalRatingScale",
        component: QuestionnaireNutritionalRatingScale,
    },
    {
        key: "formClinicalDehydrationScale",
        component: QuestionnaireClinicalDehydrationScale,
    },
    {
        key: "formBarthelScale",
        component: QuestionnaireBarthelScale,
    },
    {
        key: "formLawtonScale",
        component: QuestionnaireLawtonScale,
    },
    {
        key: "formPhysicalFunctioningTest",
        component: QuestionnairePhysicalFunctioningTest,
    },
    {
        key: "formGetUpAndGo",
        component: QuestionnaireGetUpAndGo,
    },
    {
        key: "formDynamometry",
        component: QuestionnaireDynamometry,
    },
    {
        key: "formPainIntensityRatings",
        component: QuestionnairePainIntensityRatings,
    },
    {
        key: "formGeriatricDepressionScale",
        component: QuestionnaireGeriatricDepressionScale,
    },
    {
        key: "formSleepAssessment",
        component: QuestionnaireSleepAssessment,
    },
    {
        key: "formNortonScale",
        component: QuestionnaireNortonScale,
    },
    {
        key: "formMorseScale",
        component: QuestionnaireMorseScale,
    },
    {
        key: "formCharlsonComorbidityIndex",
        component: QuestionnaireCharlsonComorbidityIndex,
    },
    {
        key: "formGHIS",
        component: QuestionnaireGHIS,
    },
    {
        key: "formRASS",
        component: QuestionnaireRASS,
    },
    {
        key: "formAdditionalScales",
        component: QuestionnaireAdditionalScales,
    },
    {
        key: "formPainAssessmentDementia",
        component: QuestionnairePainAssessmentDementia,
    },
];

export const questionnairePageSteps = {};

questionnairePageStepsArr.forEach((item, i) => {
    questionnairePageSteps[item.key] = {
        component: item.component,
        block: i + 1,
    };
});
