import { useForm } from "react-hook-form";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useExaminationStore } from "store/useExaminationStore";
import { useSetCompletedForms } from "hooks/useSetCompletedForms";
import { QuestionnaireFormWrapper, QuestionnaireRASSForm } from "components/questionnaire";
import { requiredSelectNumber } from "../schemas";

const validationSchema = z.object({
    characteristic: requiredSelectNumber,
});

export const QuestionnaireRASS = ({ form, handleSaveForm, isLoading }) => {
    const [startFillingTime] = useState(Date.now);
    const completedForms = useExaminationStore((state) => state.completedForms);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            characteristic: {},
        },
    });
    useSetCompletedForms({ form, completedForms, setValue });

    const onSubmit = (data) => {
        handleSaveForm({ form, data, fillingTime: Date.now() - startFillingTime });
    };

    return (
        <QuestionnaireFormWrapper handleSubmit={handleSubmit(onSubmit)} isLoading={isLoading} isShowResult={false}>
            <QuestionnaireRASSForm control={control} errors={errors} key={completedForms[form]} />
        </QuestionnaireFormWrapper>
    );
};
