import { memo, useState } from "react";

import { classNames } from "helpers/app";
import { DownloadPdf } from "components/result";
import { ButtonLoader } from "components/ButtonLoader";
import { ReactComponent as DownloadIcon } from "static/img/download.svg";
import btnRightImg from "static/img/btn-right.svg";

export const ExaminationResultDownloadPdf = memo(({ respondentFullName, result, questionnaire, createdAt }) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const downloadPdfHandler = () => {
        if (!isDownloading) {
            setIsDownloading(true);
        }
    };

    const closeDownloadPdfHandler = () => {
        setIsDownloading(false);
    };

    return (
        <button
            onClick={downloadPdfHandler}
            className={classNames([
                "block-wrapper respondent-page__button respondent-page__button-with-icons",
                "respondent-page__button-report respondent-page__button-loading",
            ])}
        >
            {isDownloading ? (
                <DownloadPdf
                    respondentFullName={respondentFullName}
                    result={result}
                    questionnaire={questionnaire}
                    createdAt={createdAt}
                    close={closeDownloadPdfHandler}
                >
                    <ButtonLoader height={24} />
                </DownloadPdf>
            ) : (
                <>
                    <DownloadIcon />
                    <span>Скачать результаты в PDF</span>
                    <img src={btnRightImg} alt="" />
                </>
            )}
        </button>
    );
});
