import { FormSelectMulti } from "components/forms";
import { questionsFormNortonScale as questions } from "components/questionnaire/questions";

const optionsData = {
    physicalCondition: [
        { label: "хорошее", value: 4 },
        { label: "в пределах нормы", value: 3 },
        { label: "ниже нормы", value: 2 },
        { label: "существенно ниже нормы", value: 1 },
    ],
    consciousness: [
        { label: "ясное", value: 4 },
        { label: "безучастное", value: 3 },
        { label: "спутанное", value: 2 },
        { label: "ступор", value: 1 },
    ],
    activity: [
        { label: "передвигается без помощи", value: 4 },
        { label: "передвигается с помощью", value: 3 },
        { label: "в инвалидном кресле", value: 2 },
        { label: "не двигается", value: 1 },
    ],
    mobility: [
        { label: "полноценная", value: 4 },
        { label: "частично ограничена", value: 3 },
        { label: "существенно ограничена", value: 2 },
        { label: "нет", value: 1 },
    ],
    incontinence: [
        { label: "полноценная", value: 4 },
        { label: "частично", value: 3 },
        { label: "моча", value: 2 },
        { label: "моча и кал", value: 1 },
    ],
};

export const QuestionnaireNortonScaleForm = ({ control, errors }) => {
    return (
        <>
            {Object.keys(optionsData).map((questionKey) => (
                <FormSelectMulti
                    key={questionKey}
                    control={control}
                    name={questionKey}
                    label={questions[questionKey]}
                    placeholder="Один вариант ответа"
                    error={errors[questionKey]}
                    options={optionsData[questionKey]}
                    maxSelectOptions={1}
                />
            ))}
        </>
    );
};
