import { useNavigate, useParams } from "react-router-dom";

import { useFetchRespondent } from "hooks/useQuery";
import { Loader } from "components/Loader";
import { Layout } from "components/Layout";
import { RespondentInfoHeader } from "components/respondents";
import { RespondentExaminations } from "components/examinations";

const RespondentPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { respondent, respondentError, respondentLoading } = useFetchRespondent(id);

    const handleBack = () => {
        navigate("/respondents");
    };

    return (
        <Layout>
            <div className="respondent-page">
                <RespondentInfoHeader
                    fullName={respondent?.fullName}
                    age={respondent?.age}
                    createDate={respondent?.createDate}
                    handleBack={handleBack}
                />

                {respondentLoading && <Loader />}

                {respondentError && (
                    <div className="data-error" style={{ marginTop: 40 }}>
                        Ошибка загрузки данных о респонденте
                    </div>
                )}

                {respondent && (
                    <RespondentExaminations respondentId={respondent._id} examinations={respondent.examinations} />
                )}
            </div>
        </Layout>
    );
};

export default RespondentPage;
