import { FormRadioGroupWithValue, FormSelectMulti } from "components/forms";
import { questionsNutritionalRatingScale as questions } from "components/questionnaire/questions";

const optionsYes = [
    { value: 1, label: "да" },
    { value: 0, label: "нет" },
];

const optionsNo = [
    { value: 0, label: "да" },
    { value: 1, label: "нет" },
];

export const QuestionnaireNutritionalRatingScaleForm2 = ({ control, errors }) => {
    return (
        <>
            <FormRadioGroupWithValue
                control={control}
                name="independentLiving"
                label={questions.independentLiving}
                options={optionsYes}
                error={errors.independentLiving}
            />

            <FormRadioGroupWithValue
                control={control}
                name="medicationIntakePerDay"
                label={questions.medicationIntakePerDay}
                options={optionsNo}
                error={errors.medicationIntakePerDay}
            />

            <FormRadioGroupWithValue
                control={control}
                name="pressureSoresAndSkinUlcers"
                label={questions.pressureSoresAndSkinUlcers}
                options={optionsNo}
                error={errors.pressureSoresAndSkinUlcers}
            />

            <FormRadioGroupWithValue
                control={control}
                name="frequencyOfFullMealsPerDay"
                label={questions.frequencyOfFullMealsPerDay}
                options={[
                    { value: 0, label: "1 раз" },
                    { value: 1, label: "2 раза" },
                    { value: 2, label: "3 раза" },
                ]}
                error={errors.frequencyOfFullMealsPerDay}
            />

            <div style={{ marginBottom: 12 }}>{questions.proteinIntakeMarkers}:</div>

            <FormRadioGroupWithValue
                control={control}
                name="proteinIntakeMarkers_1"
                label={questions.proteinIntakeMarkers_1}
                options={optionsYes}
                error={errors.proteinIntakeMarkers_1}
            />
            <FormRadioGroupWithValue
                control={control}
                name="proteinIntakeMarkers_2"
                label={questions.proteinIntakeMarkers_2}
                options={optionsYes}
                error={errors.proteinIntakeMarkers_2}
            />
            <FormRadioGroupWithValue
                control={control}
                name="proteinIntakeMarkers_3"
                label={questions.proteinIntakeMarkers_3}
                options={optionsYes}
                error={errors.proteinIntakeMarkers_3}
            />

            <FormRadioGroupWithValue
                control={control}
                name="consumptionOfFruitsVegetablesPerDay"
                label={questions.consumptionOfFruitsVegetablesPerDay}
                options={optionsYes}
                error={errors.consumptionOfFruitsVegetablesPerDay}
            />

            <FormRadioGroupWithValue
                control={control}
                name="dailyFluidIntake"
                label={questions.dailyFluidIntake}
                options={[
                    { value: 0, label: "Меньше 3 стаканов" },
                    { value: 0.5, label: "3-5 стаканов" },
                    { value: 1, label: "Более 5 стаканов" },
                ]}
                error={errors.dailyFluidIntake}
            />

            <FormSelectMulti
                control={control}
                name="patientDietaryHabits"
                label={questions.patientDietaryHabits}
                placeholder="Один вариант ответа"
                options={[
                    { value: 0, label: "Не способен есть без помощи" },
                    { value: 1, label: "Ест самостоятельно с небольшими трудностями" },
                    { value: 2, label: "Ест самостоятельно" },
                ]}
                error={errors.patientDietaryHabits}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="selfAssessmentOfNutritionalStatus"
                label={questions.selfAssessmentOfNutritionalStatus}
                placeholder="Один вариант ответа"
                options={[
                    { value: 0, label: "Оценивает себя как плохо питающегося" },
                    { value: 1, label: "Оценивает свое состояние неопределённо" },
                    { value: 2, label: "Оценивает себя как неимеющего проблем с питание" },
                ]}
                error={errors.selfAssessmentOfNutritionalStatus}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="patientHealthStatusComparedToPeers"
                label={questions.patientHealthStatusComparedToPeers}
                placeholder="Один вариант ответа"
                options={[
                    { value: 0, label: "Не такое хорошее" },
                    { value: 0.5, label: "Не знает" },
                    { value: 1, label: "Такое же хорошее" },
                    { value: 2, label: "Лучше" },
                ]}
                error={errors.patientHealthStatusComparedToPeers}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="midArmCircumference"
                label={questions.midArmCircumference}
                placeholder="Один вариант ответа"
                options={[
                    { value: 0, label: "20 см и меньше" },
                    { value: 0.5, label: "21-22 см" },
                    { value: 1, label: "23 и больше" },
                ]}
                error={errors.midArmCircumference}
                maxSelectOptions={1}
            />

            <FormRadioGroupWithValue
                control={control}
                name="calfCircumference"
                label={questions.calfCircumference}
                options={[
                    { value: 0, label: "Меньше 31 см" },
                    { value: 1, label: "31 см и больше" },
                ]}
                error={errors.calfCircumference}
            />
        </>
    );
};
