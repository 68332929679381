export const questionsForm1 = {
    inspectionPlace: "Осмотрен",
    withWhom: "С проживающим присутствует",
    consent: "Согласие пациента на опрос и осмотр в его присутствии",
    badHabits: "Вредные привычки",
    physicalActivity: "Физическая активность",
    reasonLimitationPhysicalActivity: "С чем связано ограничение физ. активности?",
    emergencyCallFrequency: "Частота вызова СМП (за год)",
    hospitalizationRate: "Частота госпитализаций (за год)",
    injuriesSurgeriesFractures: "Травмы/операции/переломы",
    usingAids: "Использование вспомогательных средств",
    falls: "Падения в течение последнего года",
    epidemiologicalHistory: "Эпиданамнез",
    epidemicallyArea: "Пребывание в эпидемически неблагоприятной местности в последнее время",
    drugTherapy: "Лекарственная терапия",
    polypharmacy: "Полипрагмазия",
    diseaseHistory: "Анамнез заболевания",
};

export const questionsForm2 = {
    arterialHypertension: "Артериальная гипертензия",
    bronchialAsthma: "Бронхиальная астма",
    IHD: "ИБС",
    COPD: "ХОБЛ",
    myocardialInfarction: "Инфаркт миокарда",
    oncologicalDiseases: "Онкологические заболевания",
    CHF: "ХСН",
    anemia: "Анемия",
    ONMK: "ОНМК",
    pepticUlcer: "Язвенная болезнь",
    Diabetes: "Сахарный диабет",
    urolithiasisDisease: "Мочекаменная болезнь",
    Osteoarthritis: "Остеоартрит",
    trophicUlcers: "Трофические язвы",
    rheumatoidArthritis: "Ревматоидный артрит",
    atrialFibrillation: "Фибрилляция предсердий",
    otherHNIZ: "Другое",
};

export const questionsForm3 = {
    height: "Рост (см)",
    weight: "Вес (кг)",
    imt: "ИМТ",
    consultationType: "Тип конституции",
    generalState: "Общее состояние",
    consciousness: "Сознание",
    contact: "Контакт",
    eyeContact: "Зрительный контакт",
    communicationMethod: "Способ коммуникации",
    speech: "Речь",
    movementActivity: "Двигательная активность",
    selfService: "Самообслуживание",
    skin: "Кожа",
    trophicDisorders: "Трофические болезни",
    colorOfTheSkin: "Цвет кожи",
    subcutaneousFatLayer: "Подкожно-жировой слой развит",
    presenceOfPastosity: "Наличие пастозности, отеков",
    lymphNodes: "Лимфатические узлы",
    uponPalpation: "При пальпации",
    muscleSystem: "Мышечная система",
    osteoarticularSystem: "Костно-суставная система",
    orthostaticTest: "Ортостатическая проба",
    riskMajorOsteoporoticFractures: "10-летний риск основных остеопоретических переломов (%)",
    riskHipFracture: "10-летний риск перелома шейки бедра (%)",
};

export const questionsForm4 = {
    // СИСТЕМА ОРГАНОВ ДЫХАНИЯ
    chestCageShape: "Форма грудной клетки",
    chestCageDeformation: "Деформация грудной клетки",
    breathingRate: "Частота дыхания",
    SpO2: "SpO2",
    shortnessOfBreath: "Одышка",
    chestPalpation: "Пальпация грудной клетки",
    percussionSound: "Перкуторный звук",
    auscultation: "Аускультация",
    rales: "Хрипы",
    pleuralFrictionSound: "Шум трения плевры",
    // СИСТЕМА ОРГАНОВ КРОВООБРАЩЕНИЯ
    heartArea: "Область сердца",
    apicalHeartImpulsePalpatedAt: "Верхушечный толчок сердца пальпируется в",
    heartSounds: "Тоны сердца",
    heartRate: "ЧСС",
    heartMurmurs: "Шумы сердца",
    pulse: "Пульс",
    pulseDeficit: "Дефицит пульса",
    pulseRate: "Частота пульса",
    BPLeftArm: "АД на левой руке мм рт. ст.",
    BPRightArm: "АД на правой руке мм рт. ст.",
    orthostaticTest: "Ортостатическая проба",
    // СИСТЕМА ОРГАНОВ ПИЩЕВАРЕНИЯ
    chewingProblems: "Проблемы с жеванием",
    swallowingProblems: "Проблемы с глотанием",
    teeth: "Зубы",
    yawning: "Зев",
    tonsils: "Миндалины",
    tongue: "Язык",
    abdomen: "Живот",
    abdominalPalpation: "При пальпации живот",
    liver: "Печень",
    liverOnPalpation: "Печень при пальпации",
    spleen: "Селезенка",
    pelvicFunctions: "Тазовые функции",
    stool: "Стул",
    // МОЧЕПОЛОВАЯ СИСТЕМА
    lumbarInspection: "При осмотре поясничной области пастозность, отеки",
    PasternatskySign: "С-м Пастернацкого",
    urination: "Мочеиспускание",
    eyeSclerae: "Склеры глаз",
    pupils: "Зрачки",
    visionAssessment: "Оценка зрения",
    hearingAssessment: "Оценка слуха",
    // ПСИХО - ЭМОЦИОНАЛЬНЫЙ СТАТУС
    appearance: "Внешний вид",
    personalHygiene: "Состояние личной гигиены",
    orientation: "Ориентировка",
    cooperationDuringExamination: "Сотрудничество во время обследования",
    behavior: "Поведение",
    psychomotorActivity: "Психомоторная активность",
    speech: "Речь",
    memoryImpairment: "Нарушение памяти",
    perceptualImpairment: "Нарушение восприятия",
    concentrationAttentionImpairment: "Нарушение концентрации и внимания",
};

export const questionsFormMMSE = {
    todayDateDetails: "Назовите сегодняшнюю дату (в ответе должны быть год, время года, месяц, число и день недели)",
    currentLocationDetails:
        "Назовите место, где вы находитесь (в ответе должны быть страна, область, город, клиника и этаж)",
    threeReproducedWords: "Немедленно воспроизведите три слова (в ответе может быть одно, два или три слова)",
    calculateExpressionOrReverseWord:
        "Последовательно вычислите 5 раз 7 из 100 (или воспроизведите слово 'Земля' наоборот)",
    recallImmediateReproductionWords: "Вспомните три слова, названные при проверке незамедлительного воспроизведения",
    doctorShownObjects: "Как называются предметы, показанные врачом?",
    repeatSentence: "Пациент должен повторить следующее предложение: 'Никаких если, никаких но'",
    performActionsSequence:
        "Пациент должен выполнить последовательность из 3х действий: возьмите листок бумаги правой рукой, сложите его вдвое и положите на пол.",
    writeSentence: "Напишите предложение, содержащее подлежащее и сказуемое и имеющее смысл",
    copyDrawing: "Скопируйте рисунок (Фигура-пятиугольник)",
};

export const questionsFormDeliriumScale = {
    delirium: "Делирий",
};

export const questionsFormAgeNotBarrier = {
    weightLossInLast6Months: "Похудели ли вы на 5 кг и более за последние 6 месяцев? (Непреднамеренное снижение веса)",
    limitationsDueToImpairedSenses:
        "Испытываете ли Вы какие-либо ограничения в повседневной жизни из-за снижения зрения или слуха?",
    fallsOrInjuriesInLastYear:
        "Были ли у Вас в течение последнего года травмы, связанные с падением, или падения без травм?",
    depressionOrAnxietyInLastWeeks:
        "Чувствуете ли Вы себя подавленным, грустным или встревоженным на протяжении последних недель?",
    memoryOrientationPlanningIssues: "Есть ли у Вас проблемы с памятью, ориентацией или способностью планировать?",
    urinaryIncontinence: "Страдаете ли Вы недержанием мочи?",
    mobilityDifficulties:
        "Испытываете ли вы трудности в перемещении по дому или на улице? (Ходьба до 100 метров или подъем на 1 лестничный пролёт)",
};

export const questionsNutritionalRatingScale = {
    // part 1
    decreasedFoodIntakeLast3Months:
        "Снизилось ли за последние 3 месяца количество пищи, которое Вы съедаете, из-за потери аппетита, проблем с пищеварением, из-за сложностей при пережевывании и глотании?",
    weightLossLast3Months: "Терял ли пациент массу тела за последние 3 месяца?",
    patientMobility: "Подвижность пациента",
    acuteIllnessLast3Months: "Имеел ли пациент острое заболевание(психологический стресс) за последние 3 месяца?",
    psychoneurologicalProblems: "Имеет ли пациент психоневрологические проблемы?",
    patientBMI: "Индекс массы тела (ИМТ) пациента",

    // part 2
    independentLiving: "Проживает ли пациент независимо? (не в доме престарелых или больнице)",
    medicationIntakePerDay: "Принимает ли пациент более трех лекарств в день?",
    pressureSoresAndSkinUlcers: "Имеются ли у пациента пролежни и язвы кожи?",
    frequencyOfFullMealsPerDay: "Сколько раз в день пациент полноценно питается?",
    proteinIntakeMarkers: "Маркеры потребления белковой пищи",
    proteinIntakeMarkers_1: "Пациент употребляет одну порцию молочных продуктов",
    proteinIntakeMarkers_2: "Пациент употребляет две или более порции бобовых и яиц в неделю",
    proteinIntakeMarkers_3: "Пациент употребляет мясо, рыбу или птицу ежедневно",
    consumptionOfFruitsVegetablesPerDay:
        "Съедает ли пациент две или более порций(1 порция=200г.) фруктов или овощей в день?",
    dailyFluidIntake: "Сколько жидкости выпивает в день пациент?",
    patientDietaryHabits: "Способ питания пациента?",
    selfAssessmentOfNutritionalStatus: "Самооценка состояния питания пациента?",
    patientHealthStatusComparedToPeers: "Состояние здоровья пациента в сравнении с другими людьми своего возраста?",
    midArmCircumference: "Окружность по середине плеча",
    calfCircumference: "Окружность голени",
};

export const questionsFormClinicalDehydrationScale = {
    appearance: "Внешний вид",
    eyeballs: "Глазные яблоки",
    mucousMembranes: "Слизистые оболочки",
    tears: "Слезы",
};

export const questionsFormBarthelScale = {
    eating: "Приём пищи",
    personalHygiene: "Личная гигиена",
    dressing: "Одевание",
    bathing: "Приём ванны",
    urinationControl: "Контроль мочеиспускания",
    bowelMovementControl: "Контроль дефекации",
    toiletUse: "Посещение туалета",
    gettingOutOfBed: "Вставание с постели (передвижение с кровати на стул)",
    movementOnFlatSurface: "Передвижение на ровной поверхности",
    stairsClimbing: "Подъём по лестнице",
};

export const questionsFormLawtonScale = {
    telephoneCalls: "Телефонные звонки",
    shopping: "Покупки",
    cooking: "Приготовление пищи",
    householdChores: "Ведение домашнего быта",
    laundry: "Стирка",
    usingTransportation: "Пользование транспортом",
    medicationIntake: "Прием лекарств",
    financialTransactions: "Финансовые операции",
};

export const questionsFormPhysicalFunctioningTest = {
    balanceAssessmentFeetTogether: "Определение равновесия: Стопы вместе",
    balanceAssessmentSemiTandem: "Определение равновесия: Полумандемное положение",
    balanceAssessmentTandem: "Определение равновесия: Тандемное положение",
    walkingSpeedAssessment: "Определение скорости ходьбы",
    chairRiseTest: "Тест подъема со стула",
};

export const questionsFormGetUpAndGo = {
    individualTesting: "Индивидуальное тестирование (сек)",
};

export const questionsFormDynamometry = {
    rightHand: "Сила пожатия правой руки (кг)",
    leftHand: "Сила пожатия левой руки (кг)",
};

export const questionsFormPainIntensityRatings = {
    degreeOfPainSyndrome:
        "Пациенту предлагается изображение 11 лиц. Пациент выбирает то лицо, которое в наибольшей степени соответствует его состоянию.",
};

export const questionsFormGeriatricDepressionScale = {
    overallLifeSatisfaction: "В целом, удолетворены ли Вы своей жизнью?",
    abandonmentOfActivitiesAndInterests: "Вы забросили большую часть своих занятий и интересов?",
    feelingOfEmptinessInLife: "Вы чувствуете, что ваша жизнь пуста?",
    frequentBoredom: "Вам часто становится скучно?",
    goodMoodMostOfTheTime: "У вас хорошее настроение большую часть времени?",
    fearOfSomethingBadHappening: "Вы опасаетесь, что с Вами случится что-то плохое?",
    feelingHappyMostOfTheTime: "Вы чувствуете себя счастливым большую часть времени?",
    feelingHelpless: "Вы чувствуете себя беспомощным?",
    preferringToStayAtHome: "Вы предпочитаете остаться дома, нежели выйти на улицу и заняться чем-нибудь новым?",
    perceptionOfMemoryWorseThanOthers: "Считаете ли Вы, что Ваша память хуже, чем у других?",
    livingIsWonderful: "Считаете ли Вы, что жить-это прекрасно?",
    feelingUseless: "Чувствуете ли Вы себя сейчас бесполезным?",
    feelingEnergetic: "Чувствуете ли Вы себя полным энергией и жизненной силой?",
    senseOfHopelessness: "Ощущаете ли вы безнадежность той ситуации, в которой находитесь в настоящее время?",
    comparisonToOthersLivingMoreFulfillingLives:
        "Считаете ли Вы, что окружающие Вас люди живут более полноценной жизнью в сравнении с Вами?",
};

export const questionsFormSleepAssessment = {
    sleepOnsetProblems: "Испытывает ли пациент проблемы с засыпанием?",
    sleepInterruptions: "Испытывает ли пациент проблему прерывистого сна?",
    earlyAwakeningProblem: "Испытывает ли пациент проблему слишком раннего пробуждения?",
    satisfactionWithSleepProcess: "Насколько пациент удолетворен процессом своего сна?",
    perceivedImpactOfSleepProblemsOnLifeQuality:
        "Насколько, по мнению пациента, ЗАМЕТНЫ окружающим его проблемы со сном в плане нарушения качества его жизни?",
    levelOfConcernAboutSleepProblems: "В какой степени пациента беспокоят (заботят) текущие проблемы со сном?",
    extentSleepProblemsInterfereWithDailyFunctioning:
        "В какой степени, по-мнению пациента, его проблемы со сном мешают его повседневному функционированию?",
};

export const questionsFormNortonScale = {
    physicalCondition: "Физическое состояние",
    consciousness: "Сознание",
    activity: "Активность",
    mobility: "Подвижность",
    incontinence: "Недержание",
};

export const questionsFormMorseScale = {
    fallsLastYear: "Падал ли за последний год?",
    comorbidCondition: "Сопутствующее заболевание",
    walkingIndependence: "Самостоятельность при ходьбе",
    venousCatheter: "Назначены внутривенные вливания/установлен внутривенный катетер",
    gait: "Походка",
    mentalState: "Психическое состояние",
};

export const questionsFormCharlsonComorbidityIndex = {
    myocardialInfarction: "Инфаркт миокарда",
    congestiveHeartFailure: "Застойная сердечная недостаточность",
    peripheralArteryDisease: "Болезнь периферических артерий",
    cerebrovascularDisease: "Цереброваскулярное заболевание",
    dementia: "Деменция",
    chronicLungDisease: "Хроническое заболевание легких",
    connectiveTissueDisease: "Болезнь соединительной ткани",
    pepticUlcerDisease: "Язвенная болезнь",
    mildLiverDisease: "Легкое поражение печени",
    diabetes: "Диабет",
    hemiplegia: "Гемиплегия",
    moderateSevereKidneyDisease: "Умеренная или тяжелая болезнь почек",
    diabetesComplications: "Диабет с поражением органов",
    metastaticCancer: "Злокачественная опухоль без метастазов",
    leukemia: "Лейкемия",
    lymphomas: "Лимфомы",
    moderateSevereLiverDisease: "Умеренное или тяжелое поражение печени",
    metastaticMalignantTumor: "Метастазирующие злокачественные опухоли",
    AIDS: "СПИД (болезнь, а не только виремия)",
};

export const questionsFormGHIS = {
    basicActivitiesDailyLiving: "Базовая повседневная активность (шкала Бартел)",
    instrumentalActivitiesDailyLiving: "Инструментальная повседневная активность (шкала Лоутон, баллы)",
    miniMentalStateExamination: "Краткий опросник психического статуса (MMSE, баллы)",
    comorbidityIndex: "Индекс коморбидности",
    miniNutritionalAssessment: "Краткая шкала оценки питания (MNA, баллы)",
    numberMedications: "Количество лекарственных препаратов, ед",
    socialStatusLivingConditions: "Социальный статус/условия проживания",
};

export const questionsFormRASS = {
    characteristic: "Характеристика",
};

// Переименована из "Дополнительные шкалы" в "Корнельская шкала депрессии при деменции"
export const questionsFormAdditionalScales = {
    // Аффективные симптомы
    anxiety:
        "Проявлялась ли это за последнюю неделю тревога в поведении? Тревожился ли больной о чем-то, что обычно не вызывает у него тревоги? Проявлял ли озабоченность в связи с незначительными событиями и безобидными ситуациями?",
    sadness:
        "Находился ли больной в подавленном состоянии? Как долго продолжалось такое состояние? Случалось ли ему плакать? Сколько раз за последнюю неделю?",
    lackOfResponseToPositiveEvents: "В состоянии ли больной обрадоваться приятным событиям или новостям?",
    irritability:
        "Насколько легко больной выходит из себя? Проявлялись ли у больного раздражительность и нетерпимость на этой неделе?",

    // Поведенческие расстройства
    agitation:
        "Проявлял ли больной беспокойство, выражающееся в неспособности сидеть спокойно по меньшей мере один час? Отмечались ли такие признаки беспокойства, как заламывание рук, кусание губ, перебор волос.",
    sluggishness: "Заторможенность (движений, речи, реакций).",
    physicalComplaints:
        "Жаловался ли больной чаще, чем обычно, на нарушенное пищеварение, запоры, диарею, мышечную боль, учащенное мочеиспускание, потливость, головные боли или другие проблемы? Насколько серьезны были эти жалобы? Часто ли случались?",
    lossOfInterest:
        "Как прошла последняя неделя? Занимался ли больной обычными делами? В нормальном объеме или меньше? (Если интерес к повседневным делам утрачен давно, баллы не начисляются).",

    // Физические симптомы
    decreasedAppetite: "Снизился ли аппетит за последнюю неделю? Приходилось ли убеждать больного поесть?",
    weightLoss: "Сколько килограм потерял пациент?",
    weaknessFatigue:
        "Как часто за последний месяц больной жаловался на усталость? Нуждался ли за последний месяц в дневном сне, вызванном усталостью? Испытывал ли тяжесть в конечностях за последние 30 дней?",

    // Циклические функции
    dailyMoodSwings:
        "Отмечалось ухудшение настроения? В какое время суток? Насколько серьезным было ухудшение по утрам? (Оценивается только ухудшение настроения в утренние часы.)",
    sleepIssues: "Проблемы засыпания, отход ко сну в более позднее время.",
    nighttimeAwakenings:
        "Просыпался ли больной среди ночи? Как долго не мог после этого заснуть? Поднимался ли с постели?",
    earlyMorningAwakenings:
        "Если они имели место, насколько раньше просыпался больной? Оставался ли в постели после утреннего пробуждения или поднимался?",

    // Идеаторные нарушения
    suicidalThoughts:
        "Выражал ли больной мысль о том, что жизнь не стоит того, чтобы жить? Проявлял ли суицидальные настроения? Совершал ли попытки причинить себе ущерб или покончить с жизнью? (Один балл за пассивную склонность к суицидальным настроениям, два балла за активные проявления).",
    lowSelfEsteem:
        "Проявлял ли больной склонность к самокритике? Оценивал ли производимые им действия как неудачные или неправильные? Выражал ли чувство вины за то, что сделал или не стал делать?",
    pessimism:
        "Выражал ли больной ожидания худшего? Был ли в состоянии замечать улучшения в ситуации? Оказывался ли восприимчив к позитивной поддержке со стороны близких?",
    delusionsOfHarmIllnessOrPoverty:
        "Высказывал ли больной странные идеи? Считает ли он свое заболевание наказанием свыше? Дает ли другие иррациональные объяснения проблеме? Верит ли он в несуществующие финансовые или иные материальные проблемы?",
};

export const questionsFormPainAssessmentDementia = {
    breathing: "Дыхание",
    negativeVocalization: "Негативная вокализация",
    facialExpression: "Выражение лица",
    bodyLanguage: "Язык тела",
    abilityToComfort: "Возможность утешить",
};
