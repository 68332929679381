import { Layout } from "components/Layout";
import { CreateRespondent } from "components/respondents";

export default function RespondentCreatePage() {
    return (
        <Layout>
            <CreateRespondent />
        </Layout>
    );
}
