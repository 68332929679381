import React from "react";

import { FormSelectMulti } from "components/forms";
import { questionsFormPainAssessmentDementia as questions } from "components/questionnaire/questions";

const optionsData = {
    breathing: [
        { label: "Нормальное", value: 0 },
        { label: "Иногда затруднённое, краткий период гипервентиляции", value: 1 },
        { label: "Громкое затруднённое дыхание, длинный период гипервентиляции, Чейн-Стокса", value: 2 },
    ],
    negativeVocalization: [
        { label: "Нет", value: 0 },
        { label: "Одиночный вздох или стон; Тихий голос, негативное или неодобрительное содержание речи", value: 1 },
        { label: "Повторяющиеся крики, громкие вздохи или стоны, плач", value: 2 },
    ],
    facialExpression: [
        { label: "Улыбка или ничего не выражающее", value: 0 },
        { label: "Печальное, хмурое, испуганное", value: 1 },
        { label: "Морщится", value: 2 },
    ],
    bodyLanguage: [
        { label: "Расслаблен", value: 0 },
        { label: "Напряжен, ерзает, малоподвижен", value: 1 },
        { label: "Скован, сжатые кулаки, согнутые колени, отталкивает проверяющего, дерется", value: 2 },
    ],
    abilityToComfort: [
        { label: "Не требует утешения", value: 0 },
        { label: "Отвлекается или успокаивается на голос или прикосновение", value: 1 },
        { label: "Невозможно успокоить, отвлечь или ободрить", value: 2 },
    ],
};
export const QuestionnairePainAssessmentDementiaForm = ({ control, errors }) => {
    return Object.keys(optionsData).map((questionKey) => (
        <FormSelectMulti
            key={questionKey}
            control={control}
            name={questionKey}
            label={questions[questionKey]}
            placeholder="Один вариант ответа"
            error={errors[questionKey]}
            options={optionsData[questionKey]}
            maxSelectOptions={1}
        />
    ));
};
