import React, { memo } from "react";

export const ExaminationResultBaseForm = memo(({ form, data }) => {
    const { title, questions } = data;

    return (
        <div>
            <h3 className="recommendations-page__block-title" style={{ marginTop: 0, paddingLeft: 5 }}>
                {title}
            </h3>

            <div className="respondent-page__block block-wrapper">
                {Object.entries(questions).map(([key, value]) => {
                    if (!form[key]) return null;
                    const answer = Array.isArray(form[key])
                        ? form[key].map(({ label }) => label).join(", ")
                        : form[key];

                    return (
                        <div className="recommendations-page__list" key={key}>
                            <h4 className="recommendations-page__list-title">{value}</h4>

                            <div className="recommendations-page__list-answers">
                                <div className="recommendations-page__list-answers-item">
                                    <p>{answer}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
});
