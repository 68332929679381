export const declinationOfYears = (num) => {
    const forms = ["год", "года", "лет"];
    const n = Math.abs(num) % 100;
    const n1 = n % 10;

    if (n > 10 && n < 20) return forms[2];
    if (n1 > 1 && n1 < 5) return forms[1];
    if (n1 === 1) return forms[0];

    return forms[2];
};

export const declinations = (value, type) => {
    const forms = {
        year: ["год", "года", "лет"],
        region: ["регион", "региона", "регионов"],
        point: ["балл", "балла", "баллов"],
    };
    const n = Math.abs(value) % 100;
    const n1 = n % 10;

    if (n > 10 && n < 20) return forms[type][2];
    if (n1 > 1 && n1 < 5) return forms[type][1];
    if (n1 === 1) return forms[type][0];

    return forms[type][2];
};
