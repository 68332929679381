import { memo, useEffect, useState } from "react";

import { questionnaireForms } from "components/questionnaire/consts";
import { calcScalesPoints } from "helpers/scales";
import { declinations } from "helpers/declinations";
import styles from "./ExaminationResult.module.scss";

export const ExaminationMethodsResultsItem = memo(({ form, value }) => {
    const { title, getResult } = questionnaireForms[form];
    const [points, setPoints] = useState(null);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (form && value) {
            switch (form) {
                case "formDeliriumScale":
                    setResult(`Делирий: ${value.delirium}`);
                    break;
                case "formDynamometry":
                    setResult(`Правая рука: ${value.rightHand}, левая рука: ${value.leftHand}`);
                    break;
                case "formPainIntensityRatings":
                    setResult(value.degreeOfPainSyndrome);
                    break;
                case "formRASS":
                    setPoints(value.characteristic.value);
                    setResult(value.characteristic.label);
                    break;
                default:
                    let calcPoints;

                    if (form === "formGetUpAndGo") {
                        calcPoints = Object.values(value).reduce((acc, item) => acc + Number(item), 0);
                    } else if (form === "formGHIS") {
                        calcPoints = (calcScalesPoints(value) / 7).toFixed(2);
                    } else {
                        calcPoints = calcScalesPoints(value);
                    }

                    setPoints(calcPoints);
                    setResult(getResult(calcPoints, { questionsCount: Object.keys(value).length }));
            }
        }
    }, [form, value]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="recommendations-page__list">
            <h4 className="recommendations-page__list-title" style={{ marginLeft: 8 }}>
                {title}
            </h4>

            <div className="recommendations-page__list-answers" style={{ marginTop: 5 }}>
                <div className="recommendations-page__list-answers-item">
                    <p style={{ marginTop: 0 }}>
                        {points !== null && (
                            <strong className={styles.points}>
                                {points} {declinations(points, "point")}
                            </strong>
                        )}

                        {result}
                    </p>
                </div>
            </div>
        </div>
    );
});
