import { FormSelectMulti } from "components/forms";
import { questionsFormSleepAssessment as questions } from "components/questionnaire/questions";

export const QuestionnaireSleepAssessmentForm = ({ control, errors }) => {
    return (
        <>
            <FormSelectMulti
                control={control}
                name="sleepOnsetProblems"
                label={questions.sleepOnsetProblems}
                placeholder="Один вариант ответа"
                error={errors.sleepOnsetProblems}
                options={[
                    { label: "Нет", value: 0 },
                    { label: "Легкая", value: 1 },
                    { label: "Умеренная", value: 2 },
                    { label: "Тяжелая", value: 3 },
                    { label: "Очень тяжелая", value: 4 },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="sleepInterruptions"
                label={questions.sleepInterruptions}
                placeholder="Один вариант ответа"
                error={errors.sleepInterruptions}
                options={[
                    { label: "Нет", value: 0 },
                    { label: "Легкая", value: 1 },
                    { label: "Умеренная", value: 2 },
                    { label: "Тяжелая", value: 3 },
                    { label: "Очень тяжелая", value: 4 },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="earlyAwakeningProblem"
                label={questions.earlyAwakeningProblem}
                placeholder="Один вариант ответа"
                error={errors.earlyAwakeningProblem}
                options={[
                    { label: "Нет", value: 0 },
                    { label: "Легкая", value: 1 },
                    { label: "Умеренная", value: 2 },
                    { label: "Тяжелая", value: 3 },
                    { label: "Очень тяжелая", value: 4 },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="satisfactionWithSleepProcess"
                label={questions.satisfactionWithSleepProcess}
                placeholder="Один вариант ответа"
                error={errors.satisfactionWithSleepProcess}
                options={[
                    { label: "Очень удовлетворен", value: 0 },
                    { label: "Удовлетворен", value: 1 },
                    { label: "Относительно удовлетворен", value: 2 },
                    { label: "Не удовлетворен", value: 3 },
                    { label: "Очень не удовлетворен", value: 4 },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="perceivedImpactOfSleepProblemsOnLifeQuality"
                label={questions.perceivedImpactOfSleepProblemsOnLifeQuality}
                placeholder="Один вариант ответа"
                error={errors.perceivedImpactOfSleepProblemsOnLifeQuality}
                options={[
                    { label: "Совсем не заметны", value: 0 },
                    { label: "Чуть заметны", value: 1 },
                    { label: "Немного заметны", value: 2 },
                    { label: "Сильно заметны", value: 3 },
                    { label: "Очень сильно заметны", value: 4 },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="levelOfConcernAboutSleepProblems"
                label={questions.levelOfConcernAboutSleepProblems}
                placeholder="Один вариант ответа"
                error={errors.levelOfConcernAboutSleepProblems}
                options={[
                    { label: "Совсем не беспокоят", value: 0 },
                    { label: "Чуть беспокоят", value: 1 },
                    { label: "Немного беспокоят", value: 2 },
                    { label: "Сильно беспокоят", value: 3 },
                    { label: "Очень сильно беспокоят", value: 4 },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="extentSleepProblemsInterfereWithDailyFunctioning"
                label={questions.extentSleepProblemsInterfereWithDailyFunctioning}
                placeholder="Один вариант ответа"
                error={errors.extentSleepProblemsInterfereWithDailyFunctioning}
                options={[
                    { label: "Совсем не мешают", value: 0 },
                    { label: "Чуть мешают", value: 1 },
                    { label: "Немного мешают", value: 2 },
                    { label: "Сильно мешают", value: 3 },
                    { label: "Очень сильно мешают", value: 4 },
                ]}
                maxSelectOptions={1}
            />
        </>
    );
};
