import { z } from "zod";

export const requiredString = z.string().min(1, { message: "Обязательное поле" });
export const requiredNumber = z.number({
    required_error: "Обязательное поле",
});
export const requiredMultiSelectString = z
    .array(z.object({ value: z.string(), label: z.string() }))
    .nonempty({ message: "Обязательное поле" });
export const requiredMultiSelectNumber = z
    .array(z.object({ value: z.number(), label: z.string() }))
    .nonempty({ message: "Обязательное поле" });
export const requiredSelectNumber = z.object({ value: z.number(), label: z.string() }); //.required();
