import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useExaminationStore } from "store/useExaminationStore";
import { useSetCompletedForms } from "hooks/useSetCompletedForms";
import { QuestionnaireBarthelScaleForm, QuestionnaireFormWrapper } from "components/questionnaire";
import { questionnaireForms } from "components/questionnaire/consts";
import { requiredMultiSelectNumber } from "../schemas";

const validationSchema = z.object({
    eating: requiredMultiSelectNumber,
    personalHygiene: requiredMultiSelectNumber,
    dressing: requiredMultiSelectNumber,
    bathing: requiredMultiSelectNumber,
    urinationControl: requiredMultiSelectNumber,
    bowelMovementControl: requiredMultiSelectNumber,
    toiletUse: requiredMultiSelectNumber,
    gettingOutOfBed: requiredMultiSelectNumber,
    movementOnFlatSurface: requiredMultiSelectNumber,
    stairsClimbing: requiredMultiSelectNumber,
});

export const QuestionnaireBarthelScale = ({ form, handleSaveForm, handleShowResult, isLoading }) => {
    const [startFillingTime] = useState(Date.now);
    const completedForms = useExaminationStore((state) => state.completedForms);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            eating: [],
            personalHygiene: [],
            dressing: [],
            bathing: [],
            urinationControl: [],
            bowelMovementControl: [],
            toiletUse: [],
            gettingOutOfBed: [],
            movementOnFlatSurface: [],
            stairsClimbing: [],
        },
    });
    useSetCompletedForms({ form, completedForms, setValue });

    const onSubmit = (data) => {
        handleSaveForm({ form, data, fillingTime: Date.now() - startFillingTime });
    };

    const onShowResult = (data) => {
        handleShowResult({
            form,
            values: data,
            formData: questionnaireForms[form],
        });
    };
    return (
        <QuestionnaireFormWrapper
            handleSubmit={handleSubmit(onSubmit)}
            handleShowResults={handleSubmit(onShowResult)}
            isLoading={isLoading}
        >
            <QuestionnaireBarthelScaleForm control={control} errors={errors} />
        </QuestionnaireFormWrapper>
    );
};
