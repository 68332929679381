import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useFetchExamination } from "hooks/useQuery";
import { useSearchParamsDelete } from "hooks/useSearchParamsDelete";
import { Loader } from "components/Loader";
import { Layout } from "components/Layout";
import { ExaminationPageWrapper } from "components/examinations";
import { RespondentInfoHeader } from "components/respondents";

export default function ExaminationPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { examination, examinationError, isExaminationLoading } = useFetchExamination(id);
    const deleteSearchParam = useSearchParamsDelete();

    const handleBack = () => {
        if (searchParams.has("step")) {
            deleteSearchParam("step");
        } else if (examination) {
            navigate(`/respondent/${examination.respondent._id}`);
        }
    };

    return (
        <Layout>
            <div className="respondent-page">
                <RespondentInfoHeader
                    fullName={examination?.respondent?.fullName}
                    age={examination?.respondent?.age}
                    createDate={examination?.respondent?.createDate}
                    handleBack={handleBack}
                />

                {isExaminationLoading && <Loader />}

                {examinationError && (
                    <div className="data-error" style={{ marginTop: 40 }}>
                        Ошибка загрузки данных об осмотре
                    </div>
                )}

                {examination && <ExaminationPageWrapper examination={examination} />}
            </div>
        </Layout>
    );
}
