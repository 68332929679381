import { calcCharlsonComorbidityIndexAges } from "helpers/scales";

export const getCalculatedData = (data, age) => {
    const getIndexAges = calcCharlsonComorbidityIndexAges(age);
    const calcData = { ...data };

    for (const [key, result] of Object.entries(calcData)) {
        if (result.value > 0) {
            const value = result.value > 1 ? 1 : result.value;
            calcData[key] = { ...result, value: value + getIndexAges };
        }
    }

    return calcData;
};
