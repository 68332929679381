import React from "react";

import { FormSelectMulti } from "components/forms";
import { questionsFormMMSE as questions } from "components/questionnaire/questions";

export const QuestionnaireMMSEForm = ({ control, errors }) => {
    return (
        <>
            <FormSelectMulti
                control={control}
                name="todayDateDetails"
                label={questions.todayDateDetails}
                placeholder="Несколько ответов (5 доступно для выбора)"
                error={errors.todayDateDetails}
                options={[
                    { value: 1, label: "Год" },
                    { value: 1, label: "Время года" },
                    { value: 1, label: "Число" },
                    { value: 1, label: "День недели" },
                    { value: 1, label: "Месяц" },
                ]}
            />
            <FormSelectMulti
                control={control}
                name="currentLocationDetails"
                label={questions.currentLocationDetails}
                placeholder="Несколько ответов (5 доступно для выбора)"
                error={errors.currentLocationDetails}
                options={[
                    { value: 1, label: "Страна" },
                    { value: 1, label: "Область" },
                    { value: 1, label: "Город" },
                    { value: 1, label: "Клиника" },
                    { value: 1, label: "Этаж" },
                ]}
            />
            <FormSelectMulti
                control={control}
                name="threeReproducedWords"
                label={questions.threeReproducedWords}
                placeholder="Один вариант ответа"
                error={errors.threeReproducedWords}
                options={[
                    { value: 1, label: "Одно слово" },
                    { value: 2, label: "Два слова" },
                    { value: 3, label: "Три слова" },
                ]}
                maxSelectOptions={1}
            />
            <FormSelectMulti
                control={control}
                name="calculateExpressionOrReverseWord"
                label={questions.calculateExpressionOrReverseWord}
                placeholder="Несколько ответов (2 доступно для выбора), но сумма баллов не должна превышать 5"
                error={errors.calculateExpressionOrReverseWord}
                options={[
                    { value: 1, label: "Правильно проведено одно вычисление" },
                    { value: 2, label: "Правильно проведено два вычисления" },
                    { value: 3, label: "Правильно проведены три вычисления" },
                    { value: 4, label: "Правильно проведены четыре вычисления" },
                    { value: 5, label: "Правильно проведены все вычисления" },
                    { value: 5, label: "Правильно названо слово" },
                ]}
                maxValues={5}
                maxSelectOptions={2}
            />
            <FormSelectMulti
                control={control}
                name="recallImmediateReproductionWords"
                label={questions.recallImmediateReproductionWords}
                placeholder="Один вариант ответа"
                error={errors.recallImmediateReproductionWords}
                options={[
                    { value: 1, label: "Одно слово" },
                    { value: 2, label: "Два слова" },
                    { value: 3, label: "Три слова" },
                    { value: 0, label: "Не вспомнил" },
                ]}
                maxSelectOptions={1}
            />
            <FormSelectMulti
                control={control}
                name="doctorShownObjects"
                label={questions.doctorShownObjects}
                placeholder="Один вариант ответа"
                error={errors.doctorShownObjects}
                options={[
                    { value: 1, label: "Назван правильно один объект" },
                    { value: 2, label: "Названы правильно оба объекта" },
                    { value: 0, label: "Не назван ни один объект" },
                ]}
                maxSelectOptions={1}
            />
            <FormSelectMulti
                control={control}
                name="repeatSentence"
                label={questions.repeatSentence}
                placeholder="Один вариант ответа"
                error={errors.repeatSentence}
                options={[
                    { value: 1, label: "Пациент повторил" },
                    { value: 0, label: "Пациент не повторил" },
                ]}
                maxSelectOptions={1}
            />
            <FormSelectMulti
                control={control}
                name="performActionsSequence"
                label={questions.performActionsSequence}
                placeholder="Один вариант ответа"
                error={errors.performActionsSequence}
                options={[
                    { value: 1, label: "Верно выполнено одно из действий" },
                    { value: 2, label: "Верно выполнено два действия" },
                    { value: 3, label: "Верно выполнены все действия" },
                    { value: 0, label: "Ни одно из действий не выполнено верно" },
                ]}
                maxSelectOptions={1}
            />
            <FormSelectMulti
                control={control}
                name="writeSentence"
                label={questions.writeSentence}
                placeholder="Один вариант ответа"
                error={errors.writeSentence}
                options={[
                    { value: 1, label: "Предложение написано правильно, имеет смысл" },
                    { value: 0, label: "Предложение написано не правильно, не имеет смысл" },
                ]}
                maxSelectOptions={1}
            />
            <FormSelectMulti
                control={control}
                name="copyDrawing"
                label={questions.copyDrawing}
                placeholder="Один вариант ответа"
                error={errors.copyDrawing}
                options={[
                    { value: 1, label: "Рисунок воспроизведён правильно" },
                    { value: 0, label: "Рисунок не воспроизведён верно" },
                ]}
                maxSelectOptions={1}
            />
        </>
    );
};
