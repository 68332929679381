import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useExaminationStore } from "store/useExaminationStore";
import { useSetCompletedForms } from "hooks/useSetCompletedForms";
import { getFormDataWithOtherFields } from "helpers/questionnaireForms";
import { Questionnaire3Form, QuestionnaireFormWrapper } from "components/questionnaire";
import { requiredString } from "../schemas";

const validationSchema = z.object({
    height: requiredString,
    weight: requiredString,
    imt: z.string(),
    consultationType: requiredString,
    generalState: requiredString,
    consciousness: requiredString,
    contact: requiredString,
    eyeContact: requiredString,
    communicationMethod: requiredString,
    speech: requiredString,
    movementActivity: requiredString,
    selfService: requiredString,
    skin: requiredString,
    trophicDisorders: requiredString,
    trophicDisordersOther: z.string(),
    colorOfTheSkin: requiredString,
    subcutaneousFatLayer: requiredString,
    presenceOfPastosity: requiredString,
    lymphNodes: requiredString,
    uponPalpation: requiredString,
    muscleSystem: requiredString,
    osteoarticularSystem: requiredString,
    orthostaticTest: requiredString,
    riskMajorOsteoporoticFractures: requiredString,
    riskHipFracture: requiredString,
});

export const Questionnaire3 = ({ form, handleSaveForm, isLoading }) => {
    const [startFillingTime] = useState(Date.now);
    const completedForms = useExaminationStore((state) => state.completedForms);
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            height: "",
            weight: "",
            imt: "",
            consultationType: "",
            generalState: "",
            consciousness: "",
            contact: "",
            eyeContact: "",
            communicationMethod: "",
            speech: "",
            movementActivity: "",
            selfService: "",
            skin: "",
            trophicDisorders: "",
            trophicDisordersOther: "",
            colorOfTheSkin: "",
            subcutaneousFatLayer: "",
            presenceOfPastosity: "",
            lymphNodes: "",
            uponPalpation: "",
            muscleSystem: "",
            osteoarticularSystem: "",
            orthostaticTest: "",
            riskMajorOsteoporoticFractures: "",
            riskHipFracture: "",
        },
    });
    useSetCompletedForms({ form, completedForms, setValue });
    const height = watch("height");
    const weight = watch("weight");

    useEffect(() => {
        if (height && weight) {
            const heightNum = Number(height);
            const weightNum = Number(weight);

            if (heightNum > 100 && weightNum > 30) {
                setValue("imt", (weightNum / (heightNum / 100) ** 2).toFixed(2));
            }
        }
    }, [height, weight, setValue]);

    const onSubmit = (data) => {
        const getData = getFormDataWithOtherFields(data, ["trophicDisorders"]);
        handleSaveForm({ form, data: getData, fillingTime: Date.now() - startFillingTime });
    };

    return (
        <QuestionnaireFormWrapper handleSubmit={handleSubmit(onSubmit)} isLoading={isLoading} isShowResult={false}>
            <Questionnaire3Form control={control} errors={errors} />
        </QuestionnaireFormWrapper>
    );
};
