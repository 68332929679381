import React from "react";

import { FormSelectMulti } from "components/forms";
import { questionsFormClinicalDehydrationScale as questions } from "components/questionnaire/questions";

export const QuestionnaireClinicalDehydrationScaleForm = ({ control, errors }) => {
    return (
        <>
            <FormSelectMulti
                control={control}
                name="appearance"
                label={questions.appearance}
                placeholder="Один вариант ответа"
                error={errors.appearance}
                options={[
                    { value: 0, label: "Нормальный" },
                    { value: 1, label: "Жажда, беспокойство, раздражительность" },
                    { value: 2, label: "Вялость, сонливость" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="eyeballs"
                label={questions.eyeballs}
                placeholder="Один вариант ответа"
                error={errors.eyeballs}
                options={[
                    { value: 0, label: "Тургор нормальный" },
                    { value: 1, label: "Слегка запавшие" },
                    { value: 2, label: "Запавшие" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="mucousMembranes"
                label={questions.mucousMembranes}
                placeholder="Один вариант ответа"
                error={errors.mucousMembranes}
                options={[
                    { value: 0, label: "Влажные" },
                    { value: 1, label: "Липкие, суховатые" },
                    { value: 2, label: "Сухие" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="tears"
                label={questions.tears}
                placeholder="Один вариант ответа"
                error={errors.tears}
                options={[
                    { value: 0, label: "Слезоотделение в норме" },
                    { value: 1, label: "Слезоотделение снижено" },
                    { value: 2, label: "Слезы отсутствуют" },
                ]}
                maxSelectOptions={1}
            />
        </>
    );
};
