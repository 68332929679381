import { memo } from "react";

export const ButtonLoader = memo(({ height = 16 }) => {
    return (
        <div className="lds-ellipsis" style={{ height }}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
});
