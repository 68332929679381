import { declinationOfYears } from "helpers/declinations";
import { respondentDate } from "helpers/dates";

export const RespondentInfoHeader = ({ handleBack, title = "Респондент", fullName, age, createDate }) => {
    return (
        <header className="respondent-page__header">
            <div className="respondent-page__header-buttons">
                <button onClick={handleBack} className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back" />

                <p className="page__title">{title}</p>
            </div>

            <div className="respondent-page__header-center">
                <h3>{fullName || "....."}</h3>

                <p>
                    {age ? (
                        <>
                            {age} {declinationOfYears(age)}
                        </>
                    ) : (
                        "....."
                    )}{" "}
                    • {createDate ? respondentDate(createDate) : "....."}
                </p>
            </div>
        </header>
    );
};
