import { FormSelectMulti } from "components/forms";
import { questionsFormGHIS as questions } from "components/questionnaire/questions";

const filledFields = [
    "basicActivitiesDailyLiving",
    "instrumentalActivitiesDailyLiving",
    "miniMentalStateExamination",
    "comorbidityIndex",
    "miniNutritionalAssessment",
];

const optionsData = {
    numberMedications: [
        { label: "0-3", value: 1 },
        { label: "от 4 до 6", value: 0.5 },
        { label: "≥7", value: 0 },
    ],
    socialStatusLivingConditions: [
        { label: "В семье", value: 1 },
        { label: "В социальном учреждении (есть семья/родственники, которые навещают проживающего)", value: 0.5 },
        { label: "Одинокий (нет семьи, никто не навещает проживающего)", value: 0 },
    ],
};

export const QuestionnaireGHISForm = ({ control, getValues, errors }) => {
    return (
        <>
            {filledFields.map((question) => {
                const value = getValues(question)[0]?.label;

                if (value === undefined) return null;

                return (
                    <div className="questionnaire__form-item" key={question}>
                        <p>{questions[question]}</p>
                        <input className="questionnaire__input" value={value} disabled />
                    </div>
                );
            })}

            {Object.keys(optionsData).map((questionKey) => (
                <FormSelectMulti
                    key={questionKey}
                    control={control}
                    name={questionKey}
                    label={questions[questionKey]}
                    placeholder="Один вариант ответа"
                    error={errors[questionKey]}
                    options={optionsData[questionKey]}
                    maxSelectOptions={1}
                />
            ))}
        </>
    );
};
