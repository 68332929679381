import React from "react";

import { questionsFormDynamometry as questions } from "components/questionnaire/questions";
import { FormInput } from "components/forms";

export const QuestionnaireDynamometryForm = ({ control, errors }) => {
    return (
        <>
            <FormInput
                control={control}
                name="rightHand"
                label={questions.rightHand}
                placeholder="введите значение"
                error={errors.rightHand}
                type="number"
            />
            <FormInput
                control={control}
                name="leftHand"
                label={questions.leftHand}
                placeholder="введите значение"
                error={errors.leftHand}
                type="number"
            />
        </>
    );
};
