import { create } from "zustand";

const initialState = {
    patientBMI: null,
    completedForms: {},
};

export const useExaminationStore = create((set) => ({
    ...initialState,
    setBMI: (bmi) => set({ patientBMI: bmi }),
    setCompletedForms: (newCompletedForms) =>
        set((state) => ({ completedForms: { ...state.completedForms, ...newCompletedForms } })),
    resetState: () => set({ ...initialState }),
}));
