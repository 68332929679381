import { memo } from "react";

import { questionnaireMethodsList } from "components/questionnaire/consts";
import { ExaminationMethodsResultsItem } from "./ExaminationMethodsResultsItem";
import styles from "./ExaminationResult.module.scss";

const formsList = questionnaireMethodsList.filter((item) => !["form1", "form2", "form3", "form4"].includes(item));

export const ExaminationMethodsResults = memo(({ questionnaire }) => {
    return (
        <div>
            <h3 className={styles.blockTitle}>Результаты методик</h3>

            <div className="respondent-page__block block-wrapper">
                {formsList.map((key) => {
                    if (!questionnaire[key]) return null;

                    return <ExaminationMethodsResultsItem form={key} value={questionnaire[key]} key={key} />;
                })}
            </div>
        </div>
    );
});
