import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useSetCompletedForms } from "hooks/useSetCompletedForms";
import { useExaminationStore } from "store/useExaminationStore";
import { QuestionnaireClinicalDehydrationScaleForm, QuestionnaireFormWrapper } from "components/questionnaire";
import { questionnaireForms } from "components/questionnaire/consts";
import { requiredMultiSelectNumber } from "../schemas";

const validationSchema = z.object({
    appearance: requiredMultiSelectNumber,
    eyeballs: requiredMultiSelectNumber,
    mucousMembranes: requiredMultiSelectNumber,
    tears: requiredMultiSelectNumber,
});

export const QuestionnaireClinicalDehydrationScale = ({ form, handleSaveForm, handleShowResult, isLoading }) => {
    const [startFillingTime] = useState(Date.now);
    const completedForms = useExaminationStore((state) => state.completedForms);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            appearance: [],
            eyeballs: [],
            mucousMembranes: [],
            tears: [],
        },
    });
    useSetCompletedForms({ form, completedForms, setValue });
    const onSubmit = (data) => {
        handleSaveForm({ form, data, fillingTime: Date.now() - startFillingTime });
    };

    const onShowResult = (data) => {
        handleShowResult({
            form,
            values: data,
            formData: questionnaireForms[form],
        });
    };

    return (
        <QuestionnaireFormWrapper
            handleSubmit={handleSubmit(onSubmit)}
            handleShowResults={handleSubmit(onShowResult)}
            isLoading={isLoading}
        >
            <QuestionnaireClinicalDehydrationScaleForm control={control} errors={errors} />
        </QuestionnaireFormWrapper>
    );
};
