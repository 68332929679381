import { ExaminationChooseMethods, ExaminationDone, ExaminationFilling } from "components/examinations";

export const ExaminationPageWrapper = ({ examination }) => {
    const { _id, respondent, status, methods, questionnaire, result, createdAt } = examination;

    return (
        <>
            {status === "new" && <ExaminationChooseMethods id={_id} respondentId={respondent._id} />}
            {status === "inProgress" && (
                <ExaminationFilling
                    id={_id}
                    methods={methods}
                    questionnaire={questionnaire}
                    respondentId={respondent._id}
                    age={respondent.age}
                />
            )}
            {status === "done" && (
                <ExaminationDone
                    id={_id}
                    result={result}
                    questionnaire={questionnaire}
                    methods={methods}
                    fullName={respondent.fullName}
                    age={respondent.age}
                    createdAt={createdAt}
                />
            )}
        </>
    );
};
