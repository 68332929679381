import axios from "axios";

export const saveRespondent = async (data) => {
    try {
        const response = await axios.post("respondents/save", data);

        return response.data;
    } catch (e) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: "Ошибка сохранения анкеты",
        };
    }
};
