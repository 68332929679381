import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export function useUser() {
    const { data, error, isLoading, isFetched, refetch } = useQuery({
        queryKey: ["user"],
        queryFn: async () => {
            try {
                const response = await axios.get("specialist/auth");

                return response.data;
            } catch (e) {
                const message = e.response?.data?.message || "Ошибка загрузки данных";

                // eslint-disable-next-line no-throw-literal
                throw {
                    message,
                };
            }
        },
    });

    return {
        user: data,
        userError: error,
        isUserLoading: isLoading,
        isUserFetched: isFetched,
        userRefetch: refetch,
    };
}
