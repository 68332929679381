import { useQuery } from "@tanstack/react-query";

import { fetchRespondentById } from "api/fetchRespondentById";

export const useFetchRespondent = (id) => {
    const {
        data: respondent,
        isLoading: respondentLoading,
        error: respondentError,
    } = useQuery({
        queryKey: ["respondent", id],
        queryFn: () => fetchRespondentById(id),
    });

    return {
        respondent,
        respondentLoading,
        respondentError,
    };
};
