import React from 'react';
import axios from 'axios';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { IconContext } from 'react-icons';

import { rootReducer } from 'redux/rootReducer';
import App from './App';
import './static/scss/index.scss';

axios.defaults.baseURL = `/api/`;
axios.defaults.withCredentials = true;

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: false
        }
    }
});

const app = (
    <React.StrictMode>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <IconContext.Provider value={{ className: 'reactIcon' }}>
                    <App />
                </IconContext.Provider>
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>
);

render(app, document.getElementById('root'));
