import { useEffect, useState } from "react";

import { declinations } from "helpers/declinations";
import { calcScalesPoints } from "helpers/scales";
import { Modal } from "components/modals";
import styles from "./ExaminationMethodResultModal.module.scss";

export const ExaminationMethodResultModal = ({ data, hideModal }) => {
    const {
        form,
        values,
        formData: { title, getResult },
    } = data;
    const [points, setPoints] = useState(null);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (values) {
            let calcPoints;

            if (form === "formGetUpAndGo") {
                calcPoints = Object.values(values).reduce((acc, item) => acc + Number(item), 0);
                // } else if (form === "formGHIS") {
                //     calcPoints = (calcScalesPoints(values) / 7).toFixed(2);
            } else {
                calcPoints = calcScalesPoints(values);
            }

            setPoints(calcPoints);
            setResult(getResult(calcPoints, { questionsCount: Object.keys(values).length }));
        }
    }, [form, values, getResult]);

    return (
        <Modal closeModal={hideModal}>
            <div className={styles.root}>
                <h3 className={styles.title}>{title}</h3>

                <div>
                    <h4 className={styles.pointsTitle}>
                        {points} {declinations(points, "point")}
                    </h4>

                    {result !== null && (
                        <div className="recommendations-page__list-answers">
                            <div className="recommendations-page__list-answers-item">
                                <p>{result}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};
