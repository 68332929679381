import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

const defaultOptions = ["да", "нет"];

export const FormRadioGroup = ({ name, label, control, options, defaultChecked = "нет", error = null }) => {
    const [optionsState, setOptionsState] = useState([]);

    useEffect(() => {
        if (!options) {
            setOptionsState(defaultOptions.map((value) => ({ value, label: value })));
        } else {
            const isOptionsString = options.every((item) => typeof item === "string");

            if (!isOptionsString) {
                setOptionsState(options);
            } else {
                setOptionsState(options.map((value) => ({ value, label: value })));
            }
        }
    }, [options]);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, ...field } }) => (
                <div className="questionnaire__form-item">
                    {label && <p>{label}</p>}

                    <div className="questionnaire__form-item-radio questionnaire__input">
                        {optionsState.map((option) => {
                            const isDefaultChecked = field.value
                                ? field.value === option.value
                                : defaultChecked.toLowerCase() === option.label.toLowerCase();

                            return (
                                <label key={option.label}>
                                    <input
                                        type="radio"
                                        value={option.value}
                                        {...field}
                                        defaultChecked={isDefaultChecked}
                                        onChange={() => onChange(option.value)}
                                    />
                                    {option.label}
                                </label>
                            );
                        })}
                    </div>
                    {error && <span>{error.message}</span>}
                </div>
            )}
        />
    );
};
