import { FormRadioGroupWithValue } from "components/forms";
import { questionsFormGeriatricDepressionScale as questions } from "components/questionnaire/questions";

export const QuestionnaireGeriatricDepressionScaleForm = ({ control, errors }) => {
    return (
        <>
            <FormRadioGroupWithValue
                control={control}
                name="overallLifeSatisfaction"
                label={questions.overallLifeSatisfaction}
                options={[
                    { value: 0, label: "да" },
                    { value: 1, label: "нет" },
                ]}
                error={errors.overallLifeSatisfaction}
            />

            <FormRadioGroupWithValue
                control={control}
                name="abandonmentOfActivitiesAndInterests"
                label={questions.abandonmentOfActivitiesAndInterests}
                options={[
                    { value: 1, label: "да" },
                    { value: 0, label: "нет" },
                ]}
                error={errors.abandonmentOfActivitiesAndInterests}
            />

            <FormRadioGroupWithValue
                control={control}
                name="feelingOfEmptinessInLife"
                label={questions.feelingOfEmptinessInLife}
                options={[
                    { value: 1, label: "да" },
                    { value: 0, label: "нет" },
                ]}
                error={errors.feelingOfEmptinessInLife}
            />

            <FormRadioGroupWithValue
                control={control}
                name="frequentBoredom"
                label={questions.frequentBoredom}
                options={[
                    { value: 1, label: "да" },
                    { value: 0, label: "нет" },
                ]}
                error={errors.frequentBoredom}
            />

            <FormRadioGroupWithValue
                control={control}
                name="goodMoodMostOfTheTime"
                label={questions.goodMoodMostOfTheTime}
                options={[
                    { value: 0, label: "да" },
                    { value: 1, label: "нет" },
                ]}
                error={errors.goodMoodMostOfTheTime}
            />

            <FormRadioGroupWithValue
                control={control}
                name="fearOfSomethingBadHappening"
                label={questions.fearOfSomethingBadHappening}
                options={[
                    { value: 1, label: "да" },
                    { value: 0, label: "нет" },
                ]}
                error={errors.fearOfSomethingBadHappening}
            />

            <FormRadioGroupWithValue
                control={control}
                name="feelingHappyMostOfTheTime"
                label={questions.feelingHappyMostOfTheTime}
                options={[
                    { value: 0, label: "да" },
                    { value: 1, label: "нет" },
                ]}
                error={errors.feelingHappyMostOfTheTime}
            />

            <FormRadioGroupWithValue
                control={control}
                name="feelingHelpless"
                label={questions.feelingHelpless}
                options={[
                    { value: 1, label: "да" },
                    { value: 0, label: "нет" },
                ]}
                error={errors.feelingHelpless}
            />

            <FormRadioGroupWithValue
                control={control}
                name="preferringToStayAtHome"
                label={questions.preferringToStayAtHome}
                options={[
                    { value: 1, label: "да" },
                    { value: 0, label: "нет" },
                ]}
                error={errors.preferringToStayAtHome}
            />

            <FormRadioGroupWithValue
                control={control}
                name="perceptionOfMemoryWorseThanOthers"
                label={questions.perceptionOfMemoryWorseThanOthers}
                options={[
                    { value: 1, label: "да" },
                    { value: 0, label: "нет" },
                ]}
                error={errors.perceptionOfMemoryWorseThanOthers}
            />

            <FormRadioGroupWithValue
                control={control}
                name="livingIsWonderful"
                label={questions.livingIsWonderful}
                options={[
                    { value: 0, label: "да" },
                    { value: 1, label: "нет" },
                ]}
                error={errors.livingIsWonderful}
            />

            <FormRadioGroupWithValue
                control={control}
                name="feelingUseless"
                label={questions.feelingUseless}
                options={[
                    { value: 1, label: "да" },
                    { value: 0, label: "нет" },
                ]}
                error={errors.feelingUseless}
            />

            <FormRadioGroupWithValue
                control={control}
                name="feelingEnergetic"
                label={questions.feelingEnergetic}
                options={[
                    { value: 0, label: "да" },
                    { value: 1, label: "нет" },
                ]}
                error={errors.feelingEnergetic}
            />

            <FormRadioGroupWithValue
                control={control}
                name="senseOfHopelessness"
                label={questions.senseOfHopelessness}
                options={[
                    { value: 1, label: "да" },
                    { value: 0, label: "нет" },
                ]}
                error={errors.senseOfHopelessness}
            />

            <FormRadioGroupWithValue
                control={control}
                name="comparisonToOthersLivingMoreFulfillingLives"
                label={questions.comparisonToOthersLivingMoreFulfillingLives}
                options={[
                    { value: 1, label: "да" },
                    { value: 0, label: "нет" },
                ]}
                error={errors.comparisonToOthersLivingMoreFulfillingLives}
            />
        </>
    );
};
