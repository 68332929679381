import React, { memo, useEffect, useState } from "react";

import { useExaminationStore } from "store/useExaminationStore";
import { classNames } from "helpers/app";
import { formsWithoutResult, questionnaireButtons, questionnaireForms } from "components/questionnaire/consts";
import { ExaminationMethodResultModal, ExaminationMethodsBlockItem } from "components/examinations/";
import styles from "./ExaminationSetResult.module.scss";

export const ExaminationMethodsResults = memo(({ handleSetStep }) => {
    const completedForms = useExaminationStore((state) => state.completedForms);
    const [completedMethods, setCompletedMethods] = useState([]);
    const [showResultForm, setShowResultForm] = useState(null);

    useEffect(() => {
        const filledForms = Object.keys(completedForms);

        setCompletedMethods(
            questionnaireButtons.filter(({ key }) => filledForms.includes(key) && !formsWithoutResult.includes(key))
        );
    }, [completedForms]);

    const handleShowResult = (form) => {
        if (completedForms[form]) {
            setShowResultForm({
                form,
                values: completedForms[form],
                formData: questionnaireForms[form],
            });
        }
    };

    const handleHideResultForm = () => {
        setShowResultForm(null);
    };

    return (
        <>
            <div>
                <h3 className={styles.title}>Результаты опроса</h3>

                <div className={classNames([styles.wrapper, styles.grid])}>
                    {completedMethods.map(({ key, title }) => (
                        <ExaminationMethodsBlockItem
                            title={title}
                            step={key}
                            handleSetStep={handleSetStep}
                            handleShowResult={handleShowResult}
                            isSuccess
                            isDisabled
                            isEditable={false}
                            key={key}
                        />
                    ))}
                </div>

                {!!completedMethods.length && (
                    <button onClick={() => handleSetStep("form")} className="main-btn top-margin">
                        Вернуться к завершению опроса
                    </button>
                )}
            </div>

            {showResultForm && <ExaminationMethodResultModal data={showResultForm} hideModal={handleHideResultForm} />}
        </>
    );
});
