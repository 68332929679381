import { FormSelectMulti } from "components/forms";
import { questionsFormPhysicalFunctioningTest as questions } from "components/questionnaire/questions";

export const QuestionnairePhysicalFunctioningTestForm = ({ control, errors }) => {
    return (
        <>
            <FormSelectMulti
                control={control}
                name="balanceAssessmentFeetTogether"
                label={questions.balanceAssessmentFeetTogether}
                placeholder="Один вариант ответа"
                error={errors.balanceAssessmentFeetTogether}
                options={[
                    { value: 0, label: "менее 10 сек." },
                    { value: 1, label: "более 10 сек." },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="balanceAssessmentSemiTandem"
                label={questions.balanceAssessmentSemiTandem}
                placeholder="Один вариант ответа"
                error={errors.balanceAssessmentSemiTandem}
                options={[
                    { value: 0, label: "менее 10 сек." },
                    { value: 1, label: "более 10 сек." },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="balanceAssessmentTandem"
                label={questions.balanceAssessmentTandem}
                placeholder="Один вариант ответа"
                error={errors.balanceAssessmentTandem}
                options={[
                    { value: 0, label: "менее 3 сек." },
                    { value: 1, label: "3-9,99 сек." },
                    { value: 2, label: "более 10 сек." },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="walkingSpeedAssessment"
                label={questions.walkingSpeedAssessment}
                placeholder="Один вариант ответа"
                error={errors.walkingSpeedAssessment}
                options={[
                    { value: 4, label: "менее 4,82 сек." },
                    { value: 3, label: "3,82-6,2 сек." },
                    { value: 2, label: "6,32-8,7 сек." },
                    { value: 1, label: "более 8,7 сек." },
                    { value: 0, label: "не может" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="chairRiseTest"
                label={questions.chairRiseTest}
                placeholder="Один вариант ответа"
                error={errors.chairRiseTest}
                options={[
                    { value: 4, label: "менее 11,19 сек." },
                    { value: 3, label: "11,2-13,69 сек." },
                    { value: 2, label: "13,7-16,69 сек." },
                    { value: 1, label: "более 16,7 сек." },
                    { value: 0, label: "не может" },
                ]}
                maxSelectOptions={1}
            />
        </>
    );
};
