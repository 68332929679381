import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { Loader } from "components/Loader";
import RespondentCreatePage from "pages/RespondentCreatePage";
import RespondentPage from "pages/RespondentPage";
import ExaminationPage from "pages/ExaminationPage";

const DashboardPage = lazy(() => import("pages/DashboardPage"));
const LoginPage = lazy(() => import("pages/LoginPage"));
const CodePage = lazy(() => import("pages/CodePage"));
const SettingsPage = lazy(() => import("pages/SettingsPage"));
// const QuestionnairePage = lazy(() => import("pages/QuestionnairePage"));
// const QuestionnaireResultPage = lazy(() => import("pages/QuestionnaireResultPage"));
// const NotAcceptQuestionnairePage = lazy(() => import("pages/NotAcceptQuestionnairePage"));
const RespondentsPage = lazy(() => import("pages/RespondentsPage"));
// const RespondentPage = lazy(() => import("pages/RespondentPage"));
// const ExaminationPage = lazy(() => import("pages/ExaminationPage"));
// const RespondentCreatePage = lazy(() => import("pages/RespondentCreatePage"));

// const StatisticPage = lazy(() => import("pages/StatisticPage"));
// const StatisticRespondentsPage = lazy(() =>
//   import("pages/StatisticRespondentsPage")
// );
// const RespondentsAgePage = lazy(() =>
//   import("pages/statistic/RespondentsAgePage")
// );
// const RespondentsAbortionPage = lazy(() =>
//   import("pages/statistic/RespondentsAbortionPage")
// );
// const RespondentsLocationPage = lazy(() =>
//   import("pages/statistic/RespondentsLocationPage")
// );
// const RespondentsYoungestChildPage = lazy(() =>
//   import("pages/statistic/RespondentsYoungestChildPage")
// );
// const RespondentsStatisticBySlugPage = lazy(() =>
//   import("pages/statistic/RespondentsStatisticBySlugPage")
// );
// const RespondentsSiblingsPage = lazy(() =>
//   import("pages/statistic/RespondentsSiblingsPage")
// );
// const StatisticFactorsPage = lazy(() => import("pages/StatisticFactorsPage"));
// const StatisticAnswersPage = lazy(() => import("pages/StatisticAnswersPage"));
// const EditingMainPage = lazy(() => import("pages/editing/EditingMainPage"));
// const QuestionEditPage = lazy(() => import("pages/editing/QuestionEditPage"));
// const QuestionAnswerEditPage = lazy(() =>
//   import("pages/editing/QuestionAnswerEditPage")
// );
// const FactorEditPage = lazy(() => import("pages/editing/FactorEditPage"));
// const FactorAnswerEditPage = lazy(() =>
//   import("pages/editing/FactorAnswerEditPage")
// );
// const RecommendationEditPage = lazy(() =>
//   import("pages/editing/RecommendationEditPage")
// );
// const NewRecomsListPage = lazy(() => import("pages/editing/NewRecomsListPage"));
// const RecommendationModeratingPage = lazy(() =>
//   import("pages/editing/RecommendationModeratingPage")
// );
// const RecommendationCorrectingPage = lazy(() =>
//   import("pages/editing/RecommendationCorrectingPage")
// );
// const RecommendationAddPage = lazy(() =>
//   import("pages/editing/RecommendationAddPage")
// );
// const RecommendationOrderPage = lazy(() =>
//   import("pages/editing/RecommendationsOrderPage")
// );
// const QuestionAnswerAddPage = lazy(() =>
//   import("pages/editing/QuestionAnswerAddPage")
// );
// const FactorAnswerAddPage = lazy(() =>
//   import("pages/editing/FactorAnswerAddPage")
// );
// const TextEditPage = lazy(() => import("pages/editing/TextEditPage"));
// const FiltersNamesEditPage = lazy(() =>
//   import("pages/editing/FiltersNamesEditPage")
// );
// const AccountsMainPage = lazy(() => import("pages/accounts/AccountsMainPage"));
// const AccountsAddPage = lazy(() => import("pages/accounts/AccountsAddPage"));
// const AccountsEditPage = lazy(() => import("pages/accounts/AccountsEditPage"));
// const AccountPage = lazy(() => import("pages/accounts/AccountPage"));
// const ModeratorStatisticPage = lazy(() =>
//   import("pages/moderator/ModeratorStatisticPage")
// );
// const ModeratorRecomsMainPage = lazy(() =>
//   import("pages/moderator/ModeratorRecomsMainPage")
// );
// const ModeratorQuestionPage = lazy(() =>
//   import("pages/moderator/ModeratorQuestionPage")
// );
// const ModeratorQuestionAnswerPage = lazy(() =>
//   import("pages/moderator/ModeratorQuestionAnswerPage")
// );
// const ModeratorRespondentsPage = lazy(() =>
//   import("pages/moderator/ModeratorRespondentsPage")
// );
// const ModeratorRespondentsStatisticBySlugPage = lazy(() =>
//   import("pages/moderator/ModeratorRespondentsStatisticBySlugPage")
// );
// const ModeratorFactorPage = lazy(() =>
//   import("pages/moderator/ModeratorFactorPage")
// );
// const ModeratorFactorAnswerPage = lazy(() =>
//   import("pages/moderator/ModeratorFactorAnswerPage")
// );
// const ModeratorRecommendationAddPage = lazy(() =>
//   import("pages/moderator/ModeratorRecommendationAddPage")
// );
// const ModeratorRecommendationEditPage = lazy(() =>
//   import("pages/moderator/ModeratorRecommendationEditPage")
// );
// const ModeratorRecommendationCorrectionAddPage = lazy(() =>
//   import("pages/moderator/ModeratorRecommendationCorrectionAddPage")
// );
// const ModeratorRecommendationCorrectionPage = lazy(() =>
//   import("pages/moderator/ModeratorRecommendationCorrectionPage")
// );
// const ModeratorInstructionPage = lazy(() =>
//   import("pages/moderator/ModeratorInstructionPage")
// );
// const ModeratorNotifications = lazy(() =>
//   import("pages/moderator/ModeratorNotifications")
// );
// const ModeratorStatisticFactorsPage = lazy(() =>
//   import("pages/moderator/ModeratorStatisticFactorsPage")
// );
// const ModeratorRespondentsReportPage = lazy(() =>
//   import("pages/moderator/ModeratorRespondentsReportPage")
// );
// const ModeratorRespondentsFormBySlugPage = lazy(() =>
//   import("pages/moderator/ModeratorRespondentsFormBySlugPage")
// );
// const ChartPage = lazy(() => import("pages/editing/ChartPage"));

function App() {
    // const { user } = useSelector((state) => state.auth);
    //
    // useEffect(() => {
    //     if (user && user.role === "moderator") {
    //         (async () => {
    //             try {
    //                 await axios.post("specialist/moderator/actions?actionType=login");
    //             } catch (e) {}
    //         })();
    //     }
    // }, [user]);

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader />}>
                <Routes>
                    <Route path="/" element={<DashboardPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/dashboard" element={<DashboardPage />} />

                    <Route path="/code/:specialistId" element={<CodePage />} />
                    {/*<Route path="/questionnaire/stop" element={<NotAcceptQuestionnairePage />} exact />*/}
                    {/*<Route path="/questionnaire/:specialistId" element={<QuestionnairePage />} exact />*/}
                    {/*<Route path="/questionnaire/:specialistId/result" element={<QuestionnaireResultPage />} />*/}
                    <Route path="/respondent/new/:specialistId" element={<RespondentCreatePage />} />
                    <Route path="/respondent/:id" element={<RespondentPage />} />
                    <Route path="/respondents" element={<RespondentsPage />} />
                    <Route path="/examination/:id" element={<ExaminationPage />} />

                    {/* editing */}
                    {/*<Route path="/editing" element={<EditingMainPage />} />*/}
                    {/*<Route path="/editing/question/:id" element={<QuestionEditPage />} />*/}
                    {/*<Route*/}
                    {/*  path="/editing/question/:id/add-answer"*/}
                    {/*  element={<QuestionAnswerAddPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/editing/question-answer/:id"*/}
                    {/*  element={<QuestionAnswerEditPage />}*/}
                    {/*/>*/}
                    {/*<Route path="/editing/factor/:id" element={<FactorEditPage />} />*/}
                    {/*<Route*/}
                    {/*  path="/editing/factor/:id/add-answer"*/}
                    {/*  element={<FactorAnswerAddPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/editing/factor-answer/:id"*/}
                    {/*  element={<FactorAnswerEditPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/editing/recommendation/add"*/}
                    {/*  element={<RecommendationAddPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/editing/recommendation/order"*/}
                    {/*  element={<RecommendationOrderPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/editing/recommendation/moderating/list"*/}
                    {/*  element={<NewRecomsListPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/editing/recommendation/moderating/:id"*/}
                    {/*  element={<RecommendationModeratingPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/editing/recommendation/correcting/:id"*/}
                    {/*  element={<RecommendationCorrectingPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/editing/recommendation/:id"*/}
                    {/*  element={<RecommendationEditPage />}*/}
                    {/*/>*/}
                    {/*<Route path="/editing/texts/:slug" element={<TextEditPage />} />*/}
                    {/*<Route*/}
                    {/*  path="/editing/filters/recommendations"*/}
                    {/*  element={<FiltersNamesEditPage />}*/}
                    {/*/>*/}
                    {/*<Route path="/editing/accounts" element={<AccountsMainPage />} />*/}
                    {/*<Route path="/editing/accounts/add" element={<AccountsAddPage />} />*/}
                    {/*<Route*/}
                    {/*  path="/editing/accounts/:id/edit"*/}
                    {/*  element={<AccountsEditPage />}*/}
                    {/*/>*/}
                    {/*<Route path="/editing/accounts/:id" element={<AccountPage />} />*/}

                    {/*/!* moderator *!/*/}
                    {/*<Route*/}
                    {/*  path="/moderator/respondents"*/}
                    {/*  element={<ModeratorRespondentsReportPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/statistic"*/}
                    {/*  element={<ModeratorStatisticPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/statistic/respondents"*/}
                    {/*  element={<ModeratorRespondentsPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/statistic/respondents/age"*/}
                    {/*  element={<ModeratorRespondentsFormBySlugPage slug="age" />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/statistic/respondents/location"*/}
                    {/*  element={<ModeratorRespondentsFormBySlugPage slug="location" />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/statistic/respondents/youngest-child"*/}
                    {/*  element={*/}
                    {/*    <ModeratorRespondentsFormBySlugPage slug="youngest-child" />*/}
                    {/*  }*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/statistic/respondents/abortion"*/}
                    {/*  element={<ModeratorRespondentsFormBySlugPage slug="abortion" />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/statistic/respondents/siblings"*/}
                    {/*  element={<ModeratorRespondentsFormBySlugPage slug="siblings" />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/statistic/respondents/:slug"*/}
                    {/*  element={<ModeratorRespondentsStatisticBySlugPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/statistic/factors"*/}
                    {/*  element={<ModeratorStatisticFactorsPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/recoms"*/}
                    {/*  element={<ModeratorRecomsMainPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/question/:id"*/}
                    {/*  element={<ModeratorQuestionPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/question-answer/:id"*/}
                    {/*  element={<ModeratorQuestionAnswerPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/factor/:id"*/}
                    {/*  element={<ModeratorFactorPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/factor-answer/:id"*/}
                    {/*  element={<ModeratorFactorAnswerPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/recommendation/add"*/}
                    {/*  element={<ModeratorRecommendationAddPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/correction/add/:id"*/}
                    {/*  element={<ModeratorRecommendationCorrectionAddPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/correction/:id"*/}
                    {/*  element={<ModeratorRecommendationCorrectionPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/recommendation/:id"*/}
                    {/*  element={<ModeratorRecommendationEditPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/notifications"*/}
                    {/*  element={<ModeratorNotifications />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*  path="/moderator/instruction"*/}
                    {/*  element={<ModeratorInstructionPage />}*/}
                    {/*/>*/}

                    {/* statistic */}
                    {/*<Route path="/statistic" element={<StatisticPage />} />*/}
                    {/*<Route path="/statistic/chart" element={<ChartPage />} />*/}
                    {/*<Route*/}
                    {/*    path="/statistic/respondents"*/}
                    {/*    element={<StatisticRespondentsPage />}*/}
                    {/*    exact*/}
                    {/*/>*/}
                    {/*<Route path="/statistic/respondents/age" element={<RespondentsAgePage />} />*/}
                    {/*<Route*/}
                    {/*    path="/statistic/respondents/location"*/}
                    {/*    element={<RespondentsLocationPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*    path="/statistic/respondents/youngest-child"*/}
                    {/*    element={<RespondentsYoungestChildPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*    path="/statistic/respondents/siblings"*/}
                    {/*    element={<RespondentsSiblingsPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*    path="/statistic/respondents/abortion"*/}
                    {/*    element={<RespondentsAbortionPage />}*/}
                    {/*/>*/}
                    {/*<Route*/}
                    {/*    path="/statistic/respondents/:slug"*/}
                    {/*    element={<RespondentsStatisticBySlugPage />}*/}
                    {/*/>*/}
                    {/*<Route path="/statistic/factors" element={<StatisticFactorsPage />} />*/}
                    {/*<Route path="/statistic/answers" element={<StatisticAnswersPage />} />*/}
                    <Route path="/settings" element={<SettingsPage />} />

                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
