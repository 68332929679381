import React, { memo, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";

import { fetchRespondents } from "api/fetchRespondents";
import { Loader } from "components/Loader";
import { RespondentsItem } from "components/respondents";

const PAGE_LIMIT = 50;

export const RespondentsList = memo(() => {
    const { ref, entry } = useInView({
        threshold: 0,
    });
    const {
        data: respondents,
        fetchNextPage,
        hasNextPage,
        isFetching,
    } = useInfiniteQuery({
        // queryKey: ["respondents", { region, specialist, dateStart, dateEnd, mark }],
        queryKey: ["respondents"],
        queryFn: ({ pageParam = 1 }) =>
            // fetchRespondents({ region, specialist, dateStart, dateEnd, mark }, pageParam, PAGE_LIMIT),
            fetchRespondents({}, pageParam, PAGE_LIMIT),
        getNextPageParam: (lastPage, pages) => (lastPage.length === PAGE_LIMIT ? pages.length + 1 : undefined),
        // refetchOnMount: true,
    });

    useEffect(() => {
        if (entry?.isIntersecting && !isFetching && hasNextPage) {
            fetchNextPage();
        }
    }, [entry]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="respondent-page__list">
            {!isFetching && !respondents?.pages[0].length && (
                <p className="respondent-page__list-not-found">Респонденты не найдены</p>
            )}

            {respondents?.pages.map((page, i) => (
                <React.Fragment key={i}>
                    {page.map((respondent) => (
                        <RespondentsItem respondent={respondent} key={respondent._id} />
                    ))}
                </React.Fragment>
            ))}

            {isFetching && <Loader />}
            <div ref={ref} />
        </div>
    );
});
