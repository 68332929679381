export const calcScalesPoints = (form) =>
    Object.values(form)
        .flat()
        .map(({ value }) => value)
        .reduce((acc, item) => acc + item, 0);

export const calcCharlsonComorbidityIndexAges = (age) => {
    switch (true) {
        case age >= 40 && age <= 49:
            return 1;
        case age >= 50 && age <= 59:
            return 2;
        case age >= 60 && age <= 69:
            return 3;
        case age >= 70 && age <= 79:
            return 4;
        case age >= 80 && age <= 89:
            return 5;
        case age >= 90:
            return 6;
        default:
            return 0;
    }
};

const _getBarthelScaleValue = (points) => {
    switch (true) {
        case points >= 91 && points <= 99:
            return { value: 1, label: "91-99" };
        case points >= 90 && points <= 61:
            return { value: 0.5, label: "90-61" };
        case points >= 60:
            return { value: 0, label: "60 и меньше" };
        default:
            return { value: 0, label: "60 и меньше" };
    }
};

const _getLawtonScaleValue = (points) => {
    switch (true) {
        case points >= 8 && points <= 6:
            return { value: 1, label: "от 8 до 6" };
        case points >= 5 && points <= 4:
            return { value: 0.5, label: "от 5 до 4" };
        case points >= 3:
            return { value: 0, label: "от 3 до 0" };
        default:
            return { value: 0, label: "от 3 до 0" };
    }
};

const _getMMSEValue = (points) => {
    switch (true) {
        case points >= 28 && points <= 30:
            return { value: 1, label: "28-30" };
        case points >= 24 && points <= 27:
            return { value: 0.5, label: "24-27" };
        case points >= 23:
            return { value: 0, label: "23 и менее" };
        default:
            return { value: 0, label: "23 и менее" };
    }
};

const _getComorbidityIndexValue = (points) => {
    switch (true) {
        case points === 0:
            return { value: 1, label: "0" };
        case points >= 1 && points <= 2:
            return { value: 0.5, label: "от 1 до 2" };
        case points >= 3:
            return { value: 0, label: "≥3" };
        default:
            return { value: 0, label: "≥3" };
    }
};

const _getMiniNutritionalAssessmentValue = (points) => {
    switch (true) {
        case points >= 24:
            return { value: 1, label: "≥24" };
        case points >= 17:
            return { value: 0.5, label: "17-23,5" };
        case points < 17:
            return { value: 0, label: "<17" };
        default:
            return { value: 0, label: "<17" };
    }
};

export const calcGHISFilledScales = (questionnaire) => {
    const { formMMSE, formBarthelScale, formLawtonScale, formCharlsonComorbidityIndex, formNutritionalRatingScale } =
        questionnaire;

    if (
        !formMMSE ||
        !formBarthelScale ||
        !formLawtonScale ||
        !formCharlsonComorbidityIndex ||
        !formNutritionalRatingScale
    ) {
        return null;
    }

    const pointsBasicActivitiesDailyLiving = calcScalesPoints(formBarthelScale);
    const pointsInstrumentalActivitiesDailyLiving = calcScalesPoints(formLawtonScale);
    const pointsMiniMentalStateExamination = calcScalesPoints(formMMSE);
    const pointsComorbidityIndex = calcScalesPoints(formCharlsonComorbidityIndex);
    const pointsMiniNutritionalAssessmen = calcScalesPoints(formNutritionalRatingScale);

    return {
        basicActivitiesDailyLiving: _getBarthelScaleValue(pointsBasicActivitiesDailyLiving),
        instrumentalActivitiesDailyLiving: _getLawtonScaleValue(pointsInstrumentalActivitiesDailyLiving),
        miniMentalStateExamination: _getMMSEValue(pointsMiniMentalStateExamination),
        comorbidityIndex: _getComorbidityIndexValue(pointsComorbidityIndex),
        miniNutritionalAssessment: _getMiniNutritionalAssessmentValue(pointsMiniNutritionalAssessmen),
    };
};
