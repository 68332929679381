import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useExaminationStore } from "store/useExaminationStore";
import { useSetCompletedForms } from "hooks/useSetCompletedForms";
import { QuestionnaireFormWrapper, QuestionnaireGeriatricDepressionScaleForm } from "components/questionnaire";
import { requiredSelectNumber } from "../schemas";
import { questionnaireForms } from "components/questionnaire/consts";

const validationSchema = z.object({
    overallLifeSatisfaction: requiredSelectNumber,
    abandonmentOfActivitiesAndInterests: requiredSelectNumber,
    feelingOfEmptinessInLife: requiredSelectNumber,
    frequentBoredom: requiredSelectNumber,
    goodMoodMostOfTheTime: requiredSelectNumber,
    fearOfSomethingBadHappening: requiredSelectNumber,
    feelingHappyMostOfTheTime: requiredSelectNumber,
    feelingHelpless: requiredSelectNumber,
    preferringToStayAtHome: requiredSelectNumber,
    perceptionOfMemoryWorseThanOthers: requiredSelectNumber,
    livingIsWonderful: requiredSelectNumber,
    feelingUseless: requiredSelectNumber,
    feelingEnergetic: requiredSelectNumber,
    senseOfHopelessness: requiredSelectNumber,
    comparisonToOthersLivingMoreFulfillingLives: requiredSelectNumber,
});

export const QuestionnaireGeriatricDepressionScale = ({ form, handleSaveForm, handleShowResult, isLoading }) => {
    const [startFillingTime] = useState(Date.now);
    const completedForms = useExaminationStore((state) => state.completedForms);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            overallLifeSatisfaction: {},
            abandonmentOfActivitiesAndInterests: {},
            feelingOfEmptinessInLife: {},
            frequentBoredom: {},
            goodMoodMostOfTheTime: {},
            fearOfSomethingBadHappening: {},
            feelingHappyMostOfTheTime: {},
            feelingHelpless: {},
            preferringToStayAtHome: {},
            perceptionOfMemoryWorseThanOthers: {},
            livingIsWonderful: {},
            feelingUseless: {},
            feelingEnergetic: {},
            senseOfHopelessness: {},
            comparisonToOthersLivingMoreFulfillingLives: {},
        },
    });
    useSetCompletedForms({ form, completedForms, setValue });

    const onSubmit = (data) => {
        handleSaveForm({ form, data, fillingTime: Date.now() - startFillingTime });
    };

    const onShowResult = (data) => {
        handleShowResult({
            form,
            values: data,
            formData: questionnaireForms[form],
        });
    };

    return (
        <QuestionnaireFormWrapper
            handleSubmit={handleSubmit(onSubmit)}
            handleShowResults={handleSubmit(onShowResult)}
            isLoading={isLoading}
        >
            <QuestionnaireGeriatricDepressionScaleForm control={control} errors={errors} key={completedForms[form]} />
        </QuestionnaireFormWrapper>
    );
};
