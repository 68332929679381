import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export function useFetchExamination(id) {
    const { data, error, isLoading } = useQuery({
        queryKey: ["examination", id],
        queryFn: async () => {
            try {
                const response = await axios.get(`examination/${id}`);

                return response.data;
            } catch (e) {
                const message = e.response?.data?.message || "Ошибка загрузки данных";

                // eslint-disable-next-line no-throw-literal
                throw {
                    message,
                };
            }
        },
    });

    return {
        examination: data,
        examinationError: error,
        isExaminationLoading: isLoading,
    };
}
