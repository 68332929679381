import React from "react";
import { Controller } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import { classNames } from "helpers/app";

export const FormTextArea = ({ name, label, placeholder, control, error, minRows = 1, maxRows = 100 }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => (
                <div className={classNames("questionnaire__form-item", { error })}>
                    <p>{label}</p>
                    <TextareaAutosize
                        className="questionnaire__input textarea-autosize"
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        minRows={minRows}
                        maxRows={maxRows}
                    />
                    {error && <span style={{ marginTop: "01" }}>{error.message}</span>}
                </div>
            )}
        />
    );
};
