import React from "react";
import { Controller } from "react-hook-form";

import { classNames } from "helpers/app";

export const FormInput = ({ name, label = null, placeholder, control, error, type = "text", inputProps = {} }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <div
                    className={classNames("questionnaire__form-item", {
                        error,
                    })}
                >
                    {label && <p>{label}</p>}

                    <input
                        type={type}
                        className="questionnaire__input"
                        placeholder={placeholder}
                        {...field}
                        {...inputProps}
                    />
                    {error && <span>{error.message}</span>}
                </div>
            )}
        />
    );
};
