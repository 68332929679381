import { FormRadioGroupWithValue } from "components/forms";
import { questionsFormAgeNotBarrier as questions } from "components/questionnaire/questions";

const options = [
    { value: 1, label: "да" },
    { value: 0, label: "нет" },
];

export const QuestionnaireAgeNotBarrierForm = ({ control, errors }) => {
    return (
        <>
            <FormRadioGroupWithValue
                control={control}
                name="weightLossInLast6Months"
                label={questions.weightLossInLast6Months}
                options={options}
                error={errors.weightLossInLast6Months}
            />
            <FormRadioGroupWithValue
                control={control}
                name="limitationsDueToImpairedSenses"
                label={questions.limitationsDueToImpairedSenses}
                options={options}
                error={errors.limitationsDueToImpairedSenses}
            />
            <FormRadioGroupWithValue
                control={control}
                name="fallsOrInjuriesInLastYear"
                label={questions.fallsOrInjuriesInLastYear}
                options={options}
                error={errors.fallsOrInjuriesInLastYear}
            />
            <FormRadioGroupWithValue
                control={control}
                name="depressionOrAnxietyInLastWeeks"
                label={questions.depressionOrAnxietyInLastWeeks}
                options={options}
                error={errors.depressionOrAnxietyInLastWeeks}
            />
            <FormRadioGroupWithValue
                control={control}
                name="memoryOrientationPlanningIssues"
                label={questions.memoryOrientationPlanningIssues}
                options={options}
                error={errors.memoryOrientationPlanningIssues}
            />
            <FormRadioGroupWithValue
                control={control}
                name="urinaryIncontinence"
                label={questions.urinaryIncontinence}
                options={options}
                error={errors.urinaryIncontinence}
            />
            <FormRadioGroupWithValue
                control={control}
                name="mobilityDifficulties"
                label={questions.mobilityDifficulties}
                options={options}
                error={errors.mobilityDifficulties}
            />
        </>
    );
};
