import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";

export function useSearchParamsDelete() {
    const [searchParams, setSearchParams] = useSearchParams();

    return useCallback(
        (key) => {
            if (searchParams.has(key)) {
                searchParams.delete(key);
                setSearchParams(searchParams);
            }
        },
        [searchParams, setSearchParams]
    );
}
