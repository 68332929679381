import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

import { classNames } from "helpers/app";
import { selectStyles } from "components/forms/selectStyles";
import { FormInput } from "components/forms/FormInput";

export const FormSelect = ({
    name,
    label,
    placeholder = "Один вариант ответа",
    control,
    error,
    options,
    otherField,
    otherFieldName,
}) => {
    const [optionsList, setOptionsList] = useState([]);
    const [isShowOtherField, setIsShowOtherField] = useState(false);

    useEffect(() => {
        setOptionsList(options.map((value) => ({ value, label: value })));
    }, [options]);

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field: { value, onChange } }) => (
                    <div
                        className={classNames("questionnaire__form-item", {
                            error,
                        })}
                    >
                        <p>{label}</p>
                        <Select
                            value={
                                value
                                    ? {
                                          value,
                                          label: value,
                                      }
                                    : null
                            }
                            onChange={(e) => {
                                if (otherField) {
                                    if (e.value === otherField) {
                                        setIsShowOtherField(true);
                                    } else {
                                        setIsShowOtherField(false);
                                    }
                                }

                                onChange(e.value);
                            }}
                            options={optionsList}
                            placeholder={placeholder}
                            styles={selectStyles}
                            isSearchable={false}
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                        />
                        <div className="select" />
                        {error && <span>{error.message}</span>}
                    </div>
                )}
            />

            {isShowOtherField && (
                <FormInput
                    control={control}
                    name={otherFieldName}
                    label={`${label} (${otherField})`}
                    placeholder="заполните поле"
                />
            )}
        </>
    );
};
