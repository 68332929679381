import { memo, useEffect, useState } from "react";
import { declinations } from "helpers/declinations";
import { calcScalesPoints } from "helpers/scales";

export const ExaminationResultFormWithPoints = memo(({ form, data, step, age }) => {
    const { title, questions, getResult } = data;
    const [points, setPoints] = useState(null);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (form) {
            let calcPoints;

            if (step === "formGetUpAndGo") {
                calcPoints = Object.values(form).reduce((acc, item) => acc + Number(item), 0);
                // } else if (step === "formGHIS") {
                //     calcPoints = (calcScalesPoints(form) / 7).toFixed(2);
            } else {
                calcPoints = calcScalesPoints(form);
            }

            setPoints(calcPoints);
            setResult(getResult(calcPoints, { questionsCount: Object.keys(form).length }));
        }
    }, [form, step, age, getResult]);

    return (
        <div>
            <h3 className="recommendations-page__block-title" style={{ marginTop: 0, paddingLeft: 5 }}>
                {title}
            </h3>

            <div className="respondent-page__block block-wrapper">
                {Object.entries(questions).map(([key, value]) => {
                    const formData = form[key];

                    if (!formData) return null;

                    let answer;

                    if (Array.isArray(formData)) {
                        answer = formData.map(({ label }) => label).join(", ");
                    } else {
                        answer = typeof formData === "string" ? formData : formData.label ?? null;
                    }

                    if (!answer) return null;

                    return (
                        <div className="recommendations-page__list" key={key}>
                            <h4 className="recommendations-page__list-title">{value}</h4>

                            <div className="recommendations-page__list-answers">
                                <div className="recommendations-page__list-answers-item">
                                    <p>{answer}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            {points !== null && (
                <>
                    <h3 className="recommendations-page__block-title" style={{ paddingLeft: 5 }}>
                        Результаты диагностики
                    </h3>

                    <div className="recommendations-page__list block-wrapper">
                        <h4 className="recommendations-page__list-title">
                            {points} {declinations(points, "point")}
                        </h4>

                        {result && (
                            <div className="recommendations-page__list-answers">
                                <div className="recommendations-page__list-answers-item">
                                    <p>{result}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
});
