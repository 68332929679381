import { memo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { updateExamination } from "api/examinations";
import { showAlert } from "redux/appReducer";
import { classNames } from "helpers/app";
import { ButtonLoader } from "components/ButtonLoader";
import { requiredString } from "components/questionnaire/schemas";
import styles from "./ExaminationSetResult.module.scss";

const validationSchema = z.object({
    diagnosis: requiredString,
    complications: requiredString,
    recommendations: requiredString,
});

export const ExaminationEditResultForm = memo(({ id, result }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            diagnosis: result.diagnosis || "",
            complications: result.complications || "",
            recommendations: result.recommendations || "",
        },
    });
    const { mutateAsync: updateMutation } = useMutation({
        mutationFn: (data) =>
            updateExamination(id, data, {
                type: "edit-result",
            }),
    });
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = (data) => {
        setIsLoading(true);

        updateMutation({
            result: data,
        })
            .then(async () => {
                await Promise.all([queryClient.invalidateQueries(["examination", id])]);
                searchParams.delete("step");
                setSearchParams(searchParams);
            })
            .catch(() => {
                dispatch(showAlert({ type: "error", text: "Ошибка сохранения результата" }));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div>
            <h3 className={styles.title}>Изменение результатов опроса</h3>

            <form onSubmit={handleSubmit(onSubmit)} className={classNames(["questionnaire", styles.wrapper])}>
                <div className="common-page__card-block block-wrapper">
                    <h4
                        className={classNames("", {
                            [styles.errorField]: errors.diagnosis,
                        })}
                    >
                        Диагноз
                    </h4>

                    <Controller
                        name="diagnosis"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <TextareaAutosize
                                className="common-page__card-inner textarea-autosize"
                                value={value}
                                onChange={onChange}
                                placeholder="Введите текст диагноза"
                                minRows={5}
                            />
                        )}
                    />
                </div>

                <div className="common-page__card-block block-wrapper">
                    <h4
                        className={classNames("", {
                            [styles.errorField]: errors.complications,
                        })}
                    >
                        Осложнения
                    </h4>

                    <Controller
                        name="complications"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <TextareaAutosize
                                className="common-page__card-inner textarea-autosize"
                                value={value}
                                onChange={onChange}
                                placeholder="Введите текст осложнений"
                                minRows={5}
                            />
                        )}
                    />
                </div>

                <div className="common-page__card-block block-wrapper">
                    <h4
                        className={classNames("", {
                            [styles.errorField]: errors.recommendations,
                        })}
                    >
                        Рекомендации
                    </h4>

                    <Controller
                        name="recommendations"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <TextareaAutosize
                                className="common-page__card-inner textarea-autosize"
                                value={value}
                                onChange={onChange}
                                placeholder="Введите текст рекомендаций"
                                minRows={5}
                            />
                        )}
                    />
                </div>

                <hr />

                <button className="main-btn" disabled={isLoading}>
                    {isLoading ? <ButtonLoader /> : "Сохранить"}
                </button>
            </form>
        </div>
    );
});
