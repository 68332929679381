import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { FormInput, FormRadioGroup } from "components/forms";
import { showAlert } from "redux/appReducer";
import { saveRespondent } from "api/saveRespondent";
import { requiredString } from "components/questionnaire/schemas";
import { ButtonLoader } from "components/ButtonLoader";

const validationSchema = z.object({
    fullName: requiredString,
    age: requiredString,
    sex: requiredString,
});

export const CreateRespondent = () => {
    const { specialistId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [isLoading, setIsLoading] = useState(false);
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            fullName: "",
            age: "",
            sex: "",
        },
    });
    const saveMutation = useMutation({
        mutationFn: (data) => saveRespondent(data),
    });

    const onSaveRespondent = (data) => {
        setIsLoading(true);

        saveMutation
            .mutateAsync({
                specialistId,
                form: data,
            })
            .then(async (res) => {
                if (res?._id) {
                    await Promise.all([
                        queryClient.refetchQueries(["respondents"]),
                        queryClient.refetchQueries(["respondents/count"]),
                    ]);
                    setIsLoading(false);
                    navigate(`/respondent/${res._id}`);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                dispatch(showAlert({ type: "error", text: error.message }));
            });
    };

    return (
        <div className="page">
            <header className="page__header questionnaire__header" />
            <div className="questionnaire">
                <h3>Информация о пациенте</h3>
            </div>

            <form onSubmit={handleSubmit(onSaveRespondent)} className="questionnaire__form">
                <FormInput
                    control={control}
                    name="fullName"
                    label="ФИО пациента"
                    placeholder="Фамилия Имя Отчество"
                    error={errors.fullName}
                />

                <FormInput
                    control={control}
                    name="age"
                    label="Возраст пациента"
                    placeholder="Возраст"
                    type="number"
                    inputProps={{
                        min: 0,
                        max: 199,
                    }}
                    error={errors.age}
                />

                <FormRadioGroup
                    control={control}
                    name="sex"
                    label="Пол"
                    options={["мужской", "женский"]}
                    error={errors.sex}
                />

                <button className="main-btn top-margin" disabled={isLoading}>
                    {isLoading ? <ButtonLoader height={16} /> : "Сохранить"}
                </button>
            </form>
        </div>
    );
};
