import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useExaminationStore } from "store/useExaminationStore";
import { useSetCompletedForms } from "hooks/useSetCompletedForms";
import { getFormDataWithOtherFields } from "helpers/questionnaireForms";
import { Questionnaire4Form, QuestionnaireFormWrapper } from "components/questionnaire";
import { requiredString } from "../schemas";

const validationSchema = z.object({
    chestCageShape: requiredString,
    chestCageDeformation: requiredString,
    breathingRate: requiredString,
    SpO2: requiredString,
    shortnessOfBreath: requiredString,
    chestPalpation: requiredString,
    percussionSound: requiredString,
    auscultation: requiredString,
    rales: requiredString,
    pleuralFrictionSound: requiredString,
    heartArea: requiredString,
    apicalHeartImpulsePalpatedAt: requiredString,
    heartSounds: requiredString,
    heartRate: requiredString,
    heartMurmurs: requiredString,
    pulse: requiredString,
    pulseDeficit: requiredString,
    pulseRate: requiredString,
    BPLeftArm: requiredString,
    BPRightArm: requiredString,
    orthostaticTest: requiredString,
    chewingProblems: requiredString,
    swallowingProblems: requiredString,
    teeth: requiredString,
    yawning: requiredString,
    yawningOther: z.string(),
    tonsils: requiredString,
    tongue: requiredString,
    abdomen: requiredString,
    abdominalPalpation: requiredString,
    abdominalPalpationOther: z.string(),
    liver: requiredString,
    liverOnPalpation: requiredString,
    spleen: requiredString,
    pelvicFunctions: requiredString,
    stool: requiredString,
    lumbarInspection: requiredString,
    PasternatskySign: requiredString,
    urination: requiredString,
    eyeSclerae: requiredString,
    pupils: requiredString,
    visionAssessment: requiredString,
    hearingAssessment: requiredString,
    appearance: requiredString,
    personalHygiene: requiredString,
    orientation: requiredString,
    cooperationDuringExamination: requiredString,
    behavior: requiredString,
    psychomotorActivity: requiredString,
    speech: requiredString,
    memoryImpairment: requiredString,
    perceptualImpairment: requiredString,
    concentrationAttentionImpairment: requiredString,
});

export const Questionnaire4 = ({ form, handleSaveForm, isLoading }) => {
    const [startFillingTime] = useState(Date.now);
    const completedForms = useExaminationStore((state) => state.completedForms);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            chestCageShape: "",
            chestCageDeformation: "",
            breathingRate: "",
            SpO2: "",
            shortnessOfBreath: "",
            chestPalpation: "",
            percussionSound: "",
            auscultation: "",
            rales: "",
            pleuralFrictionSound: "",
            heartArea: "",
            apicalHeartImpulsePalpatedAt: "",
            heartSounds: "",
            heartRate: "",
            heartMurmurs: "",
            pulse: "",
            pulseDeficit: "",
            pulseRate: "",
            BPLeftArm: "",
            BPRightArm: "",
            orthostaticTest: "",
            chewingProblems: "",
            swallowingProblems: "",
            teeth: "",
            yawning: "",
            yawningOther: "",
            tonsils: "",
            tongue: "",
            abdomen: "",
            abdominalPalpation: "",
            abdominalPalpationOther: "",
            liver: "",
            liverOnPalpation: "",
            spleen: "",
            pelvicFunctions: "",
            stool: "",
            lumbarInspection: "",
            PasternatskySign: "",
            urination: "",
            eyeSclerae: "",
            pupils: "",
            visionAssessment: "",
            hearingAssessment: "",
            appearance: "",
            personalHygiene: "",
            orientation: "",
            cooperationDuringExamination: "",
            behavior: "",
            psychomotorActivity: "",
            speech: "",
            memoryImpairment: "",
            perceptualImpairment: "",
            concentrationAttentionImpairment: "",
        },
    });
    useSetCompletedForms({ form, completedForms, setValue });

    const onSubmit = (data) => {
        const getData = getFormDataWithOtherFields(data, ["yawning", "abdominalPalpation"]);
        handleSaveForm({ form, data: getData, fillingTime: Date.now() - startFillingTime });
    };

    return (
        <QuestionnaireFormWrapper handleSubmit={handleSubmit(onSubmit)} isLoading={isLoading} isShowResult={false}>
            <Questionnaire4Form control={control} errors={errors} key={completedForms[form]} />
        </QuestionnaireFormWrapper>
    );
};
