import { FormSelectMulti } from "components/forms";
import { questionsFormMorseScale as questions } from "components/questionnaire/questions";

const optionsData = {
    fallsLastYear: [
        { label: "Да", value: 25 },
        { label: "Нет", value: 0 },
    ],
    comorbidCondition: [
        { label: "Да", value: 15 },
        { label: "Нет", value: 0 },
    ],
    walkingIndependence: [
        { label: "Опирается о мебель и стены", value: 30 },
        { label: "Костыли/ходунки/трость", value: 15 },
        { label: "Ходит сам или с помощью/постельный режим", value: 0 },
    ],
    venousCatheter: [
        { label: "Да", value: 20 },
        { label: "Нет", value: 0 },
    ],
    gait: [
        { label: "Нарушенная (не может встать, ходит опираясь, смотрит вниз)", value: 20 },
        { label: "Неустойчивая (ходит с остановками, шаги короткие, иногда с задержкой)", value: 10 },
        { label: "Нормальная/постельный режим", value: 0 },
    ],
    mentalState: [
        { label: "Переоценивает свои способности, забывает об ограничениях", value: 15 },
        { label: "Знает свою способность двигаться", value: 0 },
    ],
};

export const QuestionnaireMorseScaleForm = ({ control, errors }) => {
    return (
        <>
            {Object.keys(optionsData).map((questionKey) => (
                <FormSelectMulti
                    key={questionKey}
                    control={control}
                    name={questionKey}
                    label={questions[questionKey]}
                    placeholder="Один вариант ответа"
                    error={errors[questionKey]}
                    options={optionsData[questionKey]}
                    maxSelectOptions={1}
                />
            ))}
        </>
    );
};
