import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useExaminationStore } from "store/useExaminationStore";
import { useSetCompletedForms } from "hooks/useSetCompletedForms";
import { QuestionnaireDynamometryForm, QuestionnaireFormWrapper } from "components/questionnaire";
import { requiredString } from "../schemas";

const validationSchema = z.object({
    rightHand: requiredString,
    leftHand: requiredString,
});

export const QuestionnaireDynamometry = ({ form, handleSaveForm, isLoading }) => {
    const [startFillingTime] = useState(Date.now);
    const completedForms = useExaminationStore((state) => state.completedForms);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            rightHand: "",
            leftHand: "",
        },
    });
    useSetCompletedForms({ form, completedForms, setValue });

    const onSubmit = (data) => {
        handleSaveForm({ form, data, fillingTime: Date.now() - startFillingTime });
    };

    return (
        <QuestionnaireFormWrapper handleSubmit={handleSubmit(onSubmit)} isLoading={isLoading} isShowResult={false}>
            <QuestionnaireDynamometryForm control={control} errors={errors} />
        </QuestionnaireFormWrapper>
    );
};
