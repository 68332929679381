import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useExaminationStore } from "store/useExaminationStore";
import { useSetCompletedForms } from "hooks/useSetCompletedForms";
import { Questionnaire2Form, QuestionnaireFormWrapper } from "components/questionnaire";
import { requiredString } from "../schemas";

const validationSchema = z.object({
    arterialHypertension: requiredString,
    IHD: requiredString,
    myocardialInfarction: z.union([z.literal("да"), z.literal("нет")]),
    CHF: requiredString,
    ONMK: requiredString,
    Diabetes: requiredString,
    Osteoarthritis: requiredString,
    rheumatoidArthritis: requiredString,
    bronchialAsthma: requiredString,
    COPD: requiredString,
    oncologicalDiseases: requiredString,
    anemia: requiredString,
    pepticUlcer: requiredString,
    urolithiasisDisease: requiredString,
    trophicUlcers: requiredString,
    atrialFibrillation: requiredString,
    otherHNIZ: z.string(),
});

export const Questionnaire2 = ({ form, handleSaveForm, isLoading }) => {
    const [startFillingTime] = useState(Date.now);
    const completedForms = useExaminationStore((state) => state.completedForms);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            arterialHypertension: "нет",
            IHD: "нет",
            myocardialInfarction: "нет",
            CHF: "нет",
            ONMK: "нет",
            Diabetes: "нет",
            Osteoarthritis: "нет",
            rheumatoidArthritis: "нет",
            bronchialAsthma: "нет",
            COPD: "нет",
            oncologicalDiseases: "нет",
            anemia: "нет",
            pepticUlcer: "нет",
            urolithiasisDisease: "нет",
            trophicUlcers: "нет",
            atrialFibrillation: "нет",
            otherHNIZ: "",
        },
    });
    useSetCompletedForms({ form, completedForms, setValue });

    const onSubmit = (data) => {
        handleSaveForm({ form, data, fillingTime: Date.now() - startFillingTime });
    };

    return (
        <QuestionnaireFormWrapper handleSubmit={handleSubmit(onSubmit)} isLoading={isLoading} isShowResult={false}>
            <Questionnaire2Form control={control} errors={errors} key={completedForms[form]} />
        </QuestionnaireFormWrapper>
    );
};
