import React, { memo } from "react";
import { Link } from "react-router-dom";

import { declinationOfYears } from "helpers/declinations";
import { respondentDate } from "helpers/dates";

export const RespondentsItem = memo(({ respondent }) => {
    const { _id, fullName, age, createDate, examinations } = respondent;

    return (
        <Link to={`/respondent/${_id}`} className="block-wrapper respondent-page__list-item respondent-page__list-btn">
            <p className="respondent-page__list-item-title">{fullName}</p>
            <p className="respondent-page__list-item-info">
                <span>
                    {age} {declinationOfYears(age)} • {respondentDate(createDate)}
                </span>
                <span>Осмотров: {examinations.length}</span>
            </p>
        </Link>
    );
});
