// Найти время переписать функцию
export const getFormDataWithOtherFields = (data, fields) => {
    const result = { ...data };

    for (const field of fields) {
        const otherField = `${field}Other`;

        if (Array.isArray(result[field])) {
            const index = result[field].findIndex(({ label }) => label === "другое");

            if (index !== -1) {
                const value = `другое: ${result[otherField]}`;
                result[field][index] = { value, label: value };
            }
        } else {
            if (result[field] === "другое") {
                result[field] = `другое: ${result[otherField]}`;
            } else if (result[field] === "есть") {
                result[field] = `есть: ${result[otherField]}`;
            } else {
                result[field] = result[otherField];
            }
        }

        delete result[otherField];
    }

    return result;
};
