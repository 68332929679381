import axios from "axios";

export const updateExamination = async (id, data, params) => {
    try {
        const response = await axios.patch(`examination/${id}`, data, {
            params,
        });

        return response.data;
    } catch (e) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: "Ошибка обновления осмотра",
        };
    }
};
