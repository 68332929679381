import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useExaminationStore } from "store/useExaminationStore";
import { useSetCompletedForms } from "hooks/useSetCompletedForms";
import { useSearchParamsDelete } from "hooks/useSearchParamsDelete";
import { calcGHISFilledScales } from "helpers/scales";
import { QuestionnaireFormWrapper, QuestionnaireGHISForm } from "components/questionnaire";
import { questionnaireForms } from "components/questionnaire/consts";
import { requiredMultiSelectNumber } from "../schemas";

const validationSchema = z.object({
    basicActivitiesDailyLiving: requiredMultiSelectNumber,
    instrumentalActivitiesDailyLiving: requiredMultiSelectNumber,
    miniMentalStateExamination: requiredMultiSelectNumber,
    comorbidityIndex: requiredMultiSelectNumber,
    miniNutritionalAssessment: requiredMultiSelectNumber,
    numberMedications: requiredMultiSelectNumber,
    socialStatusLivingConditions: requiredMultiSelectNumber,
});

export const QuestionnaireGHIS = ({ form, handleSaveForm, handleShowResult, isLoading }) => {
    const [startFillingTime] = useState(Date.now);
    const completedForms = useExaminationStore((state) => state.completedForms);
    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            basicActivitiesDailyLiving: [],
            instrumentalActivitiesDailyLiving: [],
            miniMentalStateExamination: [],
            comorbidityIndex: [],
            miniNutritionalAssessment: [],
            numberMedications: [],
            socialStatusLivingConditions: [],
        },
    });
    useSetCompletedForms({ form, completedForms, setValue });
    const [isCalculated, setIsCalculated] = useState(false);
    const deleteSearchParam = useSearchParamsDelete();

    useEffect(() => {
        const filledScales = calcGHISFilledScales(completedForms);

        if (filledScales) {
            setValue("basicActivitiesDailyLiving", [filledScales.basicActivitiesDailyLiving]);
            setValue("instrumentalActivitiesDailyLiving", [filledScales.instrumentalActivitiesDailyLiving]);
            setValue("miniMentalStateExamination", [filledScales.miniMentalStateExamination]);
            setValue("comorbidityIndex", [filledScales.comorbidityIndex]);
            setValue("miniNutritionalAssessment", [filledScales.miniNutritionalAssessment]);

            setIsCalculated(true);
        } else {
            deleteSearchParam("step");
        }
    }, [setValue, completedForms]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = (data) => {
        handleSaveForm({ form, data, fillingTime: Date.now() - startFillingTime });
    };

    const onShowResult = (data) => {
        handleShowResult({
            form,
            values: data,
            formData: questionnaireForms[form],
        });
    };

    return (
        isCalculated && (
            <QuestionnaireFormWrapper
                handleSubmit={handleSubmit(onSubmit)}
                handleShowResults={handleSubmit(onShowResult)}
                isLoading={isLoading}
            >
                <QuestionnaireGHISForm control={control} getValues={getValues} errors={errors} />
            </QuestionnaireFormWrapper>
        )
    );
};
