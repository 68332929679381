import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useExaminationStore } from "store/useExaminationStore";
import { useSetCompletedForms } from "hooks/useSetCompletedForms";
import { getFormDataWithOtherFields } from "helpers/questionnaireForms";
import { Questionnaire1Form, QuestionnaireFormWrapper } from "components/questionnaire";
import { requiredMultiSelectString, requiredString } from "../schemas";

const validationSchema = z.object({
    inspectionPlace: requiredString,
    withWhom: requiredString,
    consent: requiredString,
    badHabits: requiredMultiSelectString,
    badHabitsOther: z.string(),
    physicalActivity: requiredString,
    reasonLimitationPhysicalActivity: requiredMultiSelectString,
    reasonLimitationPhysicalActivityOther: z.string(),
    emergencyCallFrequency: requiredString,
    hospitalizationRate: requiredString,
    injuriesSurgeriesFractures: requiredString,
    usingAids: requiredMultiSelectString,
    usingAidsOther: z.string(),
    falls: requiredString,
    epidemiologicalHistory: requiredString,
    epidemicallyArea: requiredString,
    drugTherapy: requiredString,
    polypharmacy: requiredString,
    diseaseHistory: requiredString,
});

export const Questionnaire1 = ({ form, handleSaveForm, isLoading }) => {
    const [startFillingTime] = useState(Date.now);
    const completedForms = useExaminationStore((state) => state.completedForms);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            inspectionPlace: "",
            withWhom: "",
            consent: "получено",
            badHabits: [],
            badHabitsOther: "",
            physicalActivity: "",
            reasonLimitationPhysicalActivity: [],
            reasonLimitationPhysicalActivityOther: "",
            emergencyCallFrequency: "",
            hospitalizationRate: "",
            injuriesSurgeriesFractures: "",
            usingAids: [],
            usingAidsOther: "",
            falls: "",
            epidemiologicalHistory: "не отягощен",
            epidemicallyArea: "",
            drugTherapy: "",
            polypharmacy: "нет",
            diseaseHistory: "",
        },
    });
    useSetCompletedForms({ form, completedForms, setValue });

    const onSubmit = (data) => {
        const getData = getFormDataWithOtherFields(data, [
            "reasonLimitationPhysicalActivity",
            "usingAids",
            "badHabits",
        ]);

        handleSaveForm({ form, data: getData, fillingTime: Date.now() - startFillingTime });
    };

    return (
        <QuestionnaireFormWrapper handleSubmit={handleSubmit(onSubmit)} isLoading={isLoading} isShowResult={false}>
            <Questionnaire1Form control={control} errors={errors} key={completedForms[form]} />
        </QuestionnaireFormWrapper>
    );
};
