import axios from 'axios';

export const fetchSpecialists = async ({ params = {} }) => {
    try {
        const response = await axios.get('specialist', {
            params
        });

        return response.data;
    } catch (e) {
        const message = e.response?.data?.message || 'Ошибка загрузки данных';

        // eslint-disable-next-line no-throw-literal
        throw {
            message
        };
    }
};
