import { memo, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { updateExamination } from "api/examinations";
import { showAlert } from "redux/appReducer";
import { classNames } from "helpers/app";
import { ButtonLoader } from "components/ButtonLoader";
import styles from "./ExaminationSetResult.module.scss";

export const ExaminationSetResultForm = memo(
    ({ id, respondentId, filledForms, handleSetStep, control, handleSubmit, errors }) => {
        const dispatch = useDispatch();
        const queryClient = useQueryClient();
        const { mutateAsync: updateMutation } = useMutation({
            mutationFn: (data) =>
                updateExamination(id, data, {
                    type: "set-result",
                }),
        });
        const [isLoading, setIsLoading] = useState(false);

        const onSubmit = (data) => {
            setIsLoading(true);

            updateMutation({
                methods: filledForms,
                result: data,
            })
                .then(async () => {
                    await Promise.all([
                        queryClient.invalidateQueries(["examination", id]),
                        queryClient.refetchQueries(["respondent", respondentId]),
                    ]);
                })
                .catch(() => {
                    setIsLoading(false);
                    dispatch(showAlert({ type: "error", text: "Ошибка сохранения результата" }));
                });
        };

        return (
            <div>
                <h3 className={styles.title}>Завершения опроса</h3>

                <form onSubmit={handleSubmit(onSubmit)} className={classNames(["questionnaire", styles.wrapper])}>
                    <div className="common-page__card-block block-wrapper">
                        <h4
                            className={classNames("", {
                                [styles.errorField]: errors.diagnosis,
                            })}
                        >
                            Диагноз
                        </h4>

                        <Controller
                            name="diagnosis"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <TextareaAutosize
                                    className="common-page__card-inner textarea-autosize"
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Введите текст диагноза"
                                    minRows={5}
                                />
                            )}
                        />
                    </div>

                    <div className="common-page__card-block block-wrapper">
                        <h4
                            className={classNames("", {
                                [styles.errorField]: errors.complications,
                            })}
                        >
                            Осложнения
                        </h4>

                        <Controller
                            name="complications"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <TextareaAutosize
                                    className="common-page__card-inner textarea-autosize"
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Введите текст осложнений"
                                    minRows={5}
                                />
                            )}
                        />
                    </div>

                    <div className="common-page__card-block block-wrapper">
                        <h4
                            className={classNames("", {
                                [styles.errorField]: errors.recommendations,
                            })}
                        >
                            Рекомендации
                        </h4>

                        <Controller
                            name="recommendations"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <TextareaAutosize
                                    className="common-page__card-inner textarea-autosize"
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Введите текст рекомендаций"
                                    minRows={5}
                                />
                            )}
                        />
                    </div>

                    <button
                        onClick={() => handleSetStep("results")}
                        className="main-btn main-btn_green top-margin"
                        type="button"
                    >
                        Посмотреть результаты
                    </button>

                    <hr />

                    <button className="main-btn" disabled={isLoading}>
                        {isLoading ? <ButtonLoader /> : "Завершить"}
                    </button>
                </form>
            </div>
        );
    }
);
