import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useExaminationStore } from "store/useExaminationStore";
import { showAlert } from "redux/appReducer";
import { updateExamination } from "api/examinations";
import { useSearchParamsDelete } from "hooks/useSearchParamsDelete";
import {
    ExaminationChangeMethods,
    ExaminationMethodResultModal,
    ExaminationMethodsBlocks,
    ExaminationSetResult,
} from "components/examinations";
import { questionnairePageSteps } from "components/questionnaire/questionnairePageSteps";
import { questionnaireForms } from "components/questionnaire/consts";
import styles from "./ExaminationFilling.module.scss";

const ExaminationStepItem = ({ component: Component, title, ...rest }) => {
    return (
        <>
            <h3>{title}</h3>
            <Component {...rest} />
        </>
    );
};

export const ExaminationFilling = ({ id, respondentId, methods, questionnaire, age }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [searchParams, setSearchParams] = useSearchParams();
    const deleteSearchParam = useSearchParamsDelete();
    const step = searchParams.get("step");
    const [filledForms, setFilledForms] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showResultForm, setShowResultForm] = useState(null);
    const { setBMI, setCompletedForms, resetState } = useExaminationStore();
    const { mutateAsync: updateMutation } = useMutation({
        mutationFn: (data) =>
            updateExamination(id, data, {
                type: "set-form",
            }),
    });

    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    }, [step]);

    useEffect(() => {
        if (questionnaire) {
            setFilledForms(Object.keys(questionnaire));

            resetState();

            if (questionnaire.form3) {
                setBMI(questionnaire.form3?.imt);
            }
            setCompletedForms(questionnaire);
        } else {
            resetState();
        }
    }, [questionnaire, id]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSaveForm = ({ form, data, fillingTime }) => {
        setIsLoading(true);

        updateMutation({ form, value: data, fillingTime })
            .then(async () => {
                await queryClient.invalidateQueries(["examination", id]);
                await queryClient.refetchQueries(["respondent", respondentId]);

                setIsLoading(false);
                deleteSearchParam("step");
            })
            .catch(() => {
                setIsLoading(false);
                dispatch(showAlert({ type: "error", text: "Ошибка сохранения результата" }));
            });
    };

    const handleSetResult = useCallback(() => {
        setSearchParams({ step: "result" });
    }, [setSearchParams]);

    const handleShowResult = (data) => {
        setShowResultForm(data);
    };

    const handleHideResult = () => {
        setShowResultForm(null);
    };

    return (
        <>
            <div className={styles.root}>
                {!step && (
                    <ExaminationMethodsBlocks
                        methods={methods}
                        filledForms={filledForms}
                        handleSetResult={handleSetResult}
                    />
                )}

                {step === "change-methods" && (
                    <ExaminationChangeMethods id={id} methods={methods} filledForms={filledForms} />
                )}

                {step === "result" && (
                    <ExaminationSetResult id={id} respondentId={respondentId} filledForms={filledForms} />
                )}

                {!["", "result"].includes(step) && questionnairePageSteps.hasOwnProperty(step) && (
                    <ExaminationStepItem
                        component={questionnairePageSteps[step].component}
                        title={questionnaireForms[step].title}
                        form={step}
                        age={age}
                        handleSaveForm={handleSaveForm}
                        handleShowResult={handleShowResult}
                        isLoading={isLoading}
                    />
                )}
            </div>

            {showResultForm && <ExaminationMethodResultModal data={showResultForm} hideModal={handleHideResult} />}
        </>
    );
};
