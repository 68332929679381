import { useEffect } from "react";

export const useSetCompletedForms = ({ form, completedForms, setValue }) => {
    useEffect(() => {
        if (completedForms[form]) {
            Object.entries(completedForms[form]).forEach(([name, value]) => setValue(name, value));
        }
    }, [form, completedForms, setValue]);
};

const partOneFields = [
    "decreasedFoodIntakeLast3Months",
    "weightLossLast3Months",
    "patientMobility",
    "acuteIllnessLast3Months",
    "psychoneurologicalProblems",
    "patientBMI",
];

export const useSetCompletedNutritionalRatingScale = ({ form, completedForms, setValue, setValue2 }) => {
    useEffect(() => {
        if (completedForms[form]) {
            Object.entries(completedForms[form]).forEach(([name, value]) => {
                if (partOneFields.includes(name)) {
                    setValue(name, value);
                } else {
                    setValue2(name, value);
                }
            });
        }
    }, [form, completedForms, setValue, setValue2]);
};
