import React from "react";

import { questionsForm1 } from "components/questionnaire";
import { FormInput, FormRadioGroup, FormSelect, FormSelectMulti, FormTextArea } from "components/forms";

export const Questionnaire1Form = ({ control, errors }) => {
    return (
        <>
            <FormSelect
                control={control}
                name="inspectionPlace"
                label={questionsForm1.inspectionPlace}
                placeholder="в палате / ПКО / иное"
                error={errors.inspectionPlace}
                options={["в палате", "ПКО", "иное"]}
            />
            <FormInput
                control={control}
                name="withWhom"
                label={questionsForm1.withWhom}
                placeholder="ФИО, степень родства"
                error={errors.withWhom}
            />
            <FormRadioGroup
                control={control}
                name="consent"
                label={questionsForm1.consent}
                options={["получено", "не получено"]}
                defaultChecked="получено"
            />
            <FormSelectMulti
                control={control}
                name="badHabits"
                label={questionsForm1.badHabits}
                placeholder="алкоголь / курение / другое / отсутствуют"
                error={errors.badHabits}
                options={["алкоголь", "курение", "другое", "отсутствуют"].map((value) => ({ value, label: value }))}
                otherField="другое"
                otherFieldName="badHabitsOther"
            />
            <FormInput
                control={control}
                name="physicalActivity"
                label={questionsForm1.physicalActivity}
                placeholder="вид, частота, продолжительность"
                error={errors.physicalActivity}
            />
            <FormSelectMulti
                control={control}
                name="reasonLimitationPhysicalActivity"
                label={questionsForm1.reasonLimitationPhysicalActivity}
                placeholder="проблемы с памятью / артрит / одышка / боль в груди / ..."
                error={errors.reasonLimitationPhysicalActivity}
                options={[
                    "отсутствует",
                    "проблемы с памятью",
                    "артрит",
                    "одышка",
                    "боль в груди",
                    "травмы",
                    "переломы",
                    "последствия ОНМК",
                    "хроническая боль",
                    "потеря зрения",
                    "неустойчивость походки",
                    "другое",
                ].map((value) => ({ value, label: value }))}
                otherField="другое"
                otherFieldName="reasonLimitationPhysicalActivityOther"
            />
            <FormInput
                control={control}
                name="emergencyCallFrequency"
                label={questionsForm1.emergencyCallFrequency}
                placeholder="от 0 до 99"
                error={errors.emergencyCallFrequency}
                type="number"
                inputProps={{
                    min: 0,
                    max: 99,
                }}
            />
            <FormInput
                control={control}
                name="hospitalizationRate"
                label={questionsForm1.hospitalizationRate}
                placeholder="от 0 до 99"
                error={errors.hospitalizationRate}
                type="number"
                inputProps={{
                    min: 0,
                    max: 99,
                }}
            />
            <FormTextArea
                control={control}
                name="injuriesSurgeriesFractures"
                label={questionsForm1.injuriesSurgeriesFractures}
                placeholder="возраст, обстоятельства, причина, локализация"
                error={errors.injuriesSurgeriesFractures}
            />
            <FormSelectMulti
                control={control}
                name="usingAids"
                label={questionsForm1.usingAids}
                placeholder="очки / слуховой аппарат / съемные зубные протезы / трость / ..."
                error={errors.usingAids}
                options={[
                    "очки",
                    "слуховой аппарат",
                    "съемные зубные протезы",
                    "трость",
                    "костыли",
                    "ортопедическая обувь",
                    "ортопедический корсет",
                    "абсорбирующее белье",
                    "другое",
                ].map((value) => ({ value, label: value }))}
                otherField="другое"
                otherFieldName="usingAidsOther"
            />
            <FormTextArea
                control={control}
                name="falls"
                label={questionsForm1.falls}
                placeholder="количество, обстоятельства, причина, исход"
                error={errors.falls}
            />
            <FormRadioGroup
                control={control}
                name="epidemiologicalHistory"
                label={questionsForm1.epidemiologicalHistory}
                options={["отягощен", "не отягощен"]}
                defaultChecked="не отягощен"
            />
            <FormTextArea
                control={control}
                name="epidemicallyArea"
                label={questionsForm1.epidemicallyArea}
                placeholder="заполните поле"
                error={errors.epidemicallyArea}
            />
            <FormTextArea
                control={control}
                name="drugTherapy"
                label={questionsForm1.drugTherapy}
                placeholder="с указанием дозы, кратности приема, пропусков в приеме, соответствие STOPР/STARТ критериям"
                error={errors.drugTherapy}
            />
            <FormRadioGroup
                control={control}
                name="polypharmacy"
                label={questionsForm1.polypharmacy}
                options={["есть", "нет"]}
                defaultChecked="нет"
            />
            <FormTextArea
                control={control}
                name="diseaseHistory"
                label={questionsForm1.diseaseHistory}
                placeholder="заполните поле"
                error={errors.diseaseHistory}
                minRows={3}
            />
        </>
    );
};
