import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useExaminationStore } from "store/useExaminationStore";
import { useSetCompletedForms } from "hooks/useSetCompletedForms";
import { QuestionnaireAdditionalScalesForm, QuestionnaireFormWrapper } from "components/questionnaire";
import { questionnaireForms } from "components/questionnaire/consts";
import { requiredMultiSelectNumber } from "../schemas";

const validationSchema = z.object({
    anxiety: requiredMultiSelectNumber,
    sadness: requiredMultiSelectNumber,
    lackOfResponseToPositiveEvents: requiredMultiSelectNumber,
    irritability: requiredMultiSelectNumber,

    agitation: requiredMultiSelectNumber,
    sluggishness: requiredMultiSelectNumber,
    physicalComplaints: requiredMultiSelectNumber,
    lossOfInterest: requiredMultiSelectNumber,

    decreasedAppetite: requiredMultiSelectNumber,
    weightLoss: requiredMultiSelectNumber,
    weaknessFatigue: requiredMultiSelectNumber,

    dailyMoodSwings: requiredMultiSelectNumber,
    sleepIssues: requiredMultiSelectNumber,
    nighttimeAwakenings: requiredMultiSelectNumber,
    earlyMorningAwakenings: requiredMultiSelectNumber,

    suicidalThoughts: requiredMultiSelectNumber,
    lowSelfEsteem: requiredMultiSelectNumber,
    pessimism: requiredMultiSelectNumber,
    delusionsOfHarmIllnessOrPoverty: requiredMultiSelectNumber,
});

export const QuestionnaireAdditionalScales = ({ form, handleSaveForm, handleShowResult, isLoading }) => {
    const [startFillingTime] = useState(Date.now);
    const completedForms = useExaminationStore((state) => state.completedForms);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            anxiety: [],
            sadness: [],
            lackOfResponseToPositiveEvents: [],
            irritability: [],

            agitation: [],
            sluggishness: [],
            physicalComplaints: [],
            lossOfInterest: [],

            decreasedAppetite: [],
            weightLoss: [],
            weaknessFatigue: [],

            dailyMoodSwings: [],
            sleepIssues: [],
            nighttimeAwakenings: [],
            earlyMorningAwakenings: [],

            suicidalThoughts: [],
            lowSelfEsteem: [],
            pessimism: [],
            delusionsOfHarmIllnessOrPoverty: [],
        },
    });
    useSetCompletedForms({ form, completedForms, setValue });

    const onSubmit = (data) => {
        handleSaveForm({ form, data, fillingTime: Date.now() - startFillingTime });
    };

    const onShowResult = (data) => {
        handleShowResult({
            form,
            values: data,
            formData: questionnaireForms[form],
        });
    };

    return (
        <QuestionnaireFormWrapper
            handleSubmit={handleSubmit(onSubmit)}
            handleShowResults={handleSubmit(onShowResult)}
            isLoading={isLoading}
        >
            <QuestionnaireAdditionalScalesForm control={control} errors={errors} />
        </QuestionnaireFormWrapper>
    );
};
