import { memo } from "react";
import { useSearchParams } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

export const ExaminationSpecialistConclusions = memo(({ result }) => {
    const [_, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
    const { diagnosis, complications, recommendations } = result;

    return (
        <>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button onClick={() => setSearchParams({ step: "result-edit" })} className="secondary-btn">
                    Изменить результаты
                </button>
            </div>

            <div className="common-page__card-block block-wrapper">
                <h4>Диагноз</h4>

                <TextareaAutosize className="common-page__card-inner textarea-autosize" value={diagnosis} readOnly />
            </div>

            <div className="common-page__card-block block-wrapper">
                <h4>Осложнения</h4>

                <TextareaAutosize
                    className="common-page__card-inner textarea-autosize"
                    value={complications}
                    readOnly
                />
            </div>

            <div className="common-page__card-block block-wrapper">
                <h4>Рекомендации</h4>

                <TextareaAutosize
                    className="common-page__card-inner textarea-autosize"
                    value={recommendations}
                    readOnly
                />
            </div>
        </>
    );
});
