import React from "react";

import { questionsForm3 } from "components/questionnaire";
import { FormInput, FormSelect } from "components/forms";

export const Questionnaire3Form = ({ control, errors }) => {
    return (
        <>
            <FormInput
                control={control}
                name="height"
                placeholder="заполните поле"
                error={errors.height}
                label={questionsForm3.height}
                type="number"
            />
            <FormInput
                control={control}
                name="weight"
                placeholder="заполните поле"
                error={errors.weight}
                label={questionsForm3.weight}
                type="number"
            />
            <FormInput
                control={control}
                name="imt"
                placeholder="высчитывается автоматически после заполнения роста и веса"
                error={errors.imt}
                label={questionsForm3.imt}
                inputProps={{
                    readOnly: true,
                }}
            />
            <FormSelect
                control={control}
                name="consultationType"
                error={errors.consultationType}
                options={["нормостеник", "гиперстеник", "астеник"]}
                label={questionsForm3.consultationType}
            />
            <FormSelect
                control={control}
                name="generalState"
                error={errors.generalState}
                options={["удовлетворительное", "средней тяжести", "тяжелое", "крайне тяжелое"]}
                label={questionsForm3.generalState}
            />
            <FormSelect
                control={control}
                name="consciousness"
                error={errors.consciousness}
                options={["ясное", "оглушение", "помрачение", "сопор", "кома"]}
                label={questionsForm3.consciousness}
            />
            <FormSelect
                control={control}
                name="contact"
                error={errors.contact}
                options={["продуктивный", "непродуктивный", "невозможен"]}
                label={questionsForm3.contact}
            />
            <FormSelect
                control={control}
                name="eyeContact"
                error={errors.eyeContact}
                options={["поддерживает", "не поддерживает"]}
                label={questionsForm3.eyeContact}
            />
            <FormSelect
                control={control}
                name="communicationMethod"
                error={errors.communicationMethod}
                options={[
                    "устно",
                    "кивком головы",
                    "морганием глаз",
                    "жестом",
                    "пожатием руки",
                    "с помощью алфавита",
                    "с помощью лазерной указки",
                    "другое",
                ]}
                label={questionsForm3.communicationMethod}
            />
            <FormSelect
                control={control}
                name="speech"
                error={errors.speech}
                options={["внятная", "невнятная", "афазия"]}
                label={questionsForm3.speech}
            />
            <FormSelect
                control={control}
                name="movementActivity"
                error={errors.movementActivity}
                options={[
                    "сохранена",
                    "самостоятельно",
                    "с опорой костыли",
                    "ходунки",
                    "с посторонней помощью",
                    "инвалидное кресло",
                    "ограничена в пределах отделения",
                    "ограничена в пределах палаты",
                    "ограничена в пределах прикроватного пространства",
                    "ограничена в пределах кровати",
                    "лежит неподвижно",
                    "занимает вынужденное положение",
                ]}
                label={questionsForm3.movementActivity}
            />
            <FormSelect
                control={control}
                name="selfService"
                error={errors.selfService}
                options={[
                    "самостоятельно, в полном объеме",
                    "требуется незначительная помощь",
                    "требуется значительная помощь",
                    "тотальный уход",
                ]}
                label={questionsForm3.selfService}
            />
            <FormSelect
                control={control}
                name="skin"
                label={questionsForm3.skin}
                error={errors.skin}
                options={[
                    "чистая",
                    "рубцы",
                    "шрамы",
                    "следы инъекций",
                    "раны",
                    "кровоподтеки",
                    "пролежни",
                    "опрелости",
                ]}
            />
            <FormSelect
                control={control}
                name="trophicDisorders"
                label={questionsForm3.trophicDisorders}
                error={errors.trophicDisorders}
                options={["нет", "есть"]}
                otherField="есть"
                otherFieldName="trophicDisordersOther"
            />
            <FormSelect
                control={control}
                name="colorOfTheSkin"
                label={questionsForm3.colorOfTheSkin}
                error={errors.colorOfTheSkin}
                options={["физиологической окраски", "бледная", "желтушная", "серо-землистая", "цианоз"]}
            />
            <FormSelect
                control={control}
                name="subcutaneousFatLayer"
                label={questionsForm3.subcutaneousFatLayer}
                error={errors.subcutaneousFatLayer}
                options={["удовлетворительно", "недостаточно хорошо", "кахексия", "избыточно"]}
            />
            <FormSelect
                control={control}
                name="presenceOfPastosity"
                label={questionsForm3.presenceOfPastosity}
                error={errors.presenceOfPastosity}
                options={["отсутствуют", "лица", "туловища", "конечностей"]}
            />
            <FormSelect
                control={control}
                name="lymphNodes"
                label={questionsForm3.lymphNodes}
                error={errors.lymphNodes}
                options={["не увеличены", "увеличены"]}
            />
            <FormSelect
                control={control}
                name="uponPalpation"
                label={questionsForm3.uponPalpation}
                error={errors.uponPalpation}
                options={[
                    "болезненные",
                    "безболезненные",
                    "плотные",
                    "мягкие",
                    "не спаянны между собой",
                    "с подлежащей клетчаткой",
                    "с кожей",
                ]}
            />
            <FormSelect
                control={control}
                name="muscleSystem"
                label={questionsForm3.muscleSystem}
                error={errors.muscleSystem}
                options={["развита", "удовлетворительно", "гипотрофия"]}
            />
            <FormSelect
                control={control}
                name="osteoarticularSystem"
                label={questionsForm3.osteoarticularSystem}
                error={errors.osteoarticularSystem}
                options={[
                    "суставы не изменены",
                    "изменены",
                    "дефигурация",
                    "припухлость",
                    "ограничение движений",
                    "покраснение",
                    "повышение температуры в области сустава",
                ]}
            />
            <FormSelect
                control={control}
                name="orthostaticTest"
                label={questionsForm3.orthostaticTest}
                error={errors.orthostaticTest}
                options={["положительная", "отрицательная"]}
            />

            <h4 className="questionnaire__form-block-title">Оценка риска переломов по шкале Фракс:</h4>

            <FormInput
                control={control}
                name="riskMajorOsteoporoticFractures"
                label={questionsForm3.riskMajorOsteoporoticFractures}
                placeholder="от 0 до 100 %"
                error={errors.riskMajorOsteoporoticFractures}
                type="number"
                inputProps={{
                    min: 0,
                    max: 100,
                }}
            />
            <FormInput
                control={control}
                name="riskHipFracture"
                label={questionsForm3.riskHipFracture}
                placeholder="от 0 до 100 %"
                error={errors.riskHipFracture}
                type="number"
                inputProps={{
                    min: 0,
                    max: 100,
                }}
            />
        </>
    );
};
