import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useExaminationStore } from "store/useExaminationStore";
import { useSetCompletedForms } from "hooks/useSetCompletedForms";
import { QuestionnaireFormWrapper, QuestionnaireSleepAssessmentForm } from "components/questionnaire";
import { questionnaireForms } from "components/questionnaire/consts";
import { requiredMultiSelectNumber } from "../schemas";

const validationSchema = z.object({
    sleepOnsetProblems: requiredMultiSelectNumber,
    sleepInterruptions: requiredMultiSelectNumber,
    earlyAwakeningProblem: requiredMultiSelectNumber,
    satisfactionWithSleepProcess: requiredMultiSelectNumber,
    perceivedImpactOfSleepProblemsOnLifeQuality: requiredMultiSelectNumber,
    levelOfConcernAboutSleepProblems: requiredMultiSelectNumber,
    extentSleepProblemsInterfereWithDailyFunctioning: requiredMultiSelectNumber,
});

export const QuestionnaireSleepAssessment = ({ form, handleSaveForm, handleShowResult, isLoading }) => {
    const [startFillingTime] = useState(Date.now);
    const completedForms = useExaminationStore((state) => state.completedForms);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            sleepOnsetProblems: [],
            sleepInterruptions: [],
            earlyAwakeningProblem: [],
            satisfactionWithSleepProcess: [],
            perceivedImpactOfSleepProblemsOnLifeQuality: [],
            levelOfConcernAboutSleepProblems: [],
            extentSleepProblemsInterfereWithDailyFunctioning: [],
        },
    });
    useSetCompletedForms({ form, completedForms, setValue });

    const onSubmit = (data) => {
        handleSaveForm({ form, data, fillingTime: Date.now() - startFillingTime });
    };

    const onShowResult = (data) => {
        handleShowResult({
            form,
            values: data,
            formData: questionnaireForms[form],
        });
    };

    return (
        <QuestionnaireFormWrapper
            handleSubmit={handleSubmit(onSubmit)}
            handleShowResults={handleSubmit(onShowResult)}
            isLoading={isLoading}
        >
            <QuestionnaireSleepAssessmentForm control={control} errors={errors} />
        </QuestionnaireFormWrapper>
    );
};
