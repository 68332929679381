import { FormSelectMulti } from "components/forms";
import { questionsFormBarthelScale as questions } from "components/questionnaire/questions";

export const QuestionnaireBarthelScaleForm = ({ control, errors }) => {
    return (
        <>
            <FormSelectMulti
                control={control}
                name="eating"
                label={questions.eating}
                placeholder="Один вариант ответа"
                error={errors.eating}
                options={[
                    {
                        value: 10,
                        label: "не нуждается в помощи, способен самостоятельно пользоваться всеми столовыми приборами",
                    },
                    { value: 5, label: "частично нуждается в помощи (например, при разрезании пищи)" },
                    { value: 0, label: "полностью зависим от окружающих (необходимо кормление с посторонней помощью)" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="personalHygiene"
                label={questions.personalHygiene}
                placeholder="Один вариант ответа"
                error={errors.personalHygiene}
                options={[
                    {
                        value: 5,
                        label: "не нуждается в помощи",
                    },
                    { value: 0, label: "нуждается в помощи" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="dressing"
                label={questions.dressing}
                placeholder="Один вариант ответа"
                error={errors.dressing}
                options={[
                    {
                        value: 10,
                        label: "не нуждается в помощи",
                    },
                    {
                        value: 5,
                        label: "частично нуждается в помощи (например, при одевании обуви, застегивании пуговиц и т. д.)",
                    },
                    { value: 0, label: "полностью нуждается в помощи" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="bathing"
                label={questions.bathing}
                placeholder="Один вариант ответа"
                error={errors.bathing}
                options={[
                    {
                        value: 5,
                        label: "принимает ванну без посторонней помощи",
                    },
                    { value: 0, label: "нуждается в помощи" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="urinationControl"
                label={questions.urinationControl}
                placeholder="Один вариант ответа"
                error={errors.urinationControl}
                options={[
                    { value: 10, label: "контроль над мочеиспусканием" },
                    { value: 5, label: "случайные инциденты недержания мочи" },
                    { value: 0, label: "недержание мочи или катетеризация" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="bowelMovementControl"
                label={questions.bowelMovementControl}
                placeholder="Один вариант ответа"
                error={errors.bowelMovementControl}
                options={[
                    { value: 10, label: "контроль над дефекацией" },
                    { value: 5, label: "случайные инциденты недержания кала" },
                    { value: 0, label: "недержание кала или необходимость постановки клизм" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="toiletUse"
                label={questions.toiletUse}
                placeholder="Один вариант ответа"
                error={errors.toiletUse}
                options={[
                    { value: 10, label: "не нуждается в помощи" },
                    {
                        value: 5,
                        label: "частично нуждается в помощи (удержание равновесия, использование туалетной бумаги, снятие и надевание брюк и т. д.)",
                    },
                    { value: 0, label: "нуждается в использовании судна, утки" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="gettingOutOfBed"
                label={questions.gettingOutOfBed}
                placeholder="Один вариант ответа"
                error={errors.gettingOutOfBed}
                options={[
                    { value: 15, label: "не нуждается в помощи" },
                    { value: 10, label: "нуждается в наблюдении или минимальной поддержке" },
                    { value: 5, label: "может сесть в постели, но чтобы встать, нужна существенная поддержка" },
                    {
                        value: 0,
                        label: "не способен встать с постели даже с посторонней с помощью, не удерживает баланс в положении сидя",
                    },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="movementOnFlatSurface"
                label={questions.movementOnFlatSurface}
                placeholder="Один вариант ответа"
                error={errors.movementOnFlatSurface}
                options={[
                    { value: 15, label: "может без посторонней помощи передвигаться на расстояние более 50 м" },
                    { value: 10, label: "может передвигаться с посторонней помощью на расстояние более 50 м" },
                    { value: 5, label: "может передвигаться с помощью инвалидной коляски на расстояние более 50 м" },
                    { value: 0, label: "не способен к передвижению или передвигается на расстояние менее 50 м" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="stairsClimbing"
                label={questions.stairsClimbing}
                placeholder="Один вариант ответа"
                error={errors.stairsClimbing}
                options={[
                    { value: 10, label: "не нуждается в помощи" },
                    { value: 5, label: "нуждается в наблюдении или поддержке" },
                    { value: 0, label: "не способен подниматься по лестнице даже с поддержкой" },
                ]}
                maxSelectOptions={1}
            />
        </>
    );
};
