import { memo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import {
    ExaminationResultBaseForm,
    ExaminationResultFormWithPoints,
    ExaminationResultBlock,
    ExaminationEditResultForm,
} from "components/examinations";
import { questionnaireForms } from "components/questionnaire/consts";

import styles from "./ExaminationDone.module.scss";

const baseForms = ["form1", "form2", "form3", "form4", "formDeliriumScale", "formDynamometry"];
const formWithPoints = [
    "formMMSE",
    "formAgeNotBarrier",
    "formNutritionalRatingScale",
    "formClinicalDehydrationScale",
    "formBarthelScale",
    "formLawtonScale",
    "formPhysicalFunctioningTest",
    "formGetUpAndGo",
    "formPainIntensityRatings",
    "formGeriatricDepressionScale",
    "formSleepAssessment",
    "formNortonScale",
    "formMorseScale",
    "formCharlsonComorbidityIndex",
    "formGHIS",
    "formRASS",
    "formAdditionalScales",
    "formPainAssessmentDementia",
];

export const ExaminationDone = memo(({ id, result, questionnaire, methods, fullName, age, createdAt }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const step = searchParams.get("step");

    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });

        if (searchParams.get("step") === "result") {
            searchParams.delete("step");
            setSearchParams(searchParams);
        }
    }, [searchParams, setSearchParams]);

    return (
        <div className={styles.root}>
            {baseForms.includes(step) ? (
                <ExaminationResultBaseForm form={questionnaire[step]} data={questionnaireForms[step]} />
            ) : formWithPoints.includes(step) ? (
                <ExaminationResultFormWithPoints
                    form={questionnaire[step]}
                    data={questionnaireForms[step]}
                    step={step}
                    age={age}
                />
            ) : step === "result-edit" ? (
                <ExaminationEditResultForm id={id} result={result} />
            ) : (
                <ExaminationResultBlock
                    respondentFullName={fullName}
                    result={result}
                    methods={methods}
                    questionnaire={questionnaire}
                    createdAt={createdAt}
                />
            )}
        </div>
    );
});
