import { useState } from "react";

import { declinations } from "helpers/declinations";
import { FormRadioGroup } from "components/forms";
import { ButtonLoader } from "components/ButtonLoader";
import { questionsFormDeliriumScale as questions } from "components/questionnaire/questions";
import styles from "./QuestionnaireDeliriumScaleForm.module.scss";
import { classNames } from "helpers/app";

const RASSOptions = [
    { value: 4, label: "Воинственен, агрессивен, опасен для окружающих", attempt: true },
    { value: 3, label: "Агрессивен, пытается вырвать трубки, капельницу или катетер", attempt: true },
    { value: 2, label: "Частые бесцельные движения, сопротивление процедурам" },
    { value: 1, label: "Тревожен, неагрессивные движения" },
    { value: 0, label: "Спокое и внимателен" },
    { value: -1, label: "Невнимателен, сонлив, но реагирует на голос" },
    { value: -2, label: "Просыпается на короткое время на голос" },
    { value: -3, label: "Движение или открытие глаз на голос, но нет зрительного контакта" },
    {
        value: -4,
        label: "Никакой реакции на голос, но есть какие-либо движения на физическую стимуляцию",
    },
    { value: -5, label: "Никакой реакции на голос и физическую стимуляцию" },
];

export const QuestionnaireDeliriumScaleForm = ({ control, errors, handleSubmit, isLoading }) => {
    const [isShowRASS, setIsShowRASS] = useState(false);

    return (
        <>
            <div className="questionnaire__description">
                <ol className={styles.list}>
                    <li>
                        У пациента имеются изменения психического статуса относительно исходного уровня ИЛИ отмечаются
                        волнообразные изменения психического статуса за последние 24 часа
                        <span>
                            <b>Комментарий:</b> Если одно из утверждений верно,перейдите к следующему вопросу
                        </span>
                    </li>

                    <li>
                        У пациента не имеются изменения психического статуса относительно исходного уровня И не
                        отмечаются волнообразные изменения психического статуса за последние 24 часа
                        <span>
                            <b>Комментарий:</b> Если оба утверждения верны, делирия нет
                        </span>
                    </li>

                    <li>
                        Пациент сжимает руку медицинской сестры каждый раз, когда она говорит букву А, произнося
                        следующую последовательность букв "Л А М П А А Л Л А Д И Н А"
                        <span>
                            <b>Комментарий:</b> Если пациент допусутил до 2х ошибок при выполнении, нажмите кнопку
                            ДЕЛИРИЯ НЕТ. Если пациент допустил более 3х ошибок, перейдите к следующему вопросу
                        </span>
                    </li>

                    <li>
                        Уровень сознания пациента на текущий момент по шкале RASS отличен от 0
                        <span>
                            <b>Комментарий:</b> Если значение по шкале RASS отлично от 0, нажмите кнопку ДЕЛИРИЙ ЕСТЬ.
                            Если значение по шкале RASS равно 0, перейдите к следующему вопросу
                        </span>
                    </li>

                    <li>
                        Спросите пациента следующие вопросы и посчитайте ошибки:
                        <br />
                        1) Будет ли камень держаться на воде? (НЕТ);
                        <br />
                        2) Рыба живет в море? (ДА);
                        <br />
                        3) Один килограмм весит больше двух? (НЕТ);
                        <br />
                        4) Молотком можно забить гвоздь? (ДА)
                        <br />
                        Покажите пациенту два пальца. Он должен повторить за вами и показать такое же количество
                        пальцев. Пациент должен повторить действие другой рукой, медсестра не демонстрирует
                        <span>
                            <b>Комментарий:</b> Если пациент допустил более 2х ошибок, нажмите кнопку ДЕЛИРИЙ ЕСТЬ. Если
                            допущена 0-1 ошибка, нажмите кнопку ДЕЛИРИЯ НЕТ
                        </span>
                    </li>
                </ol>
            </div>

            <hr />

            {isShowRASS && (
                <div>
                    <h4 className={styles.rassTitle}>
                        Ричмондская шкала ажитации (The Richmond Agitation-Sedation Scale, RASS)
                    </h4>

                    <ul className={styles.rassList}>
                        {RASSOptions.map(({ value, label, attempt }) => (
                            <li key={label} className={classNames("", { [styles.attempt]: attempt })}>
                                {label} ({value} {declinations(value, "point")})
                            </li>
                        ))}
                    </ul>

                    <p className={styles.rassNote}>
                        Примечание: Ячейки ХАРАКТЕРИСТИК, выделенные красным - необходимо срочно сообщить врачу
                    </p>

                    <hr />
                </div>
            )}

            <form onSubmit={handleSubmit} className="questionnaire__form" style={{ marginTop: 0 }}>
                <FormRadioGroup
                    control={control}
                    name="delirium"
                    label={questions.delirium}
                    options={["есть", "нет"]}
                    error={errors.delirium}
                    defaultChecked=""
                />

                <button
                    onClick={() => setIsShowRASS(!isShowRASS)}
                    type="button"
                    className="main-btn main-btn_green top-margin"
                >
                    {isShowRASS ? "Скрыть" : "Показать"} шкалу RASS
                </button>

                <button className="main-btn top-margin" disabled={isLoading}>
                    {isLoading ? <ButtonLoader /> : "Продолжить опрос"}
                </button>
            </form>
        </>
    );
};
