import { FormRadioGroupWithValue } from "components/forms";
import { questionsFormCharlsonComorbidityIndex as questions } from "components/questionnaire/questions";

const options = [
    { value: 1, label: "да" },
    { value: 0, label: "нет" },
];

const questionsList = [
    "myocardialInfarction",
    "congestiveHeartFailure",
    "peripheralArteryDisease",
    "cerebrovascularDisease",
    "dementia",
    "chronicLungDisease",
    "connectiveTissueDisease",
    "pepticUlcerDisease",
    "mildLiverDisease",
    "diabetes",
    "hemiplegia",
    "moderateSevereKidneyDisease",
    "diabetesComplications",
    "metastaticCancer",
    "leukemia",
    "lymphomas",
    "moderateSevereLiverDisease",
    "metastaticMalignantTumor",
    "AIDS",
];

export const QuestionnaireCharlsonComorbidityIndexForm = ({ control, errors }) => {
    return questionsList.map((question) => (
        <FormRadioGroupWithValue
            control={control}
            name={question}
            label={questions[question]}
            options={options}
            error={errors[question]}
            key={question}
        />
    ));
};
