import React, { memo, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useExaminationStore } from "store/useExaminationStore";
import { classNames } from "helpers/app";
import { questionnaireButtons, questionnaireForms } from "components/questionnaire/consts";
import { ExaminationMethodsBlockItem } from "./ExaminationMethodsBlockItem";
import { ExaminationMethodResultModal } from "components/examinations";
import styles from "./ExaminationMethodsBlocks.module.scss";

const additionalScales = ["formAdditionalScales", "formPainAssessmentDementia"];

export const ExaminationMethodsBlocks = memo(({ methods, filledForms, handleSetResult }) => {
    const completedForms = useExaminationStore((state) => state.completedForms);
    const [basicMethods, setBasicMethods] = useState([]);
    const [additionalMethods, setAdditionalMethods] = useState([]);
    const [_, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
    const patientBMI = useExaminationStore((state) => state.patientBMI);
    const [showResultForm, setShowResultForm] = useState(null);

    useEffect(() => {
        const basicMethodsArr = [];
        const additionalMethodsArr = [];

        for (const item of questionnaireButtons) {
            if (!methods.includes(item.key)) continue;

            if (additionalScales.includes(item.key)) {
                additionalMethodsArr.push(item);
            } else {
                basicMethodsArr.push(item);
            }
        }

        setBasicMethods(basicMethodsArr);
        setAdditionalMethods(additionalMethodsArr);
    }, [methods]);

    const handleSetStep = (step) => {
        setSearchParams({ step });
    };

    const handleShowResult = (form) => {
        if (completedForms[form]) {
            setShowResultForm({
                form,
                values: completedForms[form],
                formData: questionnaireForms[form],
            });
        }
    };

    const handleHideResultForm = () => {
        setShowResultForm(null);
    };

    return (
        <>
            <div>
                <div className={styles.header}>
                    <h3 className={styles.title}>Методики осмотра</h3>
                    <button onClick={() => setSearchParams({ step: "change-methods" })} className="secondary-btn">
                        Изменить методики
                    </button>
                </div>

                <div className={classNames(["questionnaire", styles.wrapper])}>
                    {!!basicMethods.length && (
                        <div className={styles.grid}>
                            {basicMethods.map(({ key, title, isHighlighted }) => {
                                let isDisabled;
                                let isBlocked = false;

                                if (key === "formNutritionalRatingScale") {
                                    isDisabled = filledForms.includes(key) || !patientBMI;
                                    isBlocked = !patientBMI;
                                    // } else if (key === "formGHIS") {
                                    //     const isRequiredFormFilled = questionnaireForms.formGHIS.required.every((item) =>
                                    //         filledForms.includes(item)
                                    //     );
                                    //     isDisabled = filledForms.includes(key) || !isRequiredFormFilled;
                                    //     isBlocked = !isRequiredFormFilled;
                                } else {
                                    isDisabled = filledForms.includes(key);
                                }

                                return (
                                    <ExaminationMethodsBlockItem
                                        title={title}
                                        step={key}
                                        handleSetStep={handleSetStep}
                                        handleShowResult={handleShowResult}
                                        isSuccess={filledForms.includes(key)}
                                        isBlocked={isBlocked}
                                        isDisabled={isDisabled}
                                        isHighlighted={isHighlighted}
                                        key={key}
                                    />
                                );
                            })}
                        </div>
                    )}

                    {!!additionalMethods.length && (
                        <>
                            <h4 className={styles.additionalTitle}>Дополнительные шкалы</h4>

                            <div className={styles.grid}>
                                {additionalMethods.map(({ key, title }) => (
                                    <ExaminationMethodsBlockItem
                                        title={title}
                                        step={key}
                                        handleSetStep={handleSetStep}
                                        handleShowResult={handleShowResult}
                                        isSuccess={filledForms.includes(key)}
                                        isDisabled={filledForms.includes(key)}
                                        key={key}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </div>

                {(!!basicMethods.length || !!additionalMethods.length) && (
                    <button onClick={handleSetResult} className="main-btn top-margin" disabled={!filledForms.length}>
                        Завершение опроса
                    </button>
                )}
            </div>

            {showResultForm && <ExaminationMethodResultModal data={showResultForm} hideModal={handleHideResultForm} />}
        </>
    );
});
