import axios from "axios";

export const fetchRespondents = async (params, page = 1, limit = 50) => {
    try {
        const response = await axios.get("respondents", {
            params: {
                ...params,
                page,
                limit,
            },
        });

        return response.data;
    } catch (e) {
        const message = e.response?.data?.message || "Ошибка загрузки данных";

        // eslint-disable-next-line no-throw-literal
        throw {
            message,
        };
    }
};
