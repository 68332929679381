import { memo, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { ExaminationSetResultForm } from "./ExaminationSetResultForm";
import { ExaminationMethodsResults } from "./ExaminationMethodsResults";
import { requiredString } from "components/questionnaire/schemas";

const validationSchema = z.object({
    diagnosis: requiredString,
    complications: requiredString,
    recommendations: requiredString,
});

export const ExaminationSetResult = memo(({ id, respondentId, filledForms }) => {
    const [step, setStep] = useState("form");
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            diagnosis: "",
            complications: "",
            recommendations: "",
        },
    });

    useEffect(() => {
        if (!filledForms.length && searchParams.has("step")) {
            searchParams.delete("step");
            setSearchParams(searchParams);
        }
    }, [filledForms, searchParams, setSearchParams]);

    const handleSetStep = useCallback((step) => {
        setStep(step);
    }, []);

    return (
        <>
            {step === "form" && (
                <ExaminationSetResultForm
                    id={id}
                    respondentId={respondentId}
                    filledForms={filledForms}
                    handleSetStep={handleSetStep}
                    control={control}
                    handleSubmit={handleSubmit}
                    errors={errors}
                />
            )}

            {step === "results" && <ExaminationMethodsResults handleSetStep={handleSetStep} />}
        </>
    );
});
