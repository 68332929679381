import React, { useLayoutEffect, useState } from "react";
import { Document, Font, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import dayjs from "dayjs";

import { calcScalesPoints } from "helpers/scales";
import { declinations } from "helpers/declinations";
import { questionnaireForms, questionnaireMethodsList } from "components/questionnaire/consts";

// import minzdravLogo from "static/img/minzdrav-logo-pdf.png";
// import agencyLogo from "static/img/agency-logo-pdf.png";
// import testuLogo from "static/img/testu-logo-pdf.png";

import lightFont from "static/fonts/Gilroy/Gilroy-Light.ttf";
import normalFont from "static/fonts/Gilroy/Gilroy-Regular.ttf";
import normalItalicFont from "static/fonts/Gilroy/Gilroy-RegularItalic.ttf";
import mediumFont from "static/fonts/Gilroy/Gilroy-Medium.ttf";
import mediumFontItalic from "static/fonts/Gilroy/Gilroy-MediumItalic.ttf";
import semiBoldFont from "static/fonts/Gilroy/Gilroy-SemiBold.ttf";
import boldFont from "static/fonts/Gilroy/Gilroy-Bold.ttf";
import blackFont from "static/fonts/Gilroy/Gilroy-Black.ttf";

Font.register({
    family: "Gilroy",
    fonts: [
        { src: normalFont },
        { src: normalItalicFont, fontStyle: "italic" },
        { src: lightFont, fontWeight: 300 },
        { src: mediumFont, fontWeight: 500 },
        {
            src: semiBoldFont,
            fontWeight: 600,
        },
        { src: boldFont, fontWeight: 700 },
        { src: mediumFontItalic, fontWeight: 500, fontStyle: "italic" },
        { src: blackFont, fontWeight: 800 },
    ],
});

const styles = StyleSheet.create({
    page: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F4F7F9",
        fontFamily: "Gilroy",
        color: "#122443",
        padding: "18pt",
    },
    contentTitle: {
        // marginTop: "24pt",
        marginBottom: "9pt",
        marginLeft: "9pt",
        fontSize: "13.5pt",
        fontWeight: 500,
        lineHeight: 1.2,
    },
    block: {
        backgroundColor: "#FFF",
        padding: "12pt",
        borderRadius: "12pt",
        marginBottom: "12pt",
    },
    blockTitle: {
        fontSize: "12pt",
        fontWeight: 500,
        lineHeight: 1.2,
    },
    blockDesc: {
        fontSize: "10.5pt",
        fontWeight: 400,
        lineHeight: 1.3,
        marginTop: "3pt",
    },
    resultBlock: {
        backgroundColor: "#FFF",
        padding: "12pt",
        paddingTop: 0,
        borderRadius: "12pt",
        marginBottom: "12pt",
    },
    resultBlockTitle: {
        marginTop: "12px",
        marginLeft: "6pt",
        fontSize: "12pt",
        fontWeight: 500,
        lineHeight: 1.2,
    },
    resultItem: {
        marginTop: "4pt",
        padding: "9pt",
        fontSize: "10.5pt",
        fontWeight: 400,
        borderRadius: "5.25pt",
        backgroundColor: "#F4F7F9",
        letterSpacing: "0.22pt",
        lineHeight: 1.3,
    },
    resultItemPoints: {
        fontWeight: 500,
    },
    logosTitle: {
        fontSize: "10.5pt",
        textAlign: "center",
        fontWeight: 500,
        lineHeight: 1.2,
        letterSpacing: "0.22pt",
    },
    logos: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "12pt",
    },
    logoFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "6pt",
    },
    logoItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "12pt",
        paddingRight: "12pt",
        height: "21pt",
    },
    logoTitle: {
        fontSize: "8.5pt",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    pagination: {
        marginBottom: "12pt",
    },
    paginationText: {
        fontSize: "10pt",
        fontWeight: 500,
        lineHeight: 1.2,
        textAlign: "right",
    },
});

const MethodsResultsItem = ({ form, value }) => {
    const { title, getResult } = questionnaireForms[form];
    const [points, setPoints] = useState(null);
    const [result, setResult] = useState(null);

    useLayoutEffect(() => {
        if (form && value) {
            switch (form) {
                case "formDeliriumScale":
                    setResult(`Делирий: ${value.delirium}`);
                    break;
                case "formDynamometry":
                    setResult(`Правая рука: ${value.rightHand}, левая рука: ${value.leftHand}`);
                    break;
                case "formPainIntensityRatings":
                    setResult(value.degreeOfPainSyndrome);
                    break;
                case "formRASS":
                    setPoints(value.characteristic.value);
                    setResult(value.characteristic.label);
                    break;
                default:
                    let calcPoints;

                    if (form === "formGetUpAndGo") {
                        calcPoints = Object.values(value).reduce((acc, item) => acc + Number(item), 0);
                    } else if (form === "formGHIS") {
                        calcPoints = (calcScalesPoints(value) / 7).toFixed(2);
                    } else {
                        calcPoints = calcScalesPoints(value);
                    }

                    setPoints(calcPoints);
                    setResult(getResult(calcPoints, { questionsCount: Object.keys(value).length }));
            }
        }
    }, [form, value]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <View wrap={false}>
            <Text style={styles.resultBlockTitle}>{title}</Text>

            <View style={styles.resultItem} wrap={false}>
                {points !== null && (
                    <Text style={styles.resultItemPoints}>
                        {points} {declinations(points, "point")}
                    </Text>
                )}

                <Text>{result}</Text>
            </View>
        </View>
    );
};

const formsList = questionnaireMethodsList.filter((item) => !["form1", "form2", "form3", "form4"].includes(item));

const PdfGenerator = ({ respondentFullName, result, questionnaire, createdAt }) => {
    const { diagnosis, complications, recommendations } = result;
    const [isShowMethodsResults, setIsShowMethodsResults] = useState(false);

    useLayoutEffect(() => {
        if (questionnaire) {
            const checkIsShowMethodsResults = Object.keys(questionnaire).filter(
                (item) => !["form1", "form2", "form3", "form4"].includes(item)
            );

            if (checkIsShowMethodsResults.length > 0) {
                setIsShowMethodsResults(true);
            }
        }
    }, [questionnaire]);

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.pagination} fixed>
                    <Text
                        render={({ pageNumber, totalPages }) => `стр. ${pageNumber} из ${totalPages}`}
                        style={styles.paginationText}
                    />
                </View>

                {/* Логотипы */}

                {/*<View style={{ marginBottom: "18pt" }} wrap={false}>*/}
                {/*    <View style={{ ...styles.logos, marginTop: 0 }}>*/}
                {/*        <View>*/}
                {/*            <View style={styles.logoTitle}>*/}
                {/*                <Text>Организаторы</Text>*/}
                {/*            </View>*/}
                {/*            <View style={styles.logoFlex}>*/}
                {/*                <View style={styles.logoItem}>*/}
                {/*                    <Image src={minzdravLogo} style={{ width: "64.5pt", height: "18pt" }} />*/}
                {/*                </View>*/}
                {/*                <View style={styles.logoItem}>*/}
                {/*                    <Image src={agencyLogo} style={{ width: "51pt", height: "21pt" }} />*/}
                {/*                </View>*/}
                {/*            </View>*/}
                {/*        </View>*/}

                {/*        <View>*/}
                {/*            <View style={styles.logoTitle}>*/}
                {/*                <Text>Технологический партнер</Text>*/}
                {/*            </View>*/}
                {/*            <View style={styles.logoFlex}>*/}
                {/*                <View style={styles.logoItem}>*/}
                {/*                    <Image src={testuLogo} style={{ width: "54.75pt", height: "15pt" }} />*/}
                {/*                </View>*/}
                {/*            </View>*/}
                {/*        </View>*/}
                {/*    </View>*/}
                {/*</View>*/}

                {/* Результат осмотра пациента */}

                <Text style={styles.contentTitle}>
                    Результат осмотра пациента {respondentFullName} от {dayjs(createdAt).format("DD.MM.YYYY")}
                </Text>

                <View style={styles.block} wrap={false}>
                    <Text style={styles.blockTitle}>Диагноз</Text>
                    <Text style={styles.blockDesc}>{diagnosis}</Text>
                </View>

                <View style={styles.block} wrap={false}>
                    <Text style={styles.blockTitle}>Осложнения</Text>
                    <Text style={styles.blockDesc}>{complications}</Text>
                </View>

                <View style={styles.block} wrap={false}>
                    <Text style={styles.blockTitle}>Рекомендации</Text>
                    <Text style={styles.blockDesc}>{recommendations}</Text>
                </View>

                {/* Результаты методик */}

                {isShowMethodsResults && (
                    <>
                        <Text style={{ ...styles.contentTitle, marginTop: "10pt" }}>Результаты методик</Text>

                        <View style={styles.resultBlock}>
                            {formsList.map((key) => {
                                if (!questionnaire[key]) return null;

                                return <MethodsResultsItem form={key} value={questionnaire[key]} key={key} />;
                            })}
                        </View>
                    </>
                )}
            </Page>
        </Document>
    );
};

export default PdfGenerator;
