import { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createExamination } from "api/examinations";
import { showAlert } from "redux/appReducer";
// import { classNames } from "helpers/app";
import { RespondentExaminationsList } from "components/examinations";
// import crossIcon from "static/img/ic-cross.svg";
import styles from "./RespondentExaminations.module.scss";

export const RespondentExaminations = memo(({ respondentId, examinations }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    // const [searchField, setSearchField] = useState("");
    const [isNewImpossible, setIsNewImpossible] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync: createMutation } = useMutation({
        mutationFn: (respondentId) => createExamination(respondentId),
    });

    useEffect(() => {
        const checkIsNewImpossible = examinations.some(({ status }) => ["new", "inProgress"].includes(status));
        setIsNewImpossible(checkIsNewImpossible);
    }, [examinations]);

    const onCreateExamination = () => {
        setIsLoading(true);

        createMutation(respondentId)
            .then(async (res) => {
                if (res?._id) {
                    await queryClient.invalidateQueries(["respondent", respondentId]);
                    setIsLoading(false);
                    navigate(`/examination/${res._id}`);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                dispatch(showAlert({ type: "error", text: error.message }));
            });
    };

    return (
        <div className="respondent-page__result-content">
            <div className={styles.addButton}>
                <button onClick={onCreateExamination} className="blue-button" disabled={isNewImpossible || isLoading}>
                    {isLoading ? "Создание осмотра ..." : "Добавить осмотр"}
                </button>
            </div>

            {/* Строка поиска временно (?) отключена */}

            {/*<div className={classNames(["editing-accounts__regions-search", styles.searchBlock])}>*/}
            {/*    <input*/}
            {/*        value={searchField}*/}
            {/*        onChange={(e) => setSearchField(e.target.value)}*/}
            {/*        type="text"*/}
            {/*        placeholder="Поиск"*/}
            {/*    />*/}

            {/*    {searchField && (*/}
            {/*        <button onClick={() => setSearchField("")}>*/}
            {/*            <img src={crossIcon} alt="" />*/}
            {/*        </button>*/}
            {/*    )}*/}
            {/*</div>*/}

            <div className={styles.examinationsBlock}>
                {examinations.length ? (
                    <RespondentExaminationsList examinations={examinations} />
                ) : (
                    <p>Нет назначенных осмотров</p>
                )}
            </div>
        </div>
    );
});
