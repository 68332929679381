import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { requiredMultiSelectNumber, requiredSelectNumber } from "../schemas";
import { useSearchParamsDelete } from "hooks/useSearchParamsDelete";
import { useExaminationStore } from "store/useExaminationStore";
import { useSetCompletedNutritionalRatingScale } from "hooks/useSetCompletedForms";
import { checkIsPartTwo } from "./checkIsPartTwo";
import { QuestionnaireNutritionalRatingScaleForm1 } from "./QuestionnaireNutritionalRatingScaleForm1";
import { QuestionnaireNutritionalRatingScaleForm2 } from "./QuestionnaireNutritionalRatingScaleForm2";
import { QuestionnaireFormWrapper } from "components/questionnaire";
import { questionnaireForms } from "components/questionnaire/consts";

const validationSchema1 = z.object({
    decreasedFoodIntakeLast3Months: requiredMultiSelectNumber,
    weightLossLast3Months: requiredMultiSelectNumber,
    patientMobility: requiredMultiSelectNumber,
    acuteIllnessLast3Months: requiredMultiSelectNumber,
    psychoneurologicalProblems: requiredMultiSelectNumber,
    patientBMI: requiredMultiSelectNumber,
});

const validationSchema2 = z.object({
    independentLiving: requiredSelectNumber,
    medicationIntakePerDay: requiredSelectNumber,
    pressureSoresAndSkinUlcers: requiredSelectNumber,
    frequencyOfFullMealsPerDay: requiredSelectNumber,
    proteinIntakeMarkers: z.object({}),
    proteinIntakeMarkers_1: requiredSelectNumber,
    proteinIntakeMarkers_2: requiredSelectNumber,
    proteinIntakeMarkers_3: requiredSelectNumber,
    consumptionOfFruitsVegetablesPerDay: requiredSelectNumber,
    dailyFluidIntake: requiredSelectNumber,
    patientDietaryHabits: requiredMultiSelectNumber,
    selfAssessmentOfNutritionalStatus: requiredMultiSelectNumber,
    patientHealthStatusComparedToPeers: requiredMultiSelectNumber,
    midArmCircumference: requiredMultiSelectNumber,
    calfCircumference: requiredSelectNumber,
});

export const QuestionnaireNutritionalRatingScale = ({ form, handleSaveForm, handleShowResult, isLoading }) => {
    const [startFillingTime] = useState(Date.now);
    const completedForms = useExaminationStore((state) => state.completedForms);
    const patientBMI = useExaminationStore((state) => state.patientBMI);
    const {
        control: control1,
        handleSubmit: handleSubmit1,
        getValues,
        setValue,
        formState,
    } = useForm({
        resolver: zodResolver(validationSchema1),
        defaultValues: {
            decreasedFoodIntakeLast3Months: [],
            weightLossLast3Months: [],
            patientMobility: [],
            acuteIllnessLast3Months: [],
            psychoneurologicalProblems: [],
            patientBMI: [],
        },
    });
    const {
        control: control2,
        handleSubmit: handleSubmit2,
        formState: formState2,
        setValue: setValue2,
    } = useForm({
        resolver: zodResolver(validationSchema2),
        defaultValues: {
            independentLiving: {},
            medicationIntakePerDay: {},
            pressureSoresAndSkinUlcers: {},
            frequencyOfFullMealsPerDay: {},
            proteinIntakeMarkers: {},
            proteinIntakeMarkers_1: {},
            proteinIntakeMarkers_2: {},
            proteinIntakeMarkers_3: {},
            consumptionOfFruitsVegetablesPerDay: {},
            dailyFluidIntake: {},
            patientDietaryHabits: [],
            selfAssessmentOfNutritionalStatus: [],
            patientHealthStatusComparedToPeers: [],
            midArmCircumference: [],
            calfCircumference: {},
        },
    });
    useSetCompletedNutritionalRatingScale({ form, completedForms, setValue, setValue2 });
    const [isPartTwo, setIsPartTwo] = useState(false);
    const deleteSearchParam = useSearchParamsDelete();

    useEffect(() => {
        if (patientBMI) {
            const imt = Number(patientBMI);
            let patientBMIValue;

            if (imt < 19) {
                patientBMIValue = { value: 0, label: "Меньше 19кг/м2" };
            } else if (imt >= 19 && imt < 21) {
                patientBMIValue = { value: 1, label: "19-20 кг/м2" };
            } else if (imt >= 21 && imt < 23) {
                patientBMIValue = { value: 2, label: "21-22 кг/м2" };
            } else {
                patientBMIValue = { value: 3, label: "23 кг/м2 и выше" };
            }

            setValue("patientBMI", [patientBMIValue]);
        } else {
            deleteSearchParam("step");
        }
    }, [patientBMI, setValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit1 = (data) => {
        const part1Values = checkIsPartTwo(data);

        if (part1Values < 12) {
            setIsPartTwo(true);
        } else {
            handleSaveForm({ form, data, fillingTime: Date.now() - startFillingTime });
        }
    };

    const onShowResult1 = (data) => {
        const part1Values = checkIsPartTwo(data);

        if (part1Values < 12) {
            setIsPartTwo(true);
        } else {
            handleShowResult({
                form,
                values: data,
                formData: questionnaireForms[form],
            });
        }
    };

    const onSubmit2 = (data) => {
        const part1Values = getValues();
        const { proteinIntakeMarkers_1, proteinIntakeMarkers_2, proteinIntakeMarkers_3, ...part2Values } = data;

        switch (proteinIntakeMarkers_1.value + proteinIntakeMarkers_2.value + proteinIntakeMarkers_3.value) {
            case 3:
                part2Values.proteinIntakeMarkers = { value: 1 };
                break;
            case 2:
                part2Values.proteinIntakeMarkers = { value: 0.5 };
                break;
            default:
                part2Values.proteinIntakeMarkers = { value: 0 };
                break;
        }

        handleSaveForm({
            form,
            data: {
                ...part1Values,
                ...part2Values,
            },
            fillingTime: Date.now() - startFillingTime,
        });
    };

    const onShowResult2 = (data) => {
        const part1Values = getValues();
        const { proteinIntakeMarkers_1, proteinIntakeMarkers_2, proteinIntakeMarkers_3, ...part2Values } = data;

        switch (proteinIntakeMarkers_1.value + proteinIntakeMarkers_2.value + proteinIntakeMarkers_3.value) {
            case 3:
                part2Values.proteinIntakeMarkers = { value: 1 };
                break;
            case 2:
                part2Values.proteinIntakeMarkers = { value: 0.5 };
                break;
            default:
                part2Values.proteinIntakeMarkers = { value: 0 };
                break;
        }

        handleShowResult({
            form,
            values: {
                ...part1Values,
                ...part2Values,
            },
            formData: questionnaireForms[form],
        });
    };

    return (
        <>
            {!isPartTwo && (
                <QuestionnaireFormWrapper
                    handleSubmit={handleSubmit1(onSubmit1)}
                    handleShowResults={handleSubmit1(onShowResult1)}
                    isLoading={isLoading}
                >
                    <QuestionnaireNutritionalRatingScaleForm1 control={control1} errors={formState.errors} />
                </QuestionnaireFormWrapper>
            )}

            {isPartTwo && (
                <QuestionnaireFormWrapper
                    handleSubmit={handleSubmit2(onSubmit2)}
                    handleShowResults={handleSubmit2(onShowResult2)}
                    isLoading={isLoading}
                >
                    <QuestionnaireNutritionalRatingScaleForm2 control={control2} errors={formState2.errors} />
                </QuestionnaireFormWrapper>
            )}
        </>
    );
};
