import React from "react";

import { FormSelectMulti } from "components/forms";
import { questionsFormAdditionalScales as questions } from "components/questionnaire/questions";

const commonOptions = [
    { label: "Признаки отсутствуют", value: 0 },
    { label: "Эпизодичесские или незначительные проявления", value: 1 },
    { label: "Регулярные проявления", value: 2 },
];

const affectiveSymptomsOptions = {
    anxiety: commonOptions,
    sadness: commonOptions,
    lackOfResponseToPositiveEvents: commonOptions,
    irritability: commonOptions,
};

const behavioralDisordersOptions = {
    agitation: commonOptions,
    sluggishness: commonOptions,
    physicalComplaints: [...commonOptions, { label: "Проблемы с пищеварительным трактом", value: 0 }],
    lossOfInterest: [...commonOptions, { label: "Интерес к повседневным делам утрачен давно", value: 0 }],
};

const physicalSymptomsOptions = {
    decreasedAppetite: [{ label: "Приходится убеждать поесть", value: 2 }, ...commonOptions],
    weightLoss: [
        { label: "Потеря более 2х килограммов", value: 2 },
        { label: "Потеря до 2х килограмм", value: 1 },
        { label: "Вес не потерян", value: 0 },
    ],
    weaknessFatigue: commonOptions,
};

const cyclicFunctionsOptions = {
    dailyMoodSwings: commonOptions,
    sleepIssues: [
        { label: "Проблема отсутствует", value: 0 },
        { label: "Проблема возникает эпизодически", value: 1 },
        { label: "Проблема повторяется каждый вечер", value: 2 },
    ],
    nighttimeAwakenings: [
        { label: "Больной просыпался, чтобы сходить в туалет", value: 0 },
        { label: "Нерегулярные и незначительные проявления", value: 1 },
        { label: "Регулярное возникновение проблемы", value: 2 },
    ],
    earlyMorningAwakenings: [
        { label: "Признаки отсутствуют", value: 0 },
        { label: "Больной просыпался раньше времени, но потом снова засыпал", value: 1 },
        { label: "Ранний подъем из-за невозможности уснуть", value: 2 },
    ],
};

const IdeatorialViolationsOptions = {
    suicidalThoughts: [
        { label: "Нет проявлений", value: 0 },
        { label: "Пассивная склонность к суицидальным настроениям", value: 1 },
        { label: "Активные проявления суицидальных настроений", value: 2 },
    ],
    lowSelfEsteem: [
        { label: "Нет проявлений", value: 0 },
        { label: "Снижение самооценки и самокритика", value: 1 },
        { label: "Чувство провала, неудачи и ощущение собственной никчемности", value: 2 },
    ],
    pessimism: [
        { label: "Признаки отсутствуют", value: 0 },
        { label: "Пессимизм", value: 1 },
        { label: "Чувство безнадёжности и неспособность принимать поддержку со стороны", value: 2 },
    ],
    delusionsOfHarmIllnessOrPoverty: commonOptions,
};

export const QuestionnaireAdditionalScalesForm = ({ control, errors }) => {
    return (
        <>
            <h4 className="questionnaire__form-block-title">Аффективные симптомы</h4>
            <BlockFields control={control} errors={errors} options={affectiveSymptomsOptions} />

            <h4 className="questionnaire__form-block-title">Поведенческие расстройства</h4>
            <BlockFields control={control} errors={errors} options={behavioralDisordersOptions} />

            <h4 className="questionnaire__form-block-title">Физические симптомы</h4>
            <BlockFields control={control} errors={errors} options={physicalSymptomsOptions} />

            <h4 className="questionnaire__form-block-title">Циклические функции</h4>
            <BlockFields control={control} errors={errors} options={cyclicFunctionsOptions} />

            <h4 className="questionnaire__form-block-title">Идеаторные нарушения</h4>
            <BlockFields control={control} errors={errors} options={IdeatorialViolationsOptions} />
        </>
    );
};

function BlockFields({ control, errors, options }) {
    return (
        <>
            {Object.keys(options).map((questionKey) => (
                <FormSelectMulti
                    key={questionKey}
                    control={control}
                    name={questionKey}
                    label={questions[questionKey]}
                    placeholder="Один вариант ответа"
                    error={errors[questionKey]}
                    options={options[questionKey]}
                    maxSelectOptions={1}
                />
            ))}
        </>
    );
}
