import React from "react";

import { questionsFormPainIntensityRatings as questions } from "components/questionnaire/questions";
import { FormInput } from "components/forms";
import painIntensityRatings from "static/img/questionnaire/painIntensityRatings.png";

export const QuestionnairePainIntensityRatingsForm = ({ control, errors }) => {
    return (
        <>
            <FormInput
                control={control}
                name="degreeOfPainSyndrome"
                label={questions.degreeOfPainSyndrome}
                placeholder="от 0 до 10"
                error={errors.degreeOfPainSyndrome}
                type="number"
                inputProps={{
                    min: 0,
                    max: 10,
                }}
            />

            <div>
                <img src={painIntensityRatings} alt="" style={{ width: "100%", borderRadius: 10 }} />
            </div>
        </>
    );
};
