import { FormSelectMulti } from "components/forms";
import { questionsNutritionalRatingScale as questions } from "components/questionnaire/questions";

export const QuestionnaireNutritionalRatingScaleForm1 = ({ control, errors }) => {
    return (
        <>
            <h4 className="questionnaire__form-block-title">Скрининговая часть</h4>

            <FormSelectMulti
                control={control}
                name="decreasedFoodIntakeLast3Months"
                label={questions.decreasedFoodIntakeLast3Months}
                placeholder="Один вариант ответа"
                options={[
                    { value: 0, label: "Серьезное уменьшение количества съедаемой пищи" },
                    { value: 1, label: "Умеренное уменьшение" },
                    { value: 2, label: "Нет уменьшения количества съедаемой пищи" },
                ]}
                error={errors.decreasedFoodIntakeLast3Months}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="weightLossLast3Months"
                label={questions.weightLossLast3Months}
                placeholder="Один вариант ответа"
                options={[
                    { value: 0, label: "Более, чем на 3кг" },
                    { value: 1, label: "Не знаю" },
                    { value: 2, label: "От 1 до 3 кг" },
                    { value: 3, label: "Нет потери массы тела" },
                ]}
                error={errors.weightLossLast3Months}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="patientMobility"
                label={questions.patientMobility}
                placeholder="Один вариант ответа"
                options={[
                    { value: 0, label: "Прикован к кровати/стулу" },
                    { value: 1, label: "Способен вставать с кровати/стула, но не выходит из дома" },
                    { value: 2, label: "Выходит из дома" },
                ]}
                error={errors.patientMobility}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="acuteIllnessLast3Months"
                label={questions.acuteIllnessLast3Months}
                placeholder="Один вариант ответа"
                options={[
                    { value: 0, label: "Да" },
                    { value: 2, label: "Нет" },
                ]}
                error={errors.acuteIllnessLast3Months}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="psychoneurologicalProblems"
                label={questions.psychoneurologicalProblems}
                placeholder="Один вариант ответа"
                options={[
                    { value: 0, label: "Серьёзное нарушение памяти или депрессия" },
                    { value: 1, label: "Умеренное нарушение памяти" },
                    { value: 2, label: "Нет нейропсихологических проблем" },
                ]}
                error={errors.psychoneurologicalProblems}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="patientBMI"
                label={questions.patientBMI}
                placeholder="Один вариант ответа"
                options={[
                    { value: 0, label: "Меньше 19кг/м2" },
                    { value: 1, label: "19-20 кг/м2" },
                    { value: 2, label: "21-22 кг/м2" },
                    { value: 3, label: "23 кг/м2 и выше" },
                ]}
                error={errors.patientBMI}
                isDisabled
                isMulti={false}
            />
        </>
    );
};
