import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useExaminationStore } from "store/useExaminationStore";
import { useSetCompletedForms } from "hooks/useSetCompletedForms";
import { getCalculatedData } from "./getCalculatedData";
import { QuestionnaireCharlsonComorbidityIndexForm, QuestionnaireFormWrapper } from "components/questionnaire";
import { questionnaireForms } from "components/questionnaire/consts";
import { requiredSelectNumber } from "../schemas";

const validationSchema = z.object({
    myocardialInfarction: requiredSelectNumber,
    congestiveHeartFailure: requiredSelectNumber,
    peripheralArteryDisease: requiredSelectNumber,
    cerebrovascularDisease: requiredSelectNumber,
    dementia: requiredSelectNumber,
    chronicLungDisease: requiredSelectNumber,
    connectiveTissueDisease: requiredSelectNumber,
    pepticUlcerDisease: requiredSelectNumber,
    mildLiverDisease: requiredSelectNumber,
    diabetes: requiredSelectNumber,
    hemiplegia: requiredSelectNumber,
    moderateSevereKidneyDisease: requiredSelectNumber,
    diabetesComplications: requiredSelectNumber,
    metastaticCancer: requiredSelectNumber,
    leukemia: requiredSelectNumber,
    lymphomas: requiredSelectNumber,
    moderateSevereLiverDisease: requiredSelectNumber,
    metastaticMalignantTumor: requiredSelectNumber,
    AIDS: requiredSelectNumber,
});

export const QuestionnaireCharlsonComorbidityIndex = ({ form, age, handleSaveForm, handleShowResult, isLoading }) => {
    const [startFillingTime] = useState(Date.now);
    const completedForms = useExaminationStore((state) => state.completedForms);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            myocardialInfarction: {},
            congestiveHeartFailure: {},
            peripheralArteryDisease: {},
            cerebrovascularDisease: {},
            dementia: {},
            chronicLungDisease: {},
            connectiveTissueDisease: {},
            pepticUlcerDisease: {},
            mildLiverDisease: {},
            diabetes: {},
            hemiplegia: {},
            moderateSevereKidneyDisease: {},
            diabetesComplications: {},
            metastaticCancer: {},
            leukemia: {},
            lymphomas: {},
            moderateSevereLiverDisease: {},
            metastaticMalignantTumor: {},
            AIDS: {},
        },
    });
    useSetCompletedForms({ form, completedForms, setValue });

    const onSubmit = (data) => {
        const calcData = getCalculatedData(data, age);
        handleSaveForm({ form, data: calcData, fillingTime: Date.now() - startFillingTime });
    };

    const onShowResult = (data) => {
        const calcData = getCalculatedData(data, age);
        handleShowResult({
            form,
            values: calcData,
            formData: questionnaireForms[form],
        });
    };

    return (
        <QuestionnaireFormWrapper
            handleSubmit={handleSubmit(onSubmit)}
            handleShowResults={handleSubmit(onShowResult)}
            isLoading={isLoading}
        >
            <QuestionnaireCharlsonComorbidityIndexForm control={control} errors={errors} key={completedForms[form]} />
        </QuestionnaireFormWrapper>
    );
};
