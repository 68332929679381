import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useExaminationStore } from "store/useExaminationStore";
import { useSetCompletedForms } from "hooks/useSetCompletedForms";
import { QuestionnaireDeliriumScaleForm } from "components/questionnaire";
import { requiredString } from "../schemas";

const validationSchema = z.object({
    delirium: requiredString,
});

export const QuestionnaireDeliriumScale = ({ form, handleSaveForm, isLoading }) => {
    const [startFillingTime] = useState(Date.now);
    const completedForms = useExaminationStore((state) => state.completedForms);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            delirium: "",
        },
    });
    useSetCompletedForms({ form, completedForms, setValue });

    const onSubmit = (data) => {
        handleSaveForm({ form, data, fillingTime: Date.now() - startFillingTime });
    };

    return (
        <QuestionnaireDeliriumScaleForm
            control={control}
            errors={errors}
            handleSubmit={handleSubmit(onSubmit)}
            isLoading={isLoading}
        />
    );
};
