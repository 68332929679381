import { memo, useEffect, useState } from "react";

import { ExaminationSpecialistConclusions } from "./ExaminationSpecialistConclusions";
import { ExaminationResultButtons } from "./ExaminationResultButtons";
import { ExaminationMethodsResults } from "./ExaminationMethodsResults";
import { ExaminationResultDownloadPdf } from "./ExaminationResultDownloadPdf";

export const ExaminationResultBlock = memo(({ respondentFullName, result, methods, questionnaire, createdAt }) => {
    const [isShowMethodsResults, setIsShowMethodsResults] = useState(false);

    useEffect(() => {
        if (questionnaire) {
            const checkIsShowMethodsResults = Object.keys(questionnaire).filter(
                (item) => !["form1", "form2", "form3", "form4"].includes(item)
            );

            if (checkIsShowMethodsResults.length > 0) {
                setIsShowMethodsResults(true);
            }
        }
    }, [questionnaire]);

    return (
        <>
            <ExaminationResultDownloadPdf
                respondentFullName={respondentFullName}
                result={result}
                questionnaire={questionnaire}
                createdAt={createdAt}
            />
            <hr />
            <ExaminationSpecialistConclusions result={result} />

            {isShowMethodsResults && (
                <>
                    <hr />
                    <ExaminationMethodsResults questionnaire={questionnaire} />
                </>
            )}
            <hr />
            <ExaminationResultButtons questionnaireKeys={methods} />
        </>
    );
});
