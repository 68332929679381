import axios from "axios";

export const fetchRespondentById = async (id) => {
    try {
        const response = await axios.get(`respondents/${id}`);

        return response.data;
    } catch (e) {
        const message = e.response?.data?.message || "Ошибка загрузки данных";

        // eslint-disable-next-line no-throw-literal
        throw {
            message,
        };
    }
};
