import React, { memo } from "react";
import { useSearchParams } from "react-router-dom";

import { questionnaireForms } from "components/questionnaire/consts";
import infoImg from "static/img/ic-info-black.svg";
import btnRightImg from "static/img/btn-right.svg";
import styles from "./ExaminationResult.module.scss";

const BlockButton = ({ title, step, setStep }) => {
    return (
        <button
            className="respondent-page__button respondent-page__button-with-icons block-wrapper"
            onClick={() => setStep(step)}
        >
            <img src={infoImg} alt="" />
            <span>{title}</span>
            <img src={btnRightImg} alt="" />
        </button>
    );
};

export const ExaminationResultButtons = memo(({ questionnaireKeys }) => {
    const [_, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars

    const handleSetStep = (step) => {
        setSearchParams({ step });
    };

    return (
        <div>
            <h3 className={styles.blockTitle}>Пройденнные методики</h3>

            {Object.entries(questionnaireForms).map(([key, value]) => {
                if (!questionnaireKeys.includes(key)) return null;

                return <BlockButton key={key} title={value.title} step={key} setStep={handleSetStep} />;
            })}
        </div>
    );
});
