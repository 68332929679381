import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { classNames } from "helpers/app";

const defaultOptions = ["да", "нет"];

export const FormRadioGroupWithValue = ({ name, label, control, options, defaultChecked, error = null }) => {
    const [optionsState, setOptionsState] = useState([]);

    useEffect(() => {
        if (!options) {
            setOptionsState(defaultOptions.map((value) => ({ value, label: value })));
        } else {
            const isOptionsString = options.every((item) => typeof item === "string");

            if (!isOptionsString) {
                setOptionsState(options);
            } else {
                setOptionsState(options.map((value) => ({ value, label: value })));
            }
        }
    }, [options]);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
                <div
                    className={classNames("questionnaire__form-item", {
                        error,
                    })}
                >
                    {label && <p>{label}</p>}

                    <div className="questionnaire__form-item-radio questionnaire__input radio">
                        {optionsState.map((option) => (
                            <label
                                className={classNames("", {
                                    attempt: option.attempt,
                                })}
                                key={option.label}
                            >
                                <input
                                    type="radio"
                                    value={option.value}
                                    {...field}
                                    defaultChecked={
                                        value?.label === option.label
                                        // defaultChecked &&
                                        // defaultChecked.toLowerCase() === option.label.toLowerCase()
                                    }
                                    onChange={() => onChange(option)}
                                />
                                {option.label}
                            </label>
                        ))}
                    </div>
                    {error && <span>Обязательное поле</span>}
                </div>
            )}
        />
    );
};
