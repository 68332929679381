import React from "react";

import { questionsForm2 } from "components/questionnaire";
import { FormRadioGroup, FormTextArea } from "components/forms";

const tempStyle = { display: "grid", gridTemplateColumns: "1fr 1fr", gap: 16 };

export const Questionnaire2Form = ({ control, errors }) => {
    return (
        <>
            <div style={tempStyle}>
                <FormRadioGroup
                    control={control}
                    name="arterialHypertension"
                    label={questionsForm2.arterialHypertension}
                />
                <FormRadioGroup control={control} name="bronchialAsthma" label={questionsForm2.bronchialAsthma} />
            </div>
            <div style={tempStyle}>
                <FormRadioGroup control={control} name="IHD" label={questionsForm2.IHD} />
                <FormRadioGroup control={control} name="COPD" label={questionsForm2.COPD} />
            </div>
            <div style={tempStyle}>
                <FormRadioGroup
                    control={control}
                    name="myocardialInfarction"
                    label={questionsForm2.myocardialInfarction}
                />
                <FormRadioGroup
                    control={control}
                    name="oncologicalDiseases"
                    label={questionsForm2.oncologicalDiseases}
                />
            </div>
            <div style={tempStyle}>
                <FormRadioGroup control={control} name="CHF" label={questionsForm2.CHF} />
                <FormRadioGroup control={control} name="anemia" label={questionsForm2.anemia} />
            </div>
            <div style={tempStyle}>
                <FormRadioGroup control={control} name="ONMK" label={questionsForm2.ONMK} />
                <FormRadioGroup control={control} name="pepticUlcer" label={questionsForm2.pepticUlcer} />
            </div>
            <div style={tempStyle}>
                <FormRadioGroup control={control} name="Diabetes" label={questionsForm2.Diabetes} />
                <FormRadioGroup
                    control={control}
                    name="urolithiasisDisease"
                    label={questionsForm2.urolithiasisDisease}
                />
            </div>
            <div style={tempStyle}>
                <FormRadioGroup control={control} name="Osteoarthritis" label={questionsForm2.Osteoarthritis} />
                <FormRadioGroup control={control} name="trophicUlcers" label={questionsForm2.trophicUlcers} />
            </div>
            <div style={tempStyle}>
                <FormRadioGroup
                    control={control}
                    name="rheumatoidArthritis"
                    label={questionsForm2.rheumatoidArthritis}
                />
                <FormRadioGroup control={control} name="atrialFibrillation" label={questionsForm2.atrialFibrillation} />
            </div>

            <FormTextArea
                control={control}
                name="otherHNIZ"
                label={questionsForm2.otherHNIZ}
                placeholder="не обязательное поле"
                error={errors.otherHNIZ}
            />
        </>
    );
};
