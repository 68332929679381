import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import { classNames } from "helpers/app";
import { ReactComponent as CloseIcon } from "static/img/ic-close.svg";
import styles from "./Modal.module.scss";

export const Modal = ({ children, closeModal }) => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(true);
    }, []);

    const handleClose = () => {
        setIsActive(false);
        setTimeout(() => {
            closeModal();
        }, 350);
    };

    return createPortal(
        <div
            className={classNames(styles.wrapper, {
                [styles.active]: isActive,
            })}
        >
            <div
                className={classNames(styles.inner, {
                    [styles.active]: isActive,
                })}
            >
                <button className={styles.closeBtn} onClick={handleClose}>
                    <CloseIcon />
                </button>

                {children}
            </div>
        </div>,
        document.getElementById("root")
    );
};
