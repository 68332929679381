import React from "react";

import { classNames } from "helpers/app";
import { formsWithoutResult } from "components/questionnaire/consts";
import { ReactComponent as SuccessIcon } from "static/img/ic-success.svg";
import { ReactComponent as EyeIcon } from "static/img/eye.svg";
import { ReactComponent as EditIcon } from "static/img/edit.svg";
import styles from "./ExaminationMethodsBlocks.module.scss";

export const ExaminationMethodsBlockItem = ({
    title,
    step,
    isSuccess,
    isDisabled,
    isBlocked,
    isEditable = true,
    isHighlighted,
    handleSetStep,
    handleShowResult,
}) => {
    return (
        <div
            onClick={() => {
                if (!isDisabled) {
                    handleSetStep(step);
                }
            }}
            className={classNames(["block-wrapper", styles.item], {
                [styles.itemDone]: isSuccess,
                [styles.itemBlocked]: isBlocked,
                [styles.highlighted]: isHighlighted,
            })}
        >
            {isSuccess && <SuccessIcon />}

            <span>{title}</span>

            <div className={styles.itemButtons}>
                {isSuccess && (
                    <>
                        {!formsWithoutResult.includes(step) && (
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleShowResult(step);
                                }}
                            >
                                <EyeIcon style={{ opacity: 0.8 }} />
                            </button>
                        )}

                        {isEditable && (
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleSetStep(step);
                                }}
                            >
                                <EditIcon style={{ opacity: 0.4, width: 18, height: 18 }} />
                            </button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
