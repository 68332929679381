import React from "react";

import { questionsFormGetUpAndGo as questions } from "components/questionnaire/questions";
import { FormInput } from "components/forms";

export const QuestionnaireGetUpAndGoForm = ({ control, errors }) => {
    return (
        <FormInput
            control={control}
            name="individualTesting"
            label={questions.individualTesting}
            error={errors.individualTesting}
            placeholder="заполните поле"
            type="number"
        />
    );
};
