import React from "react";

import { questionsForm4 as questions } from "components/questionnaire/questions";
import { FormInput, FormRadioGroup, FormSelect, FormTextArea } from "components/forms";

export const Questionnaire4Form = ({ control, errors }) => {
    return (
        <>
            <h4 className="questionnaire__form-block-title">СИСТЕМА ОРГАНОВ ДЫХАНИЯ:</h4>

            <FormSelect
                control={control}
                name="chestCageShape"
                label={questions.chestCageShape}
                error={errors.chestCageShape}
                options={[
                    "нормальная",
                    "бочкообразная",
                    "рахитическая (куриная грудь)",
                    "параметрическая (плоская грудь)",
                ]}
            />
            <FormSelect
                control={control}
                name="chestCageDeformation"
                label={questions.chestCageDeformation}
                error={errors.chestCageDeformation}
                options={[
                    "искривление позвоночника (кифоз / сколиоз)",
                    "уплощение одной стороны",
                    "выпячивание одной стороны грудной клетки",
                    "местное выпячивание грудной клетки",
                    "нет",
                ]}
            />
            <FormInput
                control={control}
                name="breathingRate"
                label={questions.breathingRate}
                error={errors.breathingRate}
                placeholder="заполните поле"
            />
            <FormInput
                control={control}
                name="SpO2"
                label={questions.SpO2}
                error={errors.SpO2}
                placeholder="заполните поле"
            />
            <FormRadioGroup
                control={control}
                name="shortnessOfBreath"
                label={questions.shortnessOfBreath}
                error={errors.shortnessOfBreath}
                options={["есть", "нет"]}
                defaultChecked={""}
            />
            <FormRadioGroup
                control={control}
                name="chestPalpation"
                label={questions.chestPalpation}
                error={errors.chestPalpation}
                options={["безболезнена", "болезнена"]}
            />
            <FormSelect
                control={control}
                name="percussionSound"
                label={questions.percussionSound}
                error={errors.percussionSound}
                options={["чистый", "легочный", "притуплен", "укорочен", "коробочный", "тимпанический"]}
            />
            <FormSelect
                control={control}
                name="auscultation"
                label={questions.auscultation}
                error={errors.auscultation}
                options={["везикулярное", "ослабленное везикулярное", "жесткое везикулярное"]}
            />
            <FormSelect
                control={control}
                name="rales"
                label={questions.rales}
                error={errors.rales}
                options={[
                    "сухие",
                    "рассеянные",
                    "единичные",
                    "множественные",
                    "свистящие",
                    "ужжащие",
                    "влажные",
                    "крепитирующие",
                    "мелкопузырчатые",
                    "среднепузырчатые",
                    "крупнопузырчатые",
                    "нет",
                ]}
            />
            <FormRadioGroup
                control={control}
                name="pleuralFrictionSound"
                label={questions.pleuralFrictionSound}
                error={errors.pleuralFrictionSound}
                options={["есть", "нет"]}
                defaultChecked={""}
            />

            <h4 className="questionnaire__form-block-title">СИСТЕМА ОРГАНОВ КРОВООБРАЩЕНИЯ:</h4>

            <FormRadioGroup
                control={control}
                name="heartArea"
                label={questions.heartArea}
                error={errors.heartArea}
                options={["не изменена", "изменена"]}
            />
            <FormInput
                control={control}
                name="apicalHeartImpulsePalpatedAt"
                label={questions.apicalHeartImpulsePalpatedAt}
                error={errors.apicalHeartImpulsePalpatedAt}
                placeholder="заполните поле"
            />
            <FormRadioGroup
                control={control}
                name="heartSounds"
                label={questions.heartSounds}
                error={errors.heartSounds}
                options={["ритмичные", "аритмичные"]}
            />
            <FormInput
                control={control}
                name="heartRate"
                label={questions.heartRate}
                error={errors.heartRate}
                placeholder="ударов в минуту"
            />
            <FormRadioGroup
                control={control}
                name="heartMurmurs"
                label={questions.heartMurmurs}
                error={errors.heartMurmurs}
                options={["систолический", "диастолический", "нет"]}
                defaultChecked={""}
            />
            <FormSelect
                control={control}
                name="pulse"
                label={questions.pulse}
                error={errors.pulse}
                options={[
                    "ритмичный",
                    "аритмичный",
                    "твердый",
                    "мягкий",
                    "напряженный",
                    "полный",
                    "пустой",
                    "нитевидный",
                    "скачущий",
                ]}
            />
            <FormRadioGroup
                control={control}
                name="pulseDeficit"
                label={questions.pulseDeficit}
                error={errors.pulseDeficit}
                options={["есть", "нет"]}
                defaultChecked={""}
            />
            <FormInput
                control={control}
                name="pulseRate"
                label={questions.pulseRate}
                error={errors.pulseRate}
                placeholder="в минуту"
            />
            <FormInput
                control={control}
                name="BPLeftArm"
                label={questions.BPLeftArm}
                error={errors.BPLeftArm}
                placeholder="заполните поле"
            />
            <FormInput
                control={control}
                name="BPRightArm"
                label={questions.BPRightArm}
                error={errors.BPRightArm}
                placeholder="заполните поле"
            />
            <FormRadioGroup
                control={control}
                name="orthostaticTest"
                label={questions.orthostaticTest}
                error={errors.orthostaticTest}
                options={["положительная", "отрицательная"]}
            />

            <h4 className="questionnaire__form-block-title">СИСТЕМА ОРГАНОВ ПИЩЕВАРЕНИЯ:</h4>

            <FormRadioGroup
                control={control}
                name="chewingProblems"
                label={questions.chewingProblems}
                error={errors.chewingProblems}
                options={["есть", "нет"]}
                defaultChecked={""}
            />
            <FormRadioGroup
                control={control}
                name="swallowingProblems"
                label={questions.swallowingProblems}
                error={errors.swallowingProblems}
                options={["есть", "нет"]}
                defaultChecked={""}
            />
            <FormSelect
                control={control}
                name="teeth"
                label={questions.teeth}
                error={errors.teeth}
                options={["санированы", "нуждается в санации", "адентия"]}
            />
            <FormSelect
                control={control}
                name="yawning"
                label={questions.yawning}
                error={errors.yawning}
                options={["обычной окраски", "гиперемирован", "другое"]}
                otherField="другое"
                otherFieldName="yawningOther"
            />
            <FormSelect
                control={control}
                name="tonsils"
                label={questions.tonsils}
                error={errors.tonsils}
                options={["увеличены", "не увеличены", "атрофированы", "удалены"]}
            />
            <FormSelect
                control={control}
                name="tongue"
                label={questions.tongue}
                error={errors.tongue}
                options={[
                    "влажный",
                    "сухой",
                    "чистый",
                    "обложен налетом (белым, желтым, серым, грязным)",
                    "географический",
                ]}
            />
            <FormSelect
                control={control}
                name="abdomen"
                label={questions.abdomen}
                error={errors.abdomen}
                options={[
                    "обычной формы и величины",
                    "увеличен в размерах",
                    "п/к жировая клетчатка развита достаточно",
                    "п/к жировая клетчатка развита избыточно",
                    "п/к жировая клетчатка развита чрезмерно",
                    "наличие отеков",
                    "отсутствие отеков",
                    "наличие грыжевые выпячивания",
                    "отсутствие грыжевых выпячиваний",
                ]}
            />
            <FormSelect
                control={control}
                name="abdominalPalpation"
                label={questions.abdominalPalpation}
                error={errors.abdominalPalpation}
                options={["мягкий", "напряженный", "безболезненный", "болезненность при пальпации в области ..."]}
                otherField="болезненность при пальпации в области ..."
                otherFieldName="abdominalPalpationOther"
            />
            <FormSelect
                control={control}
                name="liver"
                label={questions.liver}
                error={errors.liver}
                options={[
                    "из-под края реберной дуги не выступает",
                    "из-под края реберной дуги выступает",
                    "границы печени при перкуссии (или по Курлову) в пределах нормы",
                    "границы печени при перкуссии (или по Курлову) увеличены",
                ]}
            />
            <FormSelect
                control={control}
                name="liverOnPalpation"
                label={questions.liverOnPalpation}
                error={errors.liverOnPalpation}
                options={[
                    "край острый",
                    "край тупой",
                    "болезненность",
                    "безболезненная",
                    "консистенция мягко-эластичная",
                    "консистенция плотная",
                    "консистенция каменисто-плотная",
                    "поверхность гладкая",
                    "поверхность бугристая",
                ]}
            />
            <FormSelect
                control={control}
                name="spleen"
                label={questions.spleen}
                error={errors.spleen}
                options={[
                    "пальпируется",
                    "не пальпируется",
                    "не увеличена",
                    "увеличена",
                    "безболезненная",
                    "болезненная",
                    "границы селезенки в пределах нормы",
                    "границы селезенки увеличены",
                ]}
            />
            <FormRadioGroup
                control={control}
                name="pelvicFunctions"
                label={questions.pelvicFunctions}
                error={errors.pelvicFunctions}
                options={["контролирует", "не контролирует"]}
            />
            <FormTextArea
                control={control}
                name="stool"
                label={questions.stool}
                placeholder="регулярный/нерегулярный, склонность к запорам/поносам/недержание кала/ когда был последний стул/ прием слабительного средства"
                error={errors.stool}
                minRows={2}
            />

            <h4 className="questionnaire__form-block-title">МОЧЕПОЛОВАЯ СИСТЕМА:</h4>

            <FormRadioGroup
                control={control}
                name="lumbarInspection"
                label={questions.lumbarInspection}
                error={errors.lumbarInspection}
                options={["отсутствуют", "имеются"]}
            />
            <FormTextArea
                control={control}
                name="PasternatskySign"
                label={questions.PasternatskySign}
                placeholder="отрицателен / положителен / слабо положителен (справа / слева/ с обеих сторон)"
                error={errors.PasternatskySign}
            />
            <FormTextArea
                control={control}
                name="urination"
                label={questions.urination}
                placeholder="контролирует/не контролирует; не затруднено/затруднено/ учащено/ недержание мочи/ночной энурез; диурез достаточный/недостаточный"
                error={errors.urination}
                minRows={2}
            />
            <FormSelect
                control={control}
                name="eyeSclerae"
                label={questions.eyeSclerae}
                error={errors.eyeSclerae}
                options={["обычной окраски", "желтушные", "гиперемированные «инъецированные»"]}
            />
            <FormSelect
                control={control}
                name="pupils"
                label={questions.pupils}
                error={errors.pupils}
                options={["симметричные", "анизокория", "миоз", "мидриаз"]}
            />
            <FormSelect
                control={control}
                name="visionAssessment"
                label={questions.visionAssessment}
                error={errors.visionAssessment}
                options={["нет снижения зрения", "нарушения зрения компенсированы", "не компенсированы"]}
            />
            <FormSelect
                control={control}
                name="hearingAssessment"
                label={questions.hearingAssessment}
                error={errors.hearingAssessment}
                options={["нет снижения слуха", "нарушения слуха компенсированы", "не компенсированы"]}
            />

            <h4 className="questionnaire__form-block-title">ПСИХО - ЭМОЦИОНАЛЬНЫЙ СТАТУС:</h4>

            <FormSelect
                control={control}
                name="appearance"
                label={questions.appearance}
                error={errors.appearance}
                options={["опрятен", "одет(а) по погоде", "неопрятен"]}
            />
            <FormSelect
                control={control}
                name="personalHygiene"
                label={questions.personalHygiene}
                error={errors.personalHygiene}
                options={["нормальное", "сниженное", "запущенное"]}
            />
            <FormTextArea
                control={control}
                name="orientation"
                label={questions.orientation}
                placeholder="время/место/ собственная личность и окружающие/ситуация/полностью ориентирован(а)"
                error={errors.orientation}
                minRows={2}
            />
            <FormSelect
                control={control}
                name="cooperationDuringExamination"
                label={questions.cooperationDuringExamination}
                error={errors.cooperationDuringExamination}
                options={["полное", "частичное", "формальное", "отсутствует"]}
            />
            <FormSelect
                control={control}
                name="behavior"
                label={questions.behavior}
                error={errors.behavior}
                options={["спокойное", "враждебное", "негативистичное", "агрессивное", "возбуждение", "апатичное"]}
            />
            <FormSelect
                control={control}
                name="psychomotorActivity"
                label={questions.psychomotorActivity}
                error={errors.psychomotorActivity}
                options={[
                    "в норме",
                    "заторможен",
                    "скован",
                    "ригиден",
                    "тремор",
                    "восковая гибкость",
                    "угрожающие жесты",
                ]}
            />
            <FormSelect
                control={control}
                name="speech"
                label={questions.speech}
                error={errors.speech}
                options={[
                    "чистая",
                    "правильная",
                    "заикание",
                    "медленная",
                    "быстрая",
                    "невнятное бормотание",
                    "полный мутизм",
                    "селективный мутизм",
                    "немота",
                ]}
            />
            <FormTextArea
                control={control}
                name="memoryImpairment"
                label={questions.memoryImpairment}
                placeholder="нет/есть (немедленная/краткосрочная/ долгосрочная)"
                error={errors.memoryImpairment}
            />
            <FormTextArea
                control={control}
                name="perceptualImpairment"
                label={questions.perceptualImpairment}
                placeholder="нет/ есть (иллюзии/ галлюцинации: зрительные, слуховые тактильные, вкусовые/ деперсонализация/ дереализация"
                error={errors.perceptualImpairment}
            />
            <FormSelect
                control={control}
                name="concentrationAttentionImpairment"
                label={questions.concentrationAttentionImpairment}
                error={errors.concentrationAttentionImpairment}
                options={["нет", "слабо выраженное", "значительное"]}
            />
        </>
    );
};
