import { ButtonLoader } from "components/ButtonLoader";

export const QuestionnaireFormWrapper = ({
    handleSubmit,
    handleShowResults,
    isLoading,
    isShowResult = true,
    children,
}) => {
    return (
        <form onSubmit={handleSubmit} className="questionnaire__form">
            {children}

            {isShowResult && (
                <button
                    onClick={handleShowResults}
                    type="button"
                    className="main-btn main-btn_green top-margin"
                    disabled={isLoading}
                >
                    {isLoading ? <ButtonLoader /> : "Посмотреть результаты"}
                </button>
            )}

            <button className="main-btn top-margin" disabled={isLoading}>
                {isLoading ? <ButtonLoader /> : "Продолжить опрос"}
            </button>
        </form>
    );
};
