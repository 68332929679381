import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useUser } from "hooks/useQuery";
import { hideNotice } from "redux/appReducer";
import { Alert } from "components/Alert";
import { Navbar } from "components/Navbar";
import { Tools } from "components/Tools";
import { Notice } from "components/Notice";

export const Layout = ({ children }) => {
    const dispatch = useDispatch();
    const { user, isUserFetched } = useUser();
    const { alert, notice } = useSelector((state) => state.app);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user && isUserFetched) {
            navigate("/login");
        }
    }, [navigate, user, isUserFetched]);

    const hideNoticeHandler = useCallback(() => {
        dispatch(hideNotice());
    }, [dispatch]);

    return (
        <div className="main">
            {!!alert && <Alert text={alert.text} type={alert.type} />}
            {!!notice && <Notice text={notice.text} closeHandler={hideNoticeHandler} />}

            {children}

            {user?.role === "specialist" && (
                <Tools
                    tools={[
                        // { url: `/code/${user?._id}`, title: 'Отправить QR-код или ссылку' },
                        { url: `/respondent/new/${user?._id}`, title: "Новый пациент" },
                    ]}
                />
            )}
            <Navbar />
        </div>
    );
};
