import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

import { classNames } from "helpers/app";
import { selectStyles } from "components/forms/selectStyles";
import { FormInput } from "components/forms/FormInput";

export const FormSelectMulti = ({
    name,
    label,
    placeholder,
    control,
    error,
    options,
    maxSelectOptions = null,
    maxValues = null,
    isMulti = true,
    isDisabled = false,
    otherField,
    otherFieldName,
}) => {
    const [isShowOtherField, setIsShowOtherField] = useState(false);

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({ field: { value, onChange } }) => {
                    const isMaxValue = maxValues && value.map((e) => Number(e.value)).reduce((a, b) => a + b, 0);
                    const isMaxOptions = maxSelectOptions ? value.length >= maxSelectOptions : false;

                    return (
                        <div
                            className={classNames("questionnaire__form-item", {
                                error,
                            })}
                        >
                            <p>{label}</p>
                            <Select
                                value={value}
                                onChange={(e) => {
                                    if (otherField) {
                                        if (e.find(({ label }) => label === otherField)) {
                                            setIsShowOtherField(true);
                                        } else {
                                            setIsShowOtherField(false);
                                        }
                                    }

                                    onChange(e);
                                }}
                                options={options}
                                placeholder={placeholder}
                                styles={selectStyles}
                                isSearchable={false}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                                isOptionDisabled={(option) => {
                                    if (isMaxValue && isMaxValue + Number(option.value) > maxValues) {
                                        return true;
                                    }

                                    return isMaxOptions && value.length >= maxSelectOptions;
                                }}
                                isOptionSelected={(option, selectValue) =>
                                    selectValue.some((v) => v.label === option.label)
                                }
                                isMulti={isMulti}
                                isDisabled={isDisabled}
                            />
                            <div className="select" />
                            {error && <span>{error.message}</span>}
                        </div>
                    );
                }}
            />

            {isShowOtherField && (
                <FormInput
                    control={control}
                    name={otherFieldName}
                    label={`${label} (${otherField})`}
                    placeholder="заполните поле"
                />
            )}
        </>
    );
};
