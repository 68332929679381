import axios from "axios";

export const createExamination = async (respondentId) => {
    try {
        const response = await axios.post(`examination/${respondentId}`);

        return response.data;
    } catch (e) {
        // eslint-disable-next-line no-throw-literal
        throw {
            message: "Ошибка добавления осмотра",
        };
    }
};
