import React, { memo } from "react";
import { Link } from "react-router-dom";

import { classNames } from "helpers/app";
import { respondentDate } from "helpers/dates";
import { getFillingTime } from "./getFillingTime";
import styles from "./RespondentExaminationsList.module.scss";

const statuses = {
    new: "Новый",
    inProgress: "В процессе",
    done: "Завершён",
};

export const RespondentExaminationsList = memo(({ examinations }) => {
    return examinations.map(({ _id, status, createdAt, fillingTimes }, i) => {
        return (
            <Link
                to={`/examination/${_id}`}
                className={classNames(["block-wrapper", styles.listItem, styles[status]])}
                key={_id}
            >
                <div className={styles.itemHeader}>
                    <p>Осмотр {examinations.length - i}</p>
                    <span className={styles.itemStatus}>{statuses[status]}</span>
                </div>
                <div className={styles.itemContent}>
                    <div>{respondentDate(createdAt)}</div>

                    <div>Время прохождения осмотра: {getFillingTime(fillingTimes)} сек</div>
                </div>
            </Link>
        );
    });
});
