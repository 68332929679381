import { FormSelectMulti } from "components/forms";
import { questionsFormLawtonScale as questions } from "components/questionnaire/questions";

export const QuestionnaireLawtonScaleForm = ({ control, errors }) => {
    return (
        <>
            <FormSelectMulti
                control={control}
                name="telephoneCalls"
                label={questions.telephoneCalls}
                placeholder="Один вариант ответа"
                error={errors.telephoneCalls}
                options={[
                    { value: 1, label: "Пользуется телефоном по собственной инициативе, набирает номера" },
                    { value: 1, label: "Набирает несколько известных номеров" },
                    { value: 1, label: "Отвечает на телефонные звонки, но сам номера не набирает" },
                    { value: 0, label: "Не пользуется телефоном вообще" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="shopping"
                label={questions.shopping}
                placeholder="Один вариант ответа"
                error={errors.shopping}
                options={[
                    { value: 1, label: "Совершает самостоятельно все необходимые покупки" },
                    { value: 0, label: "Совершает самостоятельно небольшие покупки" },
                    { value: 0, label: "Требуется сопровождение при любом посещении магазина" },
                    { value: 0, label: "Полностью не в состоянии делать покупки" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="cooking"
                label={questions.cooking}
                placeholder="Один вариант ответа"
                error={errors.cooking}
                options={[
                    { value: 1, label: "Планирует, готовит и подает необходимую пищу самостоятельно" },
                    { value: 0, label: "Готовит необходимую пищу, если ингредиенты были предоставлены" },
                    {
                        value: 0,
                        label: "Подогревает и подает пищу или готовит пищу, но не соблюдает необходимую диету",
                    },
                    { value: 0, label: "Нуждается, чтобы кто-то приготовил и подал пищу" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="householdChores"
                label={questions.householdChores}
                placeholder="Один вариант ответа"
                error={errors.householdChores}
                options={[
                    {
                        value: 1,
                        label: "Поддерживает дом в одиночку за исключением редкой помощи при необходимости выполнения тяжелой работы",
                    },
                    {
                        value: 1,
                        label: "Выполняет простые повседневные дела, такие как мытье посуды или заправка кровати",
                    },
                    {
                        value: 1,
                        label: "Выполняет простые повседневные дела, но не в состоянии поддерживать необходимый уровень чистоты в доме",
                    },
                    { value: 1, label: "Нужна помощь при выполнении всех домашних дел" },
                    { value: 0, label: "Не участвует ни в каких хозяйственных делах" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="laundry"
                label={questions.laundry}
                placeholder="Один вариант ответа"
                error={errors.laundry}
                options={[
                    { value: 1, label: "Самостоятельно стирает все необходимые вещи" },
                    { value: 1, label: "Стирает мелкие вещи, такие как носки, чулки" },
                    { value: 0, label: "Вся стирка должна осуществляться кем-то" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="usingTransportation"
                label={questions.usingTransportation}
                placeholder="Один вариант ответа"
                error={errors.usingTransportation}
                options={[
                    { value: 1, label: "Самостоятельно пользуется общественным транспортом или водит машину" },
                    {
                        value: 1,
                        label: "Организует собственную поездку на такси, но не пользуется общественным транспортом",
                    },
                    {
                        value: 1,
                        label: "Пользуется общественным транспортом если кто- то при этом помогает или сопровождает",
                    },
                    { value: 0, label: "Перемещается на такси или машине в сопровождении другого лица" },
                    { value: 0, label: "Не перемещается" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="medicationIntake"
                label={questions.medicationIntake}
                placeholder="Один вариант ответа"
                error={errors.medicationIntake}
                options={[
                    {
                        value: 1,
                        label: "Самостоятельно принимает необходимые лекарства в правильных дозировках и в правильное время",
                    },
                    {
                        value: 0,
                        label: "Принимает лекарство, если оно было кем-то приготовлено для приема в необходимой дозе",
                    },
                    { value: 0, label: "Не способен самостоятельно принимать лекарства" },
                ]}
                maxSelectOptions={1}
            />

            <FormSelectMulti
                control={control}
                name="financialTransactions"
                label={questions.financialTransactions}
                placeholder="Один вариант ответа"
                error={errors.financialTransactions}
                options={[
                    {
                        value: 1,
                        label: "Самостоятельно контролирует финансовые вопросы (бюджет, поверка чеков, оплата за жилье, посещение банка), контролирует доходы",
                    },
                    {
                        value: 1,
                        label: "Занимается ежедневными покупками, но нуждается в помощи с банковскими операциями и при осуществлении крупных покупок",
                    },
                    { value: 0, label: "Не способен самостоятельно распоряжаться деньгами" },
                ]}
                maxSelectOptions={1}
            />
        </>
    );
};
