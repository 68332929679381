import { FormRadioGroupWithValue } from "components/forms";
import { questionsFormRASS as questions } from "components/questionnaire/questions";

const options = [
    { value: 4, label: "Воинственен, агрессивен, опасен для окружающих", attempt: true },
    { value: 3, label: "Агрессивен, пытается вырвать трубки, капельницу или катетер", attempt: true },
    { value: 2, label: "Частые бесцельные движения, сопротивление процедурам" },
    { value: 1, label: "Тревожен, неагрессивные движения" },
    { value: 0, label: "Спокое и внимателен" },
    { value: -1, label: "Невнимателен, сонлив, но реагирует на голос" },
    { value: -2, label: "Просыпается на короткое время на голос" },
    { value: -3, label: "Движение или открытие глаз на голос, но нет зрительного контакта" },
    {
        value: -4,
        label: "Никакой реакции на голос, но есть какие-либо движения на физическую стимуляцию",
    },
    { value: -5, label: "Никакой реакции на голос и физическую стимуляцию" },
];

export const QuestionnaireRASSForm = ({ control, errors }) => {
    return (
        <>
            <FormRadioGroupWithValue
                control={control}
                name="characteristic"
                label={questions.characteristic}
                options={options}
                error={errors.characteristic}
            />

            <p style={{ fontStyle: "italic", opacity: 0.7 }}>
                Примечание: Ячейки ХАРАКТЕРИСТИК, выделенные красным - необходимо срочно сообщить врачу
            </p>
        </>
    );
};
