import {
    questionsForm1,
    questionsForm2,
    questionsForm3,
    questionsForm4,
    questionsFormAgeNotBarrier,
    questionsFormBarthelScale,
    questionsFormClinicalDehydrationScale,
    questionsFormDeliriumScale,
    questionsFormLawtonScale,
    questionsFormMMSE,
    questionsFormPainIntensityRatings,
    questionsFormPhysicalFunctioningTest,
    questionsFormSleepAssessment,
    questionsFormGeriatricDepressionScale,
    questionsNutritionalRatingScale,
    questionsFormNortonScale,
    questionsFormMorseScale,
    // questionsFormGHIS,
    questionsFormPainAssessmentDementia,
    questionsFormAdditionalScales,
    questionsFormGetUpAndGo,
    // questionsFormCharlsonComorbidityIndex,
    // questionsFormRASS,
    questionsFormDynamometry,
} from "./questions";

export const questionnaireForms = {
    formAgeNotBarrier: {
        title: "Опросник «Возраст не помеха» для скрининга синдрома старческой астении",
        questions: questionsFormAgeNotBarrier,
        isHighlighted: true,
        getResult(score) {
            switch (true) {
                case score >= 0 && score <= 2:
                    return "Нет старческой астении";
                case score === 3 || score === 4:
                    return "Вероятная преастения";
                case score >= 5 && score <= 7:
                    return "Вероятная старческая астения";
                default:
                    return "Некорректное количество баллов";
            }
        },
    },
    form1: {
        title: "Первичный осмотр",
        questions: questionsForm1,
    },
    form2: {
        title: "Имеющиеся ХНИЗ",
        questions: questionsForm2,
    },
    form3: {
        title: "Данные объективного осмотра",
        questions: questionsForm3,
    },
    form4: {
        title: "Состояние пациента",
        questions: questionsForm4,
    },
    formMMSE: {
        title: "Краткая шкала оценки психического статуса - MMSE",
        questions: questionsFormMMSE,
        getResult(score) {
            switch (true) {
                case score >= 28 && score <= 30:
                    return "Нет нарушений когнитивных функций";
                case score >= 24 && score <= 27:
                    return "Преддементные когнитивные нарушения";
                case score >= 20 && score <= 23:
                    return "Деменция легкой степени выраженности";
                case score >= 11 && score <= 19:
                    return "Деменция умеренной степени выраженности";
                case score >= 0 && score <= 10:
                    return "Тяжёлая деменция";
                default:
                    return "Некорректное количество баллов";
            }
        },
    },
    formDeliriumScale: {
        title: "Шкала спутанности сознания (делирия)",
        questions: questionsFormDeliriumScale,
    },
    formNutritionalRatingScale: {
        title: "Краткая шкала оценки питания",
        questions: questionsNutritionalRatingScale,
        required: ["form3"],
        getResult(score, { questionsCount }) {
            if (questionsCount > 6) {
                switch (true) {
                    case score >= 23.5:
                        return "Нормальный статус питания";
                    case score >= 17 && score <= 23.4:
                        return "Риск недостаточности питания (мальнутриции)";
                    case score < 17:
                        return "Недостаточность питания (мальнутриция)";
                    default:
                        return "Некорректное количество баллов";
                }
            } else {
                switch (true) {
                    case score >= 12:
                        return "Нормальный статус питания";
                    default:
                        return "Некорректное количество баллов";
                }
            }
        },
    },
    formClinicalDehydrationScale: {
        title: "Клиническая шкала дегидратации",
        questions: questionsFormClinicalDehydrationScale,
        getResult(score) {
            switch (true) {
                case score === 0:
                    return "Дегидратация отсутствует";
                case score >= 1 && score <= 4:
                    return "Легкая дегидратация";
                case score >= 5 && score <= 8:
                    return "Соответствуют дегидратации средней и тяжелой степени тяжести";
                default:
                    return "Некорректное количество баллов";
            }
        },
    },
    formBarthelScale: {
        title: "Шкала оценки базовой функциональной активности (шкала Бартел) ",
        questions: questionsFormBarthelScale,
        getResult(score) {
            switch (true) {
                case score === 100:
                    return "Полная независимость";
                case score >= 95:
                    return "Легкая зависимость";
                case score >= 65 && score <= 90:
                    return "Умеренная зависимость";
                case score >= 25 && score <= 60:
                    return "Выраженная зависимость";
                case score >= 0 && score <= 24:
                    return "Полная зависимость";
                default:
                    return "Некорректное количество баллов";
            }
        },
    },
    formLawtonScale: {
        title: "Шкала оценки инструментальной функциональной активности в повседневной жизни (шкала Лоутона)",
        questions: questionsFormLawtonScale,
        getResult(score) {
            switch (true) {
                case score < 8:
                    return "Зависим от посторонней помощи";
                case score >= 8:
                    return "Не зависим от посторонней помощи";
                default:
                    return "Некорректное количество баллов";
            }
        },
    },
    formPhysicalFunctioningTest: {
        title: "Краткая батарея тестов физического функционирования",
        questions: questionsFormPhysicalFunctioningTest,
        getResult(score) {
            switch (true) {
                case score > 10:
                    return "Нет старческой астении";
                case score >= 8 && score <= 9:
                    return "Преастения";
                case score <= 7:
                    return "Старческая астения";
                default:
                    return "Некорректное количество баллов";
            }
        },
    },
    formGetUpAndGo: {
        title: "Тест «Встань и иди» с фиксацией времени",
        questions: questionsFormGetUpAndGo,
        getResult(score) {
            switch (true) {
                case score <= 10:
                    return "Норма";
                case score > 10 && score < 14:
                    return "Средний риск падений";
                case score >= 14:
                    return "Риск падений";
                default:
                    return "Некорректное количество баллов";
            }
        },
    },
    formDynamometry: {
        title: "Динамометрия",
        questions: questionsFormDynamometry,
    },
    formPainIntensityRatings: {
        title: "Оценки интенсивности боли - визуально-аналоговая шкала",
        questions: questionsFormPainIntensityRatings,
    },
    formGeriatricDepressionScale: {
        title: "Гериатрическая шкала депрессии",
        questions: questionsFormGeriatricDepressionScale,
        getResult(score) {
            switch (true) {
                case score >= 5:
                    return "Вероятная депрессия";
                case score <= 4:
                    return "Депрессия отсутствует";
                default:
                    return "Некорректное количество баллов";
            }
        },
    },
    formSleepAssessment: {
        title: "Оценка сна (Индекс выраженности инсомнии)",
        questions: questionsFormSleepAssessment,
        getResult(score) {
            switch (true) {
                case score <= 7:
                    return "Норма";
                case score >= 8 && score <= 14:
                    return "Легкие нарушения сна";
                case score >= 15 && score <= 21:
                    return "Умеренные нарушения сна";
                case score >= 22 && score <= 28:
                    return "Выраженные нарушения сна";
                default:
                    return "Некорректное количество баллов";
            }
        },
    },
    formNortonScale: {
        title: "Шкала для оценки риска пролежней (шкала Нортона)",
        questions: questionsFormNortonScale,
        getResult(score) {
            switch (true) {
                case score < 12:
                    return "Существенный риск образования";
                case score >= 12 && score <= 14:
                    return "Присутствует риск появления пролежней";
                case score > 14:
                    return "опасность невелика";
                default:
                    return "Некорректное количество баллов";
            }
        },
    },
    formMorseScale: {
        title: "Шкала Морсе",
        questions: questionsFormMorseScale,
        getResult(score) {
            switch (true) {
                case score < 25:
                    return "Нет риска падений";
                case score >= 25 && score <= 50:
                    return "Низкий риск падений";
                case score > 50:
                    return "Высокий риск падений";
                default:
                    return "Некорректное количество баллов";
            }
        },
    },

    // formCharlsonComorbidityIndex: {},
    // formGHIS: {},
    // formRASS: {},

    // Переименована из "Дополнительные шкалы" в "Корнельская шкала депрессии при деменции"
    formAdditionalScales: {
        title: "Корнельская шкала депрессии при деменции",
        questions: questionsFormAdditionalScales,
        getResult(score) {
            switch (true) {
                case score > 10:
                    return "Есть основания подозревать вероятную депрессию";
                case score > 18:
                    return "Выраженная депрессия";
                default:
                    return "Некорректное количество баллов";
            }
        },
    },
    formPainAssessmentDementia: {
        title: "Оценка боли при тяжелой деменции",
        questions: questionsFormPainAssessmentDementia,
        getResult(score) {
            switch (true) {
                case score < 4:
                    return "Слабая боль";
                case score >= 4 && score <= 6:
                    return "Боль средней тяжести";
                case score > 6:
                    return "Сильная боль";
                default:
                    return "Некорректное количество баллов";
            }
        },
    },
};

export const questionnaireMethodsList = Object.keys(questionnaireForms);

export const formsWithoutResult = [
    "form1",
    "form2",
    "form3",
    "form4",
    "formDeliriumScale",
    "formDynamometry",
    "formPainIntensityRatings",
    "formRASS",
];

export const questionnaireButtons = Object.entries(questionnaireForms).map(([key, value]) => ({
    key,
    title: value.title,
    isHighlighted: value.isHighlighted,
}));

// Убранные методики

// formCharlsonComorbidityIndex: {
//     title: "Индекс коморбидности Чарлсона",
//     questions: questionsFormCharlsonComorbidityIndex,
//     getResult(score) {
//         switch (true) {
//             case score === 0:
//                 return "10-летняя выживаемость 99%";
//             case score === 1:
//                 return "10-летняя выживаемость 96%";
//             case score === 2:
//                 return "10-летняя выживаемость 90%";
//             case score === 3:
//                 return "10-летняя выживаемость 77%";
//             case score === 4:
//                 return "10-летняя выживаемость 53%";
//             case score === 5:
//                 return "10-летняя выживаемость 21%";
//             case score > 5:
//                 return "10-летняя выживаемость менее 21%";
//             default:
//                 return "Некорректное количество баллов";
//         }
//     },
// },
// formGHIS: {
//     title: "Оценка гериатрического индекса здоровья (ОГИЗ)",
//     questions: questionsFormGHIS,
//     required: [
//         "formMMSE",
//         "formBarthelScale",
//         "formLawtonScale",
//         "formCharlsonComorbidityIndex",
//         "formNutritionalRatingScale",
//     ],
//     getResult(initScore) {
//         const score = Number(initScore);
//
//         switch (true) {
//             case score <= 0.33:
//                 return "III группа здоровья";
//             case score > 0.33 && score <= 0.66:
//                 return "II группа здоровья";
//             case score > 0.66:
//                 return "I группа здоровья";
//             default:
//                 return "Некорректное количество баллов";
//         }
//     },
// },
// formRASS: {
//     title: "Ричмондская шкала ажитации (The Richmond Agitation-Sedation Scale, RASS)",
//     questions: questionsFormRASS,
//     getResult() {
//         return "";
//     },
// },
