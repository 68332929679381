import { useEffect } from "react";
import { usePDF } from "@react-pdf/renderer";

import { PdfGenerator } from "components/result";

const DownloadPdf = ({ respondentFullName, result, questionnaire, createdAt, close, children }) => {
    const [instance] = usePDF({
        document: (
            <PdfGenerator
                respondentFullName={respondentFullName}
                result={result}
                questionnaire={questionnaire}
                createdAt={createdAt}
            />
        ),
    });

    useEffect(() => {
        if (instance.loading) {
            return;
        }

        if (instance.error) {
            close();
        }

        if (instance.url) {
            const link = document.createElement("a");

            link.href = instance.url;
            link.setAttribute("download", `Результат осмотра ${respondentFullName}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            close();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance]);

    return <>{children}</>;
};

export default DownloadPdf;
